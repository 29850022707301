
import Modal from "../../../../views/utils/Modal";
import { 
    useState
} from "react";

export default function AddCommand ({
    id,
    comps,
    setComps,
    indices,
    setIsScriptRendered,
    setIsAddingCommand,
    availableCommands
}) {

    let all_commands = {
        "conditional/if": "IF/THEN/ELSE", // done
        "variable/set": "SET VARIABLE", // done
        "variable/get": "GET VARIABLE", // done
        "expression/number": "NUMBER", // done
        "expression/string": "STRING", // done
        "expression/boolean": "BOOLEAN", // done
        "expression/color": "COLOR", // done
        "expression/currentTime": "CURRENT TIME", // done
        "operator/logical/and": "AND", // done
        "operator/logical/or": "OR", // done
        "operator/logical/not": "NOT", // done
        "operator/ordinal/g": "GREATER THAN", // done
        "operator/ordinal/geq": "GREATER THAN OR EQUAL TO", // done
        "operator/ordinal/l": "LESS THAN", // done
        "operator/ordinal/leq": "LESS THAN OR EQUAL TO", // done
        "operator/ordinal/eq": "EQUAL TO", // done
        "operator/type/isNum": "IS A NUMBER", // done
        "operator/type/isStr": "IS A STRING", // done
        "operator/type/isEmail": "IS AN EMAIL", // done
        "operator/type/isDate": "IS A DATE", // done
        "operator/type/isTime": "IS A TIME", // done
        "operator/type/isUndecorated": "IS UNDECORATED", // done
        "operator/type/isBlank": "IS BLANK", // done
        "operator/array/includes": "INCLUDES", // done
        "operator/array/isEmpty": "IS EMPTY", // done
        // "operator/quant/timeBetween": "TIME BETWEEN",
        "operator/quant/add": "ADD", // done
        "operator/quant/subtract": "SUBTRACT", // done
        "operator/quant/multiply": "MULTIPLY", // done
        "operator/quant/divide": "DIVIDE", // done
        "operator/quant/modulus": "MODULUS", // done
        "operator/quant/floor": "ROUND DOWN", // done
        "operator/quant/ceiling": "ROUND UP", // done
        "operator/quant/round": "ROUND", // done
        "operator/quant/abs": "ABSOLUTE VALUE", // done
        "operator/quant/max": "MAX", // done
        "operator/quant/min": "MIN", // done
        "operator/qual/digits": "NUMBER OF DIGITS", // done
        "operator/qual/words": "NUMBER OF WORDS", // done
        "operator/qual/day": "DAY OF DATE", // done
        "operator/qual/month": "MONTH OF DATE", // done
        "operator/qual/year": "YEAR OF DATE", // done
        "self/value": "THIS ELEMENT'S VALUE", // done
        "signal/error": "THROW ERROR", // done
        "signal/warning": "THROW WARNING",
        "signal/disable": "DISABLE", // done
        "signal/disableSelf": "DISABLE SELF", // done
        "signal/enable": "ENABLE", // done

        "signal/table/disable": "DISABLE COLUMN", // done
        "signal/table/enable": "ENABLE COLUMN", // done
        "variable/table/set": "SET TABLE VARIABLE", // done
        "variable/table/get": "GET TABLE VARIABLE", // done
        "self/table/value": "GET COLUMN VALUE", // done
        "signal/table/error": "THROW ERROR", // done
        "signal/table/disableRow": "DISABLE ROW", // done
        "signal/table/warning": "THROW WARNING", // done

        // complex commands

        "type/isNum": "[THIS COMPONENT] IS A NUMBER", // done
        "quant/between": "BETWEEN TWO NUMBERS", // done
        "quant/roundiv": "ROUNDED DIVISION", // done
        "conditional/ifselected": "IF [OPTION SELECTED] THEN" // done
    };

    let commands_render = [];
    for (let k = 0; k < availableCommands.length; ++k) {
        if (availableCommands[k] !== "") {
            // we want to retrieve the first identified in a command's name to color it appropriately.
            let decomposedCommandIdentifier = availableCommands[k].split('/');

            let commandBackgroundColor = '#faf3dd'; // default value is gold
            switch (decomposedCommandIdentifier[0]) {
                case 'conditional':
                    commandBackgroundColor = '#55efc4';
                    break;
                case 'variable':
                    commandBackgroundColor = '#dfe6e9';
                    break;
                case 'expression':
                    commandBackgroundColor = '#74b9ff';
                    break;
                case 'operator':
                    commandBackgroundColor = '#ff7675';
                    break;
                case 'self':
                    commandBackgroundColor = '#dfe6e9';
                    break;
                case 'signal':
                    commandBackgroundColor = '#dfe6e9';
                    break;
                case 'type':
                    commandBackgroundColor = '#ff7675';
                    break;
                case 'quant':
                    commandBackgroundColor = '#ff7675';
                    break;
                default:
                    // command not found, so do nothing.
            };

            commands_render.push(<a style={{
                width: '30%',
                fontFamily: "Work Sans, Lato, sans-serif",
                textAlign: 'center',
                fontWeight: '300',
                border: `1pt solid ${commandBackgroundColor}`,
                margin: '1.5%',
                // borderTop: k !== 0 ? '0pt' : "1pt solid #faf3dd",
                padding: '2vmax 0'
            }} className='panel-button' onClick={(e) => {
                e.preventDefault();
                if (availableCommands[k] === 'signal/error') {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "signal/error",
                        message: "Error Found",
                        comment: -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === 'signal/disable') {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "signal/disable",
                        comp: "",
                        comment: -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === 'signal/disableSelf') {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "signal/disableSelf",
                        comment: -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === 'signal/enable') {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "signal/enable",
                        comp: "",
                        comment: -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === 'signal/table/disable') {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "signal/table/disable",
                        column: 0,
                        comment: -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === 'signal/table/enable') {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "signal/table/enable",
                        column: 0,
                        comment: -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === 'signal/table/error') {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "signal/table/error",
                        column: 0,
                        message: "",
                        comment: -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === 'signal/table/warning') {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "signal/table/warning",
                        column: 0,
                        message: "",
                        comment: -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === 'signal/warning') {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "signal/warning",
                        message: "",
                        comment: -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === 'signal/table/disableRow') {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "signal/table/disableRow",
                        comment: -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === 'variable/table/set') {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "variable/table/set",
                        var: "",
                        children: [],
                        comment: -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === 'variable/table/get') {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "variable/table/get",
                        var: "",
                        comment: -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "self/table/value") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "self/table/value",
                        column: 0,
                        comment: -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === 'conditional/if') {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "conditional/if",
                        children: [
                            {
                                children: []
                            },
                            {
                                children: []
                            },
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "expression/boolean") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "expression/boolean",
                        value: "true",
                        comment: -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "variable/set") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "variable/set",
                        var: "",
                        children: [],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "variable/get") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "variable/get",
                        var: "",
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "expression/number") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "expression/number",
                        value: "0",
                        comment: -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "expression/string") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "expression/string",
                        value: "",
                        comment: -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "expression/color") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "expression/color",
                        value: "#ffffff",
                        comment: -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "expression/currentTime") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "expression/currentTime",
                        comment: -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/logical/and") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/logical/and",
                        children: [
                            {
                                children: []
                            },
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/logical/or") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/logical/or",
                        children: [
                            {
                                children: []
                            },
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/logical/not") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/logical/not",
                        children: [
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/ordinal/g") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/ordinal/g",
                        children: [
                            {
                                children: []
                            },
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/ordinal/geq") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/ordinal/geq",
                        children: [
                            {
                                children: []
                            },
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/ordinal/l") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/ordinal/l",
                        children: [
                            {
                                children: []
                            },
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/ordinal/leq") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/ordinal/leq",
                        children: [
                            {
                                children: []
                            },
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/ordinal/eq") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/ordinal/eq",
                        children: [
                            {
                                children: []
                            },
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "self/value") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "self/value",
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/type/isNum") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/type/isNum",
                        children: [
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/type/isStr") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/type/isStr",
                        children: [
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/array/isEmpty") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/array/isEmpty",
                        children: [
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/array/includes") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/array/includes",
                        children: [
                            {
                                children: []
                            },
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/quant/add") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/quant/add",
                        children: [
                            {
                                children: []
                            },
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/quant/timeBetween") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/quant/timeBetween",
                        children: [
                            {
                                children: []
                            },
                            {
                                children: []
                            }
                        ],
                        returnType: "Days", // ["Seconds", "Minutes", "Hours", "Days", "Weeks", "Months", "Years"]
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/quant/subtract") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/quant/subtract",
                        children: [
                            {
                                children: []
                            },
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/quant/multiply") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/quant/multiply",
                        children: [
                            {
                                children: []
                            },
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/quant/divide") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/quant/divide",
                        children: [
                            {
                                children: []
                            },
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/quant/modulus") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/quant/modulus",
                        children: [
                            {
                                children: []
                            },
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/quant/floor") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/quant/floor",
                        children: [
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/quant/ceiling") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/quant/ceiling",
                        children: [
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/quant/round") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/quant/round",
                        children: [
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/quant/abs") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/quant/abs",
                        children: [
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/qual/digits") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/qual/digits",
                        children: [
                            {
                                children: []
                            }
                        ],
                        before: -1,
                        after: -1,
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/quant/max") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/quant/max",
                        children: [
                            {
                                children: []
                            },
                            {
                                children: []
                            }
                        ],
                        numOfChildren: 2,
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/quant/min") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/quant/min",
                        children: [
                            {
                                children: []
                            },
                            {
                                children: []
                            }
                        ],
                        numOfChildren: 2,
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/type/isEmail") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/type/isEmail",
                        children: [
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/type/isDate") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/type/isDate",
                        children: [
                            {
                                children: []
                            }
                        ],
                        format: 'us', // can be us or uk (march 17, 2023 can be 03/17/2023 or 17/03/2023)
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/type/isTime") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/type/isTime",
                        children: [
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/type/isBlank") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/type/isBlank",
                        children: [
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/type/isUndecorated") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/type/isUndecorated",
                        children: [
                            {
                                children: []
                            }
                        ],
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/qual/words") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/qual/words",
                        children: [
                            {
                                children: []
                            }
                        ],
                        before: -1,
                        after: -1,
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/qual/day") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/qual/day",
                        children: [
                            {
                                children: []
                            }
                        ],
                        format: 'us', // can in us or uk
                        before: -1,
                        after: -1,
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/qual/month") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/qual/month",
                        children: [
                            {
                                children: []
                            }
                        ],
                        format: 'us',
                        before: -1,
                        after: -1,
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === "operator/qual/year") {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        type: "operator/qual/year",
                        children: [
                            {
                                children: []
                            }
                        ],
                        before: -1,
                        after: -1,
                        comment: -1
                    });
                    console.log(new_comps);
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === 'type/isNum') {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        "type": "conditional/if",
                        "children": [
                        {
                            "children": [
                            {
                                "type": "operator/type/isNum",
                                "children": [
                                {
                                    "children": [
                                    {
                                        "type": "self/value",
                                        "comment": -1
                                    }
                                    ]
                                }
                                ],
                                "comment": -1
                            }
                            ]
                        },
                        {
                            "children": [
                                {
                                    "type": "signal/error",
                                    "message": "",
                                    "comment": -1
                                }
                                ]
                        },
                        {
                            "children": [
                            {
                                "type": "signal/error",
                                "message": "Value must be a number.",
                                "comment": -1
                            }
                            ]
                        }
                        ],
                        "comment": -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === 'quant/between') {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        "type": "operator/logical/and",
                        "children": [
                        {
                            "children": [
                            {
                                "type": "operator/ordinal/geq",
                                "children": [
                                {
                                    "children": [
                                    {
                                        "type": "expression/number",
                                        "value": "0",
                                        "comment": "Upper Limit"
                                    }
                                    ]
                                },
                                {
                                    "children": []
                                }
                                ],
                                "comment": -1
                            }
                            ]
                        },
                        {
                            "children": [
                            {
                                "type": "operator/ordinal/leq",
                                "children": [
                                {
                                    "children": [
                                    {
                                        "type": "expression/number",
                                        "value": "0",
                                        "comment": "Lower Limit"
                                    }
                                    ]
                                },
                                {
                                    "children": []
                                }
                                ],
                                "comment": -1
                            }
                            ]
                        }
                        ],
                        "comment": -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === 'quant/roundiv') {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        "type": "operator/quant/round",
                        "children": [
                        {
                            "children": [
                            {
                                "type": "operator/quant/divide",
                                "children": [
                                {
                                    "children": []
                                },
                                {
                                    "children": []
                                }
                                ],
                                "comment": "Include numbers here."
                            }
                            ]
                        }
                        ],
                        "comment": -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                } else if (availableCommands[k] === 'conditional/ifselected') {
                    let new_comps = [...comps];
                    let scriptObj = new_comps[id].script;
                    let thisCommand = scriptObj[indices[0]];
                    for (let j = 0; j < indices.length - 1; ++j) {
                        thisCommand = thisCommand.children[indices[j + 1]];
                    } thisCommand.children.push({
                        "type": "conditional/if",
                        "children": [
                        {
                            "children": [
                            {
                                "type": "operator/ordinal/eq",
                                "children": [
                                {
                                    "children": [
                                    {
                                        "type": "self/value",
                                        "comment": -1
                                    }
                                    ]
                                },
                                {
                                    "children": [
                                    {
                                        "type": "expression/string",
                                        "value": "",
                                        "comment": "Set value here."
                                    }
                                    ]
                                }
                                ],
                                "comment": -1
                            }
                            ]
                        },
                        {
                            "children": [
                            {
                                "type": "variable/set",
                                "var": "isCorrect",
                                "children": [
                                {
                                    "type": "expression/boolean",
                                    "value": "true",
                                    "comment": -1
                                }
                                ],
                                "comment": -1
                            }
                            ]
                        },
                        {
                            "children": [
                            {
                                "type": "variable/set",
                                "var": "isCorrect",
                                "children": [
                                {
                                    "type": "expression/boolean",
                                    "value": "false",
                                    "comment": -1
                                }
                                ],
                                "comment": -1
                            }
                            ]
                        }
                        ],
                        "comment": -1
                    });
                    setComps(new_comps);
                    setIsAddingCommand(false);
                    setIsScriptRendered(false);
                }
            }}>
                { all_commands[availableCommands[k]] }
            </a>);
        }
    }

    return <Modal width='75' height='77.5' marginTop='20vh'>
            <h1 style={{
                color: '#FAF3DD',
                fontWeight: '300',
                margin: '1vmax 0',
                padding: '1vmax 0',
                fontSize: '1.75vmax',
                display: 'block'
        }}>Add Command</h1>
        <div style={{
            height: '69%',
            overflow: 'scroll',
            borderTop: '1pt solid #faf3dd',
            borderBottom: '1pt solid #faf3dd',
            padding: '2.5%',
            textAlign: 'left',
            whiteSpace: 'normal'
        }}>
            { commands_render }
        </div>
        <div style={{
            position: 'absolute',
            bottom: '0',
            width: '100%',
            backgroundColor: '#000000'
        }}>
            <a href='' className='panel-button' style={{
                marginBottom: '1vmax'
            }} onClick={(e) => {
                e.preventDefault();
                setIsAddingCommand(false);
            }}>Cancel</a>
        </div>
        </Modal>

}