
import { useState } from "react";
import AddCommand from "../AddCommand";

 // Has params: props.id, props.comps, props.setComps, props.indices, props.setIsScriptRendered, props.children
export default function StartEventCommand (props) {

    const [ isAddingCommand, setIsAddingCommand ] = useState(false);

    let availableCommands = [
        "conditional/if",
        props.comps[props.id].type !== "intermediate/InputTable" ? "variable/set" : "variable/table/set",
        props.comps[props.id].type !== "intermediate/InputTable" ? "signal/error" : "signal/table/error",
        props.comps[props.id].type !== "intermediate/InputTable" ? "" : "signal/table/disableRow",
        props.comps[props.id].type !== "intermediate/InputTable" ? "signal/disable" : "signal/table/disable",
        props.comps[props.id].type !== "intermediate/InputTable" ? "signal/enable" : "signal/table/enable",
        props.comps[props.id].type !== "intermediate/InputTable" ? "conditional/ifselected" : "",
        props.comps[props.id].type !== "intermediate/InputTable" ? "type/isNum" : "",
        props.comps[props.id].type !== "intermediate/InputTable" ? "signal/warning" : "signal/table/warning",
        props.comps[props.id].type !== "intermediate/InputTable" ? "signal/disableSelf" : "",
    ]; // available commands for StartEventCommand

    return <>
        <div style={{
            marginBottom: '2vmax',
            overflow: 'scroll',
            display: 'inline-block',
            marginRight: '4vmax',
        }}>
            <div style={{
                padding: '1vmax',
                backgroundColor: '#faf3dd',
                color: '#000',
                display: 'inline-block',
                fontSize: '1.15vmax',
                fontFamily: "Work Sans, Lato, sans-serif",
                fontWeight: '500'
            }}>
                ON START
            </div>
            <a className='panel-button' style={{
               padding: '1vmax',
               display: 'inline-block',
               fontSize: '1.15vmax',
               fontFamily: "Work Sans, Lato, sans-serif",
               fontWeight: '300',
               border: '0pt',
               borderLeft: `1pt solid #faf3dd`,
               marginLeft: '1vmax'
           }} onClick={(e) => {
               e.preventDefault();
               let new_comps = [...props.comps];
               new_comps[props.id].script[1].children = JSON.parse(JSON.stringify(new_comps[props.id].script[0].children));
               props.setComps(new_comps);
               props.setIsScriptRendered(false);
           }}>
               Duplicate in Change
           </a>
            <div style={{
                padding: '2vmax 0',
                borderLeft: '3pt solid #faf3dd',
                borderBottom: '1pt dashed #faf3dd',
                paddingLeft: '2vmax',
                whiteSpace: 'nowrap',
                overflow: 'auto'
            }}>
                {props.children}
                <br />
                <i className='fa-solid fa-plus panel-icon' style={{
                    fontSize: '1vmax',
                    padding: '0.5vmax',
                    borderRadius: '100px'
                }} onClick={(e) => {
                    e.preventDefault();
                    setIsAddingCommand(true);
                }} />
                <i className='fa-solid fa-paste pointy comp panel-button' style={{
                    padding: '1vmax',
                    display: 'inline-block',
                    fontSize: '1.15vmax',
                    fontWeight: '300',
                    border: '0pt',
                    borderLeft: `1pt solid #faf3dd`,
                    marginLeft: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    let new_comps = [...props.comps];
                    let thisObj = props.comps[props.id].script[props.indices[0]];
                    for (let k = 0; k < props.indices.length - 1; ++k) {
                            thisObj = thisObj.children[props.indices[k + 1]];
                    }
                    navigator.clipboard.readText().then(text => {
                        try {
                            let parsedText = JSON.parse(text);
                            thisObj.children.push(parsedText);
                            props.setComps(new_comps);
                            props.setIsScriptRendered(false);
                        } catch (err) {

                        }
                    });
                }}></i>
            </div>
        </div>
        {
            isAddingCommand ? <AddCommand id={props.id} comps={props.comps}
                                            setComps={props.setComps} indices={props.indices}
                                            setIsScriptRendered={props.setIsScriptRendered}
                                            setIsAddingCommand={setIsAddingCommand}
                                            availableCommands={availableCommands} /> : <></>
        }
    </>;

}