
import ActionButton from "./ActionButton";

import AddElement from "./actions/AddElement";
import ViewPages from "./actions/ViewPages";
import ViewVars from "./actions/ViewVars";
import Settings from "./actions/Settings";
import Externals from "./actions/Externals";

import { useState, useEffect } from 'react';

export default function ActionPanel ({
    comps,
    setComps,
    page,
    setPage,
    pages,
    setPages,
    vars,
    setVars,
    externals,
    setExternals,
    parent,
    setParent,
    name,
    setName,
    dbId,
    setDbId,
    setSelectedEl
}) {

    const [ view, setView ] = useState('');
    // '' => default, 'add' => add component, 'states' => state machine
    // 'externals' => externals, 'settings' => settings

    let view_render = <></>;
    if (view === 'add') {
        view_render = <AddElement setSelectedEl={setSelectedEl} comps={comps} setComps={setComps} setView={setView} page={page} />;
    } else if (view === 'page') {
        view_render = <ViewPages comps={comps} setComps={setComps} setView={setView}
                    page={page} setPage={setPage} pages={pages} setPages={setPages} />;
    } else if (view === 'vars') {
        view_render = <ViewVars comps={comps} setComps={setComps} setView={setView}
                    vars={vars} setVars={setVars} />;
    } else if (view === 'settings') {
        view_render = <Settings comps={comps} setComps={setComps} setView={setView}
                    pages={pages} vars={vars}
                    externals={externals} parent={parent} name={name} setName={setName}
                    dbId={dbId} setDbId={setDbId} />;
    } else if (view === 'externals') {
        view_render = <Externals comps={comps} setComps={setComps} setView={setView}
                    pages={pages} setPages={setPages} vars={vars} setVars={setVars}
                    externals={externals} setExternals={setExternals} />;
    }

    return (
        <>
            <div>
                <ActionButton icon='plus' id={0} click={() => {
                    if (view === '') {
                        setView('add');
                    }
                }} />
                <ActionButton icon='table-list' id={1} click={() => {
                    if (view === '') {
                        setView('vars');
                    }
                }} />
                <ActionButton icon='ethernet' id={2} click={() => {
                    if (view === '') {
                        setView('externals');
                    }
                }} />
                <ActionButton icon='layer-group' id={3} click={() => {
                    if (view === '') {
                        setView('page');
                    }
                }} />
                <ActionButton icon='gear' id={4} click={() => {
                    if (view === '') {
                        setView('settings');
                    }
                }} />
            </div>
            { view_render }
        </>
    );

}