
// LogIn.js
// Component for log-in screen

// standard
import React from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setToken
} from '../../redux/features/tokenSlice';
import {
  selectUsername,
  setUsername
} from '../../redux/features/usernameSlice';
import {
  setId
} from '../../redux/features/idSlice';
import {
  setMode
} from '../../redux/features/modeSlice';

// stylesheets
import './Login.css';

// external packages
const axios = require('axios');

export default function Login () {

  const [ password, setPassword ] = useState('');
  const username = useSelector(selectUsername);
  const dispatch = useDispatch();

  const [ hasError, setHasError ] = useState(false);

  async function login () {
    axios
        .get(`${process.env.REACT_APP_DEV_SERVER}` + 'api/login', {
          params: {
            username: username,
            password: password
          }
        })
        .then((response) => {
          dispatch(setToken(response.data.token));
          dispatch(setId(response.data.id));
          dispatch(setMode(response.data.mode));
        })
        .catch((error) => {
          setHasError(true);
        });
  }

  return <>
      <div>
        <div className='logo'>
          <img src="/logo_white.svg" type="image/svg+xml"></img>
        </div>
        <div className='login'>
            <h1 style={{
                marginBottom: '0'
            }}>Atlas</h1>
            <h3 style={{
                marginTop: '0'
            }}>by Satet</h3>
            <i class="fa-solid fa-user"></i>
            <input placeholder="" className='auth-input' value={username}
                onChange={(e) => dispatch(setUsername(e.target.value))} />
            <br />
            <i class="fa-solid fa-key"></i>
            <input type="password" placeholder="" className='auth-input' value={password} style={{
              marginBottom: '1.5vmax',
              width: '18.75vmax'
            }}
                onChange={(e) => setPassword(e.target.value)} />
            <br />
            <a href='' className='auth-button' onClick={(e) => {
              e.preventDefault();
              login();
            }}><i class="fa-solid fa-arrow-right"></i></a>
        </div>
    </div>
    {hasError ? <div className='error-modal'>
        <p>Username or password is incorrect. Please try again.</p>
        <a href='' onClick={(e) => {
          e.preventDefault();
          setHasError(false);
        }}>Okay</a>
    </div> : <></>}
    
  </>;

}