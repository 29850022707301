
import {
    useLocation
} from 'react-router-dom';

import {
    useState,
    useRef,
    useEffect
} from 'react';

import NavBar from '../../../views/utils/NavBar';
import ControlPanel from '../../utils/ControlPanel';
import FormView from '../../utils/FormView';

// components
import Label from '../../components/primitive/Label';
import Box from '../../components/primitive/Box';
import Input from '../../components/primitive/Input';
import Button from '../../components/primitive/Button';
import Radio from '../../components/primitive/Radio';
import Check from '../../components/primitive/Check';
import List from '../../components/primitive/List';
import Drop from '../../components/primitive/Drop';
import Image from '../../components/primitive/Image';

import Signature from '../../components/intermediate/Signature';
import InputTable from '../../components/intermediate/InputTable';
import Snapshot from '../../components/intermediate/Snapshot';

import Header from '../../../views/utils/Header';
import Modal from '../../../views/utils/Modal';

import { useSelector } from 'react-redux';
import { selectToken } from '../../../redux/features/tokenSlice';

const axios = require('axios');

export default function CreateForm () {

    const token = useSelector(selectToken);

    let size_of_grid = window.innerWidth * 0.00001;

    const location = useLocation();

    const [ saveStatus, setSaveStatus ] = useState(0); // -1 = error, 0 = nothing, 1 = success
    const [ loaded, setLoaded ] = useState(false);
    const [ dbId, setDbId ] = useState('');

    const [ name, setName ] = useState('My Form');

    // Array of components to be rendered
    const [components, setComponents] = useState([]);

    // ID in component array of currently selected/hovered element
    const [selectedEl, setSelectedEl] = useState(-1);
    const [ contextMenu, setContextMenu ] = useState([false, -1, [0, 0]]); // isContextMenuShowing?, id of active el, coordinates
    const [hoveredEl, setHoveredEl] = useState(-1);

    // States for pages
    const [ pages, setPages ] = useState([
        {
            height: 0.7,
            visibleTo: 'all',
            editableTo: 'all'
        }
    ]);
    
    const [ currentPage, setCurrentPage ] = useState(0);
    
    const [ vars, setVars ] = useState([]);
    const [ externals, setExternals ] = useState({
        flags: []
    });

    const [ parent, setParent ] = useState('');

    try {
        if (!loaded) {
            if (Object.keys(location.state).length !== 0) {
                setName(location.state.name);
                setComponents(location.state.content);
                setPages(location.state.pages);
                setVars(location.state.vars);
                setExternals(location.state.externals);
                setParent(location.state.parent);
                setDbId(location.state._id);
                setLoaded(true);
            }
        }
    } catch (err) {

    }

    // control-s for saving
    // useEffect(() => {
    //     const handleKeyDown = (event) => {
    //         let form_obj = {
    //             name: name,
    //             content: components,
    //             pages: pages,
    //             vars: vars,
    //             externals: externals,
    //             parent: parent
    //         };
    //       console.log(form_obj);
    //       event.preventDefault();
    //       const code = event.which || event.keyCode;
    
    //       let charCode = String.fromCharCode(code).toLowerCase();
    //       if ((event.ctrlKey || event.metaKey) && charCode === 's' && loaded) {
    //             if (dbId !== '') {
    //                 axios.
    //                     patch(`${process.env.REACT_APP_DEV_SERVER}` + 'api/forms', {
    //                         query: {
    //                             _id: dbId
    //                         }, params: form_obj
    //                     }, {
    //                         headers: {
    //                             Authorization: 'Bearing ' + token
    //                         }
    //                     })
    //                     .then((response) => {
    //                         setSaveStatus(1);
    //                     })
    //                     .catch((error) => {
    //                         setSaveStatus(-1);
    //                     })
    //             }
    //       }
    //     };
    
    //     window.addEventListener('keydown', handleKeyDown);
    
    //     return () => window.removeEventListener('keydown', handleKeyDown);
    //   }, [name, components, pages, vars, externals, parent]);

    // Captures width and height of FormView, and updates components
    const dimRef = useRef(null);
    let components_render = [];
    for (let k = 0; k < components.length; ++k) {
        if (components[k].page === currentPage) {
            if (components[k].type === 'primitive/Label') {
                components_render.push(
                    <Label id={k} comps={components} setter={setComponents}
                        vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                        sel={selectedEl} setSel={setSelectedEl}
                        hov={hoveredEl} setHov={setHoveredEl}
                        vars={vars} setVars={setVars}
                        contextMenu={contextMenu} setContextMenu={setContextMenu} />
                );
            } else if (components[k].type === 'primitive/Box') {
                components_render.push(
                    <Box id={k} comps={components} setter={setComponents}
                        vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                        sel={selectedEl} setSel={setSelectedEl}
                        hov={hoveredEl} setHov={setHoveredEl}
                        vars={vars} setVars={setVars}
                        contextMenu={contextMenu} setContextMenu={setContextMenu} />
                );
            } else if (components[k].type === 'primitive/Input') {
                components_render.push(<Input id={k} comps={components} setter={setComponents}
                    vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                    sel={selectedEl} setSel={setSelectedEl}
                    hov={hoveredEl} setHov={setHoveredEl}
                    vars={vars} setVars={setVars}
                    contextMenu={contextMenu} setContextMenu={setContextMenu} />
                );
            } else if (components[k].type === 'primitive/Button') {
                components_render.push(<Button id={k} comps={components} setter={setComponents}
                    vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                    sel={selectedEl} setSel={setSelectedEl}
                    hov={hoveredEl} setHov={setHoveredEl}
                    vars={vars} setVars={setVars}
                    contextMenu={contextMenu} setContextMenu={setContextMenu} />
                );
            } else if (components[k].type === 'primitive/Radio') {
                components_render.push(<Radio id={k} comps={components} setter={setComponents}
                    vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                    sel={selectedEl} setSel={setSelectedEl}
                    hov={hoveredEl} setHov={setHoveredEl}
                    vars={vars} setVars={setVars}
                    contextMenu={contextMenu} setContextMenu={setContextMenu} />
                );
            } else if (components[k].type === 'primitive/Check') {
                components_render.push(<Check id={k} comps={components} setter={setComponents}
                    vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                    sel={selectedEl} setSel={setSelectedEl}
                    hov={hoveredEl} setHov={setHoveredEl}
                    vars={vars} setVars={setVars}
                    contextMenu={contextMenu} setContextMenu={setContextMenu} />
                );
            } else if (components[k].type === 'primitive/List') {
                components_render.push(<List id={k} comps={components} setter={setComponents}
                    vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                    sel={selectedEl} setSel={setSelectedEl}
                    hov={hoveredEl} setHov={setHoveredEl}
                    vars={vars} setVars={setVars}
                    contextMenu={contextMenu} setContextMenu={setContextMenu} />
                );
            } else if (components[k].type === 'primitive/Drop') {
                components_render.push(<Drop id={k} comps={components} setter={setComponents}
                    vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                    sel={selectedEl} setSel={setSelectedEl}
                    hov={hoveredEl} setHov={setHoveredEl}
                    vars={vars} setVars={setVars}
                    contextMenu={contextMenu} setContextMenu={setContextMenu} />
                );
            } else if (components[k].type === 'primitive/Image') {
                components_render.push(
                    <Image id={k} comps={components} setter={setComponents}
                        vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                        sel={selectedEl} setSel={setSelectedEl}
                        hov={hoveredEl} setHov={setHoveredEl}
                        vars={vars} setVars={setVars}
                        contextMenu={contextMenu} setContextMenu={setContextMenu} />
                );
            } else if (components[k].type === 'intermediate/Signature') {
                components_render.push(<Signature id={k} comps={components} setter={setComponents}
                    vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                    sel={selectedEl} setSel={setSelectedEl}
                    hov={hoveredEl} setHov={setHoveredEl}
                    vars={vars} setVars={setVars}
                    contextMenu={contextMenu} setContextMenu={setContextMenu} />
                );
            } else if (components[k].type === 'intermediate/InputTable') {
                components_render.push(
                    <InputTable id={k} comps={components} setter={setComponents}
                        vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                        sel={selectedEl} setSel={setSelectedEl}
                        hov={hoveredEl} setHov={setHoveredEl}
                        vars={vars} setVars={setVars}
                        contextMenu={contextMenu} setContextMenu={setContextMenu} />
                );
            } else if (components[k].type === 'intermediate/Snapshot') {
                components_render.push(
                    <Snapshot id={k} comps={components} setter={setComponents}
                        vw={0.75 * window.innerWidth} gridSize={size_of_grid}
                        sel={selectedEl} setSel={setSelectedEl}
                        hov={hoveredEl} setHov={setHoveredEl}
                        vars={vars} setVars={setVars}
                        contextMenu={contextMenu} setContextMenu={setContextMenu} />
                );
            }
        }
    }

    return (<><div style={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100vh',
        backgroundColor: '#0F1108',
        overflow: 'scroll'
    }} id="wrapper" onScroll={(e) => {
        setContextMenu([false, -1, [0, 0]]);
    }}>

        {/* <div style={{
            height: 'calc(4vmax + 12vh)',
            width: '100%',
            position: 'fixed',
            top: '0',
            left: '0',
            backgroundColor: '#0F1108',
            zIndex: '9',
            borderBottom: 'solid 1pt #FAF3DD'
        }}>
            <div className='logo'>
                <object data="/logo_black.svg" type="image/svg+xml"></object>
            </div>
        </div>
        <NavBar /> */}
        <Header />

        <FormView reference={dimRef} comps={components} setComps={setComponents}
        content={components_render} position={{
            x: '1.5vmax',
            y: '100vh - ((100vh - (4vmax + 12vh) - 5vh) - 5vw + 3.8vw + 1pt + 1.5vmax)'
        }} size={{
            w: '100vw - 3vmax - 20vw - 4pt - 1.5vmax'
        }} isEditable={true} pages={pages} setPages={setPages}
        currentPage={currentPage} setCurrentPage={setCurrentPage}
        setSelectedEl={setSelectedEl} setContextMenu={setContextMenu}
        css={{
            zIndex: '0'
        }} />
        {
            contextMenu[0] ? <div style={{
                position: 'absolute',
                top:`${document.getElementById("wrapper").scrollTop + contextMenu[2][1]}px`,
                left: `${contextMenu[2][0]}px`,
                zIndex: '100',
                background: '#000',
                color: '#faf3dd',
                border: '#faf3dd 1pt solid',
                width: '6vmax',
            }}>
                <div className='pointy dark-to-light-on-hover' style={{
                    width: '80%',
                    borderBottom: '#faf3dd 0.75pt solid',
                    padding: '0.5vmax',
                    fontWeight: '400',
                    fontSize: '1vmax'
                }} onClick={(e) => {
                    e.stopPropagation();
                    setContextMenu([false, -1, [0, 0]]);
                }}>Close</div>
                <div className='pointy dark-to-light-on-hover' style={{
                    width: '80%',
                    padding: '0.5vmax',
                    fontWeight: '400',
                    fontSize: '1vmax',
                    borderBottom: '#faf3dd 0.75pt solid'
                }} onClick={(e) => {
                    e.stopPropagation();
                    let new_comps = [...components];
                    new_comps.push(JSON.parse(JSON.stringify(new_comps[contextMenu[1]])));
                    setComponents(new_comps);
                    setContextMenu([false, -1, [0, 0]]);
                }}>Duplicate</div>
                <div className='pointy dark-to-light-on-hover' style={{
                    width: '80%',
                    padding: '0.5vmax',
                    fontWeight: '400',
                    fontSize: '1vmax',
                    borderBottom: '#faf3dd 0.75pt solid'
                }} onClick={(e) => {
                    e.stopPropagation();
                    setSelectedEl(-1);
                    if (pages.length > 1) {
                        let new_comps = [...components];
                        if (new_comps[contextMenu[1]].page + 1 === pages.length) {
                            new_comps[contextMenu[1]].page = 0;
                        } else {
                            new_comps[contextMenu[1]].page = new_comps[contextMenu[1]].page + 1;
                        }
                        setComponents(new_comps);
                    }
                    setContextMenu([false, -1, [0, 0]]);
                }}>To Right</div>
                <div className='pointy dark-to-light-on-hover' style={{
                    width: '80%',
                    padding: '0.5vmax',
                    fontWeight: '400',
                    fontSize: '1vmax',
                    borderBottom: '#faf3dd 0.75pt solid'
                }} onClick={(e) => {
                    e.stopPropagation();
                    setSelectedEl(-1);
                    if (pages.length > 1) {
                        let new_comps = [...components];
                        if (new_comps[contextMenu[1]].page === 0) {
                            new_comps[contextMenu[1]].page = pages.length - 1;
                        } else {
                            new_comps[contextMenu[1]].page = new_comps[contextMenu[1]].page - 1;
                        }
                        setComponents(new_comps);
                    }
                    setContextMenu([false, -1, [0, 0]]);
                }}>To Left</div>
                <div className='pointy dark-to-light-on-hover' style={{
                    width: '80%',
                    padding: '0.5vmax',
                    fontWeight: '400',
                    fontSize: '1vmax',
                    borderBottom: '#faf3dd 0.75pt solid'
                }} onClick={(e) => {
                    e.stopPropagation();
                    setSelectedEl(-1);
                    
                    let currentPage = components[contextMenu[1]].page;
                    let lowestIndexOnCurrentPage = -1;

                    for (let j = 0; j < components.length; ++j) {
                        if (components[j].page == currentPage) {
                            if (j < lowestIndexOnCurrentPage || lowestIndexOnCurrentPage == -1) {
                                lowestIndexOnCurrentPage = j;
                            }
                        }
                    }

                    let new_comps = [...components];
                    let currentElement = {...components[contextMenu[1]]};
                    new_comps.splice(contextMenu[1], 1);
                    new_comps.splice(lowestIndexOnCurrentPage, 0, currentElement);
                    setComponents(new_comps);
                    setContextMenu([false, -1, [0, 0]]);
                }}>To Bottom</div>
                <div className='pointy dark-to-light-on-hover' style={{
                    width: '80%',
                    padding: '0.5vmax',
                    fontWeight: '400',
                    fontSize: '1vmax',
                    borderBottom: '#faf3dd 0.75pt solid'
                }} onClick={(e) => {
                    e.stopPropagation();
                    setSelectedEl(-1);
                    
                    let currentPage = components[contextMenu[1]].page;
                    let highestIndexOnCurrentPage = -1;

                    for (let j = 0; j < components.length; ++j) {
                        if (components[j].page == currentPage) {
                            if (j > highestIndexOnCurrentPage || highestIndexOnCurrentPage == -1) {
                                highestIndexOnCurrentPage = j;
                            }
                        }
                    }

                    let new_comps = [...components];
                    let currentElement = {...components[contextMenu[1]]};
                    new_comps.splice(contextMenu[1], 1);
                    new_comps.splice(highestIndexOnCurrentPage + 1, 0, currentElement);
                    setComponents(new_comps);
                    setContextMenu([false, -1, [0, 0]]);
                }}>To Top</div>
                <div className='pointy dark-to-light-on-hover' style={{
                    width: '80%',
                    padding: '0.5vmax',
                    fontWeight: '400',
                    fontSize: '1vmax'
                }} onClick={(e) => {
                    e.stopPropagation();
                    setSelectedEl(-1);
                    let new_comps = [...components];
                    new_comps.splice(contextMenu[1], 1);
                    setComponents(new_comps);
                    setContextMenu([false, -1, [0, 0]]);
                }}>Delete</div>
            </div> : <></>
        }
        <ControlPanel comps={components} setComps={setComponents} sel={selectedEl}
            setSel={setSelectedEl} hov={hoveredEl} setHov={setHoveredEl} 
            pages={pages} setPages={setPages}
            currentPage={currentPage} setCurrentPage={setCurrentPage} vars={vars} setVars={setVars}
            externals={externals} setExternals={setExternals} parent={parent} setParent={setParent}
            name={name} setName={setName} dbId={dbId} setDbId={setDbId} />

    </div>
    { saveStatus === 1 ? <Modal width='15' height='21.5'>
            <p style={{
                fontWeight: '400',
                fontSize: '1.25vmax',
                marginTop: '3vh',
                padding: '1vmax'
            }}>Your form was successfully saved.</p>
            <a href='#' className='panel-button' onClick={(e) => {
                e.preventDefault();
                setSaveStatus(0);
            }}>Ok</a>
        </Modal> : <></> }
        { saveStatus === -1 ? <Modal width='15' height='21.5'>
            <p style={{
                fontWeight: '400',
                fontSize: '1.25vmax',
                marginTop: '3vh',
                padding: '1vmax'
            }}>Your form could not be saved.</p>
            <a href='#' className='panel-button' onClick={(e) => {
                e.preventDefault();
                setSaveStatus(0);
            }}>Ok</a>
        </Modal> : <></> }
    </>);

}