
import ActionPanel from "./ActionPanel";
import ListElement from "./ListElement";

// components
import { LabelProperties } from "../components/primitive/Label";
import { BoxProperties } from "../components/primitive/Box";
import { InputProperties } from "../components/primitive/Input";
import { ButtonProperties } from "../components/primitive/Button";
import { RadioProperties } from "../components/primitive/Radio";
import { CheckProperties } from "../components/primitive/Check";
import { ListProperties } from "../components/primitive/List";
import { DropProperties } from "../components/primitive/Drop";
import { ImageProperties } from "../components/primitive/Image";

import { SignatureProperties } from "../components/intermediate/Signature";
import { InputTableProperties } from "../components/intermediate/InputTable";
import { SnapshotProperties } from "../components/intermediate/Snapshot";

export default function ControlPanel ({ 
    comps,
    setComps, 
    sel, 
    setSel, 
    hov, 
    setHov,
    pages,
    setPages,
    currentPage,
    setCurrentPage,
    vars,
    setVars,
    externals,
    setExternals,
    parent,
    setParent,
    name,
    setName,
    dbId,
    setDbId
}) {

    const title = {
        color: '#FAF3DD',
        fontWeight: '300',
        fontSize: '1.5vmax',
        padding: '1vmax 0vmax',
        margin: '0',
        borderBottom: 'solid 1pt #FAF3DD'
    };

    const panel = {
        height: 'calc(((100vh - (4vmax + 12vh) - 5vh) - 5vw - 7vmax - 8pt) / 2)',
        overflow: 'scroll'
    };

    let properties = <></>;
    if (sel === -1) {
        properties = <p style={{
            color: '#FAF3DD',
            fontSize: '1vmax',
            marginTop: '10vh',
            fontWeight: '300'
        }}>Empty</p>
    } else {
        if (comps[sel].type === 'primitive/Label') {
            properties = <LabelProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} />;
        } else if (comps[sel].type === 'primitive/Box') {
            properties = <BoxProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} />;
        } else if (comps[sel].type === 'primitive/Input') {
            properties = <InputProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} />;
        } else if (comps[sel].type === 'primitive/Button') {
            properties = <ButtonProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} />;
        } else if (comps[sel].type === 'primitive/Radio') {
            properties = <RadioProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} />;
        } else if (comps[sel].type === 'primitive/Check') {
            properties = <CheckProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} />;
        } else if (comps[sel].type === 'primitive/List') {
            properties = <ListProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} />;
        } else if (comps[sel].type === 'primitive/Drop') {
            properties = <DropProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} />;
        } else if (comps[sel].type === 'primitive/Image') {
            properties = <ImageProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} />;
        } else if (comps[sel].type === 'intermediate/Signature') {
            properties = <SignatureProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} />;
        } else if (comps[sel].type === 'intermediate/InputTable') {
            properties = <InputTableProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
                vars={vars} setVars={setVars} />;
        } else if (comps[sel].type === 'intermediate/Snapshot') {
            properties = <SnapshotProperties id={sel} comps={comps} setter={setComps} setSel={setSel}
            vars={vars} setVars={setVars} />; 
        }
    }

    let elements = [];
    for (let k = 0; k < comps.length; ++k) {
        if (comps[k].page === currentPage) {
            elements.push(
                <ListElement id={k} comps={comps} setComps={setComps} sel={sel} setSel={setSel}
                    hov={hov} setHov={setHov} />
            );
        }
    } if (elements.length === 0) {
        elements = <p style={{
            color: '#FAF3DD',
            fontSize: '1vmax',
            marginTop: '10vh',
            fontWeight: '300'
        }}>Empty</p>;
    }

    return (
        <div style={{
            position: 'fixed',
            right: '1.5vmax',
            bottom: '1.5vmax',
            width: 'calc(20vw + 3pt)',
            zIndex: '0',
            textAlign: 'center',
            border: 'solid 1pt #FAF3DD'
        }}>
            <h1 style={title}>Properties</h1>
            <div style={panel}> 
                { properties }
            </div>
            <h1 style={{
                    ...title,
                    borderTop: 'solid 1pt #FAF3DD'
                }}>Elements</h1>
            <div style={panel}>
                { elements }
            </div>
            <ActionPanel 
                setSelectedEl={setSel}
                comps={comps} setComps={setComps} page={currentPage} setPage={setCurrentPage}
                pages={pages} setPages={setPages} vars={vars} setVars={setVars}
                externals={externals} setExternals={setExternals} parent={parent} setParent={setParent}
                name={name} setName={setName} dbId={dbId} setDbId={setDbId} />
        </div>
    );

}