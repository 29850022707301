
// Process theme object
// Return [bg, fg] colors

export default function process_theme(theme) {
    if (theme === '1') {
        return ['#0f1108', '#faf3dd'];
    } else if (theme === '2') {
        return ['#faf3dd', '#0f1108'];
    }
}