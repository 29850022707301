
import Modal from "../../../views/utils/Modal";

import {
    useState
} from 'react';

export default function Externals ({
    comps,
    setComps,
    setView,
    pages,
    setPages,
    vars,
    setVars,
    externals,
    setExternals
}) {

    const [ isManagingFlags, setIsManagingFlags ] = useState(false);
    const [ isEditingFlag, setIsEditingFlag ] = useState(false);
    
    let flags_overview = [];
    if (externals.flags.length === 0) {
        flags_overview = <p style={{
            fontWeight: '300'
        }}>No Flags</p>;
    } else {
        for (let k = 0; k < externals.flags.length; ++k) {
            flags_overview.push(
                <div style={{
                    width: '90%',
                    marginLeft: '5%',
                    fontWeight: '300',
                    padding: '1vmax 0',
                    border: '1pt #faf3dd solid',
                    borderTop: k !== 0 ? '0pt' : '1pt #faf3dd solid'
                }}>
                    {externals.flags[k].name}
                    <a href='#' className='panel-button' style={{
                        marginLeft: '2vmax'
                    }} onClick={(e) => {
                        e.preventDefault();
                        setIsEditingFlag(k);
                    }}>Edit</a>
                    <a href='#' className='panel-button' style={{
                        marginLeft: '1vmax'
                    }} onClick={(e) => {
                        e.preventDefault();
                        let new_externals = {...externals};
                        new_externals.flags.splice(k, 1);
                        setExternals(new_externals);
                    }}>Delete</a>
                </div>
            );
        }
    }

    let flag_overview = [];
    if (isEditingFlag !== false) {
        for (let k = 0; k < externals.flags[isEditingFlag].values.length; ++k) {
            flag_overview.push(
                <div style={{
                    width: '90%',
                    marginLeft: '5%',
                    fontWeight: '300',
                    padding: '1vmax 0',
                    border: '1pt #faf3dd solid',
                    borderTop: k !== 0 ? '0pt' : '1pt #faf3dd solid',
                    backgroundColor: parseInt(externals.flags[isEditingFlag].value) === k ? '#faf3dd' : '#000',
                    color: parseInt(externals.flags[isEditingFlag].value) === k ? '#000' : '#faf3dd'
                }}>
                    <input type='color' className='panel-color-input' style={{
                        width: '1.6vmax',
                        marginRight: '1vmax'
                    }} onChange={(e) => {
                        let new_externals = {...externals};
                        new_externals.flags[isEditingFlag].colors[k] = e.target.value;
                        setExternals(new_externals);
                    }} value={externals.flags[isEditingFlag].colors[k]} />
                    <input className='panel-input' value={externals.flags[isEditingFlag].values[k]} style={{
                        fontWeight: parseInt(externals.flags[isEditingFlag].value) === k ? '400' : '300',
                        padding: '0.5vmax',
                        backgroundColor: parseInt(externals.flags[isEditingFlag].value) === k ? '#faf3dd' : '#000',
                        color: parseInt(externals.flags[isEditingFlag].value) === k ? '#000' : '#faf3dd',
                        borderColor: parseInt(externals.flags[isEditingFlag].value) === k ? '#000' : '#faf3dd'
                    }} onChange={(e) => {
                        let new_externals = {...externals};
                        new_externals.flags[isEditingFlag].values[k] = e.target.value;
                        setExternals(new_externals);
                    }}></input>
                    <a href='#' className='panel-button' style={{
                        fontWeight: parseInt(externals.flags[isEditingFlag].value) === k ? '400' : '300',
                        marginLeft: '1vmax',
                        backgroundColor: parseInt(externals.flags[isEditingFlag].value) === k ? '#faf3dd' : '#000',
                        color: parseInt(externals.flags[isEditingFlag].value) === k ? '#000' : '#faf3dd',
                        borderColor: parseInt(externals.flags[isEditingFlag].value) === k ? '#000' : '#faf3dd'
                    }} onClick={(e) => {
                        e.preventDefault();
                        let new_externals = {...externals};
                        new_externals.flags[isEditingFlag].value = k;
                        setExternals(new_externals);
                    }}>Set To Default</a>
                    <a href='#' className='panel-button' style={{
                        fontWeight: parseInt(externals.flags[isEditingFlag].value) === k ? '400' : '300',
                        marginLeft: '1vmax',
                        backgroundColor: parseInt(externals.flags[isEditingFlag].value) === k ? '#faf3dd' : '#000',
                        color: parseInt(externals.flags[isEditingFlag].value) === k ? '#000' : '#faf3dd',
                        borderColor: parseInt(externals.flags[isEditingFlag].value) === k ? '#000' : '#faf3dd'
                    }} onClick={(e) => {
                        e.preventDefault();
                        if (externals.flags[isEditingFlag].values.length > 1) {
                            let new_externals = {...externals};
                            new_externals.flags[isEditingFlag].values.splice(k, 1);
                            new_externals.flags[isEditingFlag].colors.splice(k, 1);
                            new_externals.flags[isEditingFlag].value = 0;
                            setExternals(new_externals);
                        }
                    }}>Delete</a>
                </div>
            );
        }
    }

    return <>
        <Modal width={30} height={45}>
            <h1 style={{
                    color: '#FAF3DD',
                    fontWeight: '300',
                    margin: '0',
                    padding: '1vmax 0',
                    fontSize: '1.75vmax',
                    borderBottom: '1px #FAF3DD solid',
                    display: 'block'
            }}>Externals</h1>
            <div style={{
                overflow: 'scroll',
                height: '70%'
            }}>
                <a href='#' className='panel-button' onClick={(e) => {
                    e.preventDefault();
                    setIsManagingFlags(true);
                }} style={{
                    marginTop: '2vmax'
                }}>Manage Flags</a>
            </div>
            <div style={{
                position: 'absolute',
                bottom: '0',
                width: '100%',
                backgroundColor: '#000000'
            }}>
                <a href='' className='panel-button' style={{
                    marginBottom: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    setView('');
                }}>Exit</a>
            </div>
        </Modal>
        { isManagingFlags ? <Modal width={50} height={50}>
            <h1 style={{
                    color: '#FAF3DD',
                    fontWeight: '300',
                    margin: '1vmax 0',
                    padding: '1vmax 0',
                    fontSize: '1.75vmax',
                    display: 'block'
            }}>Flag Manager</h1>
            <div style={{
                height: '65%',
                overflow: 'scroll'
            }}>
                { flags_overview }
            </div>
            <div style={{
                position: 'absolute',
                bottom: '0',
                width: '100%',
                backgroundColor: '#000000'
            }}>
                <a href='' className='panel-button' style={{
                    marginBottom: '1vmax',
                    marginRight: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    let new_externals = {
                        ...externals
                    };
                    new_externals.flags.push({
                        name: 'New Flag',
                        values: [ 'Incomplete', 'In Progress', 'Complete' ],
                        colors: [ '#E74C3C', '#F1C40F', '#2ECC71' ],
                        value: 0
                    });
                    setExternals(new_externals);
                }}>Add Flag</a>
                <a href='' className='panel-button' style={{
                    marginBottom: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    setIsManagingFlags(false);
                }}>Exit</a>
            </div>
        </Modal> : <></> }
        { isEditingFlag !== false ? <Modal width={50} height={50}>
            <h1 style={{
                    color: '#FAF3DD',
                    fontWeight: '300',
                    margin: '1vmax 0',
                    padding: '1vmax 0',
                    fontSize: '1.75vmax',
                    display: 'block'
            }}>Edit
                <input className='panel-input' value={externals.flags[isEditingFlag].name} style={{
                    fontSize: '1.25vmax',
                    padding: '0.75vmax',
                    marginLeft: '1vmax',
                    fontWeight: '300'
                }} onChange={(e) => {
                    let new_externals = {...externals};
                    new_externals.flags[isEditingFlag].name = e.target.value;
                    setExternals(new_externals);
                }}></input>
            </h1>
            <div style={{
                height: '65%',
                overflow: 'scroll'
            }}>
                { flag_overview }
            </div>
            <div style={{
                position: 'absolute',
                bottom: '0',
                width: '100%',
                backgroundColor: '#000000'
            }}>
                <a href='#' className='panel-button' style={{
                    marginBottom: '1vmax',
                    marginRight: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    let new_externals = {...externals};
                    new_externals.flags[isEditingFlag].values.push('New State');
                    new_externals.flags[isEditingFlag].colors.push('#ffffff');
                    setExternals(new_externals);
                }}>Add State</a>
                <a href='' className='panel-button' style={{
                    marginBottom: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    setIsEditingFlag(false);
                }}>Exit</a>
            </div>
        </Modal> : <></> }
    </>;

}