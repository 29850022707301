
export default function Modal (props) {
    return (
        <div style={{
            width: props.width + 'vw',
            height: props.height + 'vh',
            backgroundColor: '#000000',
            border: '1px #FAF3DD solid',
            boxShadow: '#FAF3DD 0px 0px 20px 0.5px',
            color: '#FAF3DD',
            textAlign: 'center',
            position: 'fixed',
            left: '0',
            right: '0',
            top: '0',
            bottom: '0',
            margin: 'auto',
            maxWidth: '100vw',
            maxHeight: '100vw',
            overflow: 'scroll',
            zIndex: '9',
            marginTop: ( "marginTop" in props === true ) ? props.marginTop : 'auto'
        }}>
            {props.children}
        </div>
    );
}