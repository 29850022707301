
import { Rnd } from 'react-rnd';
import {
    useState,
    useEffect,
    useRef
} from 'react';

import process_vars from '../../process_vars';
import '../component.css';

import ScriptView from '../script/ScriptView';

export default function Button ({ id, comps, setter, vw, gridSize, sel, setSel, hov, setHov, vars, setVars, contextMenu, setContextMenu }) {

    let background_color = '';
    if (hov === id) {
        background_color = '#FAF3DD';
    } else {
        background_color = process_vars(comps[id].props.backgroundColor, vars);
    }

    let innerContent = <></>;
    if (sel === id) {
        background_color = comps[id].props.backgroundColor;
        innerContent = <input className='view-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.text = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.text} onClick={(e) =>{
            e.stopPropagation();
        }} onDoubleClick={(e) => {
            e.target.select();
        }} style={{
            width: '100%',
            height: '100%',
            textAlign: process_vars(comps[id].props.alignment, vars),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: process_vars(comps[id].props.fontSize, vars) + 'vmax',
            color: process_vars(comps[id].props.color, vars),
            backgroundColor: background_color,
            fontWeight: process_vars(comps[id].props.bold, vars) ? '700' : '400',
            fontStyle: process_vars(comps[id].props.italic, vars) ? 'italic' : '',
            textDecoration: process_vars(comps[id].props.underlined, vars) ? 'underline' : ''
        }} />
    } else {
        innerContent = <p style={{
            color: process_vars(comps[id].props.color, vars),
            width: '90%',
            height: '100%',
            textAlign: process_vars(comps[id].props.alignment, vars),
            display: "flex",
            alignItems: "center",
            justifyContent: process_vars(comps[id].props.alignment, vars),
            fontSize: process_vars(comps[id].props.fontSize, vars) + 'vmax',
            fontWeight: process_vars(comps[id].props.bold, vars) ? '700' : '400',
            fontStyle: process_vars(comps[id].props.italic, vars) ? 'italic' : '',
            textDecoration: process_vars(comps[id].props.underlined, vars) ? 'underline' : '',
            overflow: 'hidden'
        }} className='pointy'>{process_vars(comps[id].props.text, vars)}</p>;
    }

    return (
        <div>
            <Rnd className='comp' style={{
                backgroundColor: background_color,
                color: process_vars(comps[id].props.color, vars),
                textAlign: 'center',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: 'hidden',
                zIndex: '0',
                border: process_vars(comps[id].props.borderEnabled, vars) ?
                            `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px'
            }} position={{
                x: comps[id].position[0] * vw,
                y: comps[id].position[1] * vw
            }} size={{
                width: comps[id].size[0] * vw,
                height: comps[id].size[1] * vw
            }} onDragStop={(e, d) => {
                let new_comps = [...comps];
                new_comps[id].position = [
                    d.x / vw,
                    d.y / vw
                ];
                setter(new_comps);
            }} onResize={(e, direction, ref, delta, position) => {
                let new_comps = [...comps];
                new_comps[id].position = [
                    position.x / vw,
                    position.y / vw
                ];
                new_comps[id].size = [
                    ref.offsetWidth / vw,
                    ref.offsetHeight / vw
                ];
                setter(new_comps);
            }} minWidth={window.innerWidth * 0.05} minHeight={window.innerWidth * 0.025} bounds='.form-view' 
            dragGrid={[
                gridSize,
                gridSize
            ]} resizeGrid={[
                gridSize,
                gridSize
            ]} onMouseEnter={() => {
                setHov(id);
            }} onMouseLeave={() => {
                setHov(-1);
            }} onDoubleClick={() => {
                setSel(id);
            }} onContextMenu={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setContextMenu([true, id, [e.clientX, e.clientY]]);
            }} onClick={(e) => {
                e.stopPropagation();
            }}>{ innerContent }</Rnd>
        </div>
    );

}

export function ButtonProperties ({ id, comps, setter, setSel, vars, setVars }) {

    const [ isShowingScript, setIsShowingScript ] = useState(false);

    return (<>
        <br></br>
        <a className='panel-button' onClick={(e) => {
            e.preventDefault();
            setIsShowingScript(true);
        }}>Edit Script</a>
        <br /><br />
        <p className='panel-label'>Position: </p>
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].position[0] = e.target.value;
            setter(new_comps);
        }} value={comps[id].position[0]} style={{width: '15%'}} placeholder='X' />
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].position[1] = e.target.value;
            setter(new_comps);
        }} value={comps[id].position[1]} style={{width: '15%',
            marginLeft: '0.5vmax'}} placeholder='Y' />
        <br /> <br />
        <p className='panel-label'>Dimensions: </p>
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].size[0] = e.target.value;
            setter(new_comps);
        }} value={comps[id].size[0]} style={{width: '15%'}} placeholder='Width' />
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].size[1] = e.target.value;
            setter(new_comps);
        }} value={comps[id].size[1]} style={{width: '15%',
            marginLeft: '0.5vmax'}} placeholder='Height' />
        <br /> <br />
        <p className='panel-label'>Size: </p>
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.fontSize = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.fontSize} />
        <br /> <br />
        <p className='panel-label'>Style: </p>
        <i className='fa-solid fa-bold panel-icon' style={{
            color: comps[id].props.bold ? '#0F1108' : '#FAF3DD',
            backgroundColor: comps[id].props.bold ? '#FAF3DD' : '#0F1108'
        }} onClick={() => {
            let new_comps = [...comps];
            new_comps[id].props.bold = !new_comps[id].props.bold;
            setter(new_comps);
        }}></i>
        <i className='fa-solid fa-italic panel-icon' style={{
            color: comps[id].props.italic ? '#0F1108' : '#FAF3DD',
            backgroundColor: comps[id].props.italic ? '#FAF3DD' : '#0F1108'
        }} onClick={() => {
            let new_comps = [...comps];
            new_comps[id].props.italic = !new_comps[id].props.italic;
            setter(new_comps);
        }}></i>
        <i className='fa-solid fa-underline panel-icon' style={{
            color: comps[id].props.underlined ? '#0F1108' : '#FAF3DD',
            backgroundColor: comps[id].props.underlined ? '#FAF3DD' : '#0F1108'
        }} onClick={() => {
            let new_comps = [...comps];
            new_comps[id].props.underlined = !new_comps[id].props.underlined;
            setter(new_comps);
        }}></i>
        <br /> <br />
        <p className='panel-label'>Alignment: </p>
        <i className='fa-solid fa-align-left panel-icon' style={{
            color: comps[id].props.alignment === 'left' ? '#0F1108' : '#FAF3DD',
            backgroundColor: comps[id].props.alignment === 'left' ? '#FAF3DD' : '#0F1108'
        }} onClick={() => {
            let new_comps = [...comps];
            new_comps[id].props.alignment = 'left';
            setter(new_comps);
        }}></i>
        <i className='fa-solid fa-align-center panel-icon' style={{
            color: comps[id].props.alignment === 'center' ? '#0F1108' : '#FAF3DD',
            backgroundColor: comps[id].props.alignment === 'center' ? '#FAF3DD' : '#0F1108'
        }} onClick={() => {
            let new_comps = [...comps];
            new_comps[id].props.alignment = 'center';
            setter(new_comps);
        }}></i>
        <i className='fa-solid fa-align-right panel-icon' style={{
            color: comps[id].props.alignment === 'right' ? '#0F1108' : '#FAF3DD',
            backgroundColor: comps[id].props.alignment === 'right' ? '#FAF3DD' : '#0F1108'
        }} onClick={() => {
            let new_comps = [...comps];
            new_comps[id].props.alignment = 'right';
            setter(new_comps);
        }}></i>
        <br /> <br />
        <p className='panel-label'>Text Color: </p>
        <input className='panel-input' style={{
            width: '17.5%',
            marginRight: '0.75vmax'
        }} onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.color = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.color} />
        <p className='panel-label'>or</p>
        <input type='color' className='panel-color-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.color = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.color} />
        <br /> <br />
        <p className='panel-label'>Background Color: </p>
        <input className='panel-input' style={{
            width: '17.5%',
            marginRight: '0.75vmax'
        }} onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.backgroundColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.backgroundColor} />
        <p className='panel-label'>or</p>
        <input type='color' className='panel-color-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.backgroundColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.backgroundColor} />
        <br /> <br />
        <p className='panel-label'>Border Enabled: </p>
        <input type="checkbox" checked={comps[id].props.borderEnabled} onClick={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderEnabled = !new_comps[id].props.borderEnabled;
            setter(new_comps);
        }}></input>
        <br /><br />
        <p className='panel-label'>Border Color: </p>
        <input className='panel-input' style={{
            width: '17.5%',
            marginRight: '0.75vmax'
        }} onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.borderColor} />
        <p className='panel-label'>or</p>
        <input type='color' className='panel-color-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.borderColor} />
        <br /><br />
        <p className='panel-label'>Border Width:</p>
        <input type='range' min='1' max='10' value={comps[id].props.borderWidth} onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderWidth = parseInt(e.target.value);
            setter(new_comps);
        }}></input>
        <br /><br />
        <a className='panel-button' onClick={(e) => {
            e.preventDefault();
            setSel(-1);
        }}>Ok</a>
        <a className='panel-button' onClick={(e) => {
            e.preventDefault();
            let new_comps = [...comps];
            new_comps.splice(id, 1);
            setSel(-1);
            setter(new_comps);
        }} style={{
            marginLeft: '1vmax'
        }}>Delete</a>
        <br /> <br />
        { isShowingScript ? <ScriptView id={id} comps={comps} setComps={setter}
                                        setIsShowingScript={setIsShowingScript}
                                        vars={vars} setVars={setVars} /> : <></> }
    </>);

}

export function ButtonAdder ({ comps, setComps, setView, page, setSelectedEl }) {

    const [ hovered, setHovered ] = useState(false);

    return (
        <div style={{
            border: '1px #FAF3DD solid',
            display: 'inline-block',
            padding: '1vmax',
            color: hovered ? 'black' : '#FAF3DD',
            backgroundColor: hovered ? '#FAF3DD' : 'black',
            transition: 'background-color 0.1s, color 0.1s',
            marginTop: '1vmax'
        }} onMouseEnter={() => {
            setHovered(true);
        }} onMouseLeave={() => {
            setHovered(false);
        }} onClick={(e) => {
            e.preventDefault();
            let new_comps = [...comps];
            new_comps.push({
                page: page,
                type: 'primitive/Button',
                name: 'Button',
                position: [0.475, 0.15 + document.getElementById("wrapper").scrollTop / document.getElementById("wrapper").offsetWidth],
                size: [0.10, 0.05],
                props: {
                    text: 'Button',
                    fontSize: 1,
                    bold: false,
                    italic: false,
                    underlined: false,
                    color: '#000000',
                    backgroundColor: '#ffffff',
                    alignment: 'center',
                    borderEnabled: true,
                    borderColor: '#000000',
                    borderWidth: 1
                }, script: [
                    {
                        type: "event/start",
                        children: []
                    },
                    {
                        type: "event/change",
                        children: []
                    },
                    {
                        type: "event/save",
                        children: []
                    }
                ],
                error: "",
                warning: "",
                disable: false
            });
            setComps(new_comps);
            setView('');
            setSelectedEl(comps.length);
        }} className='pointy'>
            <i className="fa-solid fa-computer-mouse" style={{
                color: hovered ? 'black' : '#FAF3DD'
            }}></i>
            <p className='panel-label' style={{
                color: hovered ? 'black' : '#FAF3DD',
                marginLeft: '1vmax',
                fontSize: '1.15vmax'
            }}>Button</p>
        </div>
    );

}