
import { useState } from "react";
import Modal from "../../../views/utils/Modal";

export default function ViewPages ({ comps, setComps, setView, page, setPage, pages, setPages }) {

    const [ del, setDel ] = useState(false);

    let page_list = [];
    for (let k = 0; k < pages.length; k++) {
        page_list.push(
            <div style={{
                padding: '0.5vmax',
                textAlign: 'justify',
                border: '1px #FAF3DD solid',
                borderTop: k !== 0 ? '' : '1px #FAF3DD solid',
                margin: '2vmax 2vmax 0 2vmax',
                marginTop: k !== 0 ? '' : '2vmax',
                backgroundColor: page === k ? '#FAF3DD' : '#000'
            }}>
                <i class="fa-solid fa-scroll" style={{
                    color: page === k ? '#000' : '#FAF3DD',
                    fontSize: '1.25vmax',
                    margin: '0 1vmax 0 0.5vmax'
                }}></i>
                <p className="panel-label" style={{
                    fontWeight: '300',
                    fontSize: '1.25vmax',
                    color: page === k ? '#000' : '#FAF3DD'
                }}>Page {k + 1}</p>
                <i class="fa-solid fa-eye pointy" style={{
                    color: page === k ? '#000' : '#FAF3DD',
                    fontSize: '1.25vmax',
                    margin: '0 1.75vmax 0 30%'
                }} onClick={() => {
                    setPage(k);
                }}></i>
                <i class="fa-solid fa-angles-down pointy" style={{
                    color: page === k ? '#000' : '#FAF3DD',
                    fontSize: '1.25vmax',
                    margin: '0 1vmax 0 0vmax'
                }} onClick={() => {
                    if (k !== pages.length - 1) {
                        let new_comps = [...comps];
                        for (let j = 0; j < new_comps.length; ++j) {
                            if (new_comps[j].page === k) {
                                new_comps[j].page += 1;
                            } else if (new_comps[j].page === k + 1) {
                                new_comps[j].page -= 1;
                            }
                        } setComps(new_comps);
                        let current = pages[k];
                        let new_pages = [...pages];
                        new_pages[k] = new_pages[k + 1];
                        new_pages[k + 1] = current;
                        setPages(new_pages);
                        setPage(k + 1);
                    }
                }}></i>
                <i class="fa-solid fa-angles-up pointy" style={{
                    color: page === k ? '#000' : '#FAF3DD',
                    fontSize: '1.25vmax',
                    margin: '0 0vmax 0 0vmax'
                }} onClick={() => {
                    if (k !== 0) {
                        let new_comps = [...comps];
                        for (let j = 0; j < new_comps.length; ++j) {
                            if (new_comps[j].page === k) {
                                new_comps[j].page -= 1;
                            } else if (new_comps[j].page === k - 1) {
                                new_comps[j].page += 1;
                            }
                        } setComps(new_comps);
                        let current = pages[k];
                        let new_pages = [...pages];
                        new_pages[k] = new_pages[k - 1];
                        new_pages[k - 1] = current;
                        setPages(new_pages);
                        setPage(k - 1);
                    }
                }}></i>
                <i class="fa-solid fa-trash pointy" style={{
                    color: page === k ? '#000' : '#FAF3DD',
                    fontSize: '1.25vmax',
                    margin: '0 0vmax 0 2vmax'
                }} onClick={() => {
                    if (pages.length > 1)
                        setDel(k);
                }}></i>
            </div>
        );
    }

    return (
        <>
        <Modal width={30} height={40}>
            <h1 style={{
                color: '#FAF3DD',
                fontWeight: '300',
                margin: '0',
                padding: '1vmax 0',
                fontSize: '1.75vmax',
                borderBottom: '1px #FAF3DD solid',
                display: 'block'
            }}>View Pages</h1>
            <div style={{
                height: '27.5vh',
                overflow: 'scroll'
            }}>
                {page_list}
                <br /> <br /> <br />
            </div>
            <div style={{
                position: 'absolute',
                bottom: '0',
                width: '100%',
                backgroundColor: '#000000'
            }}>
                <a href='' className='panel-button' style={{
                    margin: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    let new_pages = [...pages];
                    new_pages.splice(page + 1, 0, {
                        height: 0.7,
                        visibleTo: 'all'
                    }); setPages(new_pages);
                    let new_comps = [...comps];
                    for (let k = 0; k < new_comps.length; ++k) {
                        if (new_comps[k].page > page) {
                            new_comps[k].page += 1;
                        }
                    } setComps(new_comps);
                    setPage(page + 1);
                }}>Add Page</a>
                <a href='' className='panel-button' style={{
                    marginBottom: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    setView('');
                }}>Ok</a>
            </div>
        </Modal>

        { del !== false ? <Modal width='15' height='15'>
            <p style={{
                fontWeight: '400',
                fontSize: '1.25vmax',
                marginTop: '3vh'
            }}>Confirm Deletion?</p>
            <a href='#' className='panel-button' style={{
                marginRight: '1vmax'
            }} onClick={() => {
                let new_comps = [...comps];
                let newer_comps = [];
                for (let j = 0; j < new_comps.length; ++j) {
                    if (new_comps[j].page < del) {
                        newer_comps.push(new_comps[j]);
                    } else if (new_comps[j].page > del) {
                        new_comps[j].page -= 1;
                        newer_comps.push(new_comps[j]);
                    }
                } setComps(newer_comps);
                let new_pages = [...pages];
                new_pages.splice(del, 1);
                if (page > del) {
                    setPage(page - 1);
                } else if (del === pages.length - 1 && page === del) {
                    setPage(del - 1);
                } setPages(new_pages);
                setDel(false);
            }}>Yes</a>
            <a href='#' className='panel-button' onClick={() => {
                setDel(false);
            }}>No</a>
        </Modal> : <></> }
        </>
    );

}