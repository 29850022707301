
import {
    useLocation,
    useNavigate
} from 'react-router-dom';

import process_vars from '../../../src/forms/process_vars.js';

import { useSelector } from 'react-redux';
import { selectId } from '../../redux/features/idSlice';
import { selectToken } from '../../redux/features/tokenSlice';

import { renderToStaticMarkup } from "react-dom/server"

import Modal from '../../views/utils/Modal';
import NavBar from "../../views/utils/NavBar";

import Datetime from 'react-datetime';

import {
    useState,
    useEffect,
    ReactDOM,
    useRef,
    useReducer
} from 'react';

import axios from 'axios';
import Header from '../../views/utils/Header';

import Box from '../components/Box';
import Label from '../components/Label';
import Button from '../components/Button';
import Input from '../components/Input';
import List from '../components/List';
import Check from '../components/Check';
import Radio from '../components/Radio';
import Drop from '../components/Drop';
import Image from '../components/Image';

import Signature from '../components/Signature';
import InputTable, { renderTableLabelText, process_table_vars } from '../components/InputTable';
import Snapshot from '../components/Snapshot';

import domToImage from 'dom-to-image';
import jsPDF from 'jspdf';

const math = require('mathjs');

function contentFor(record, page) {
    let components_render = [];
    let components = record.content;
    let vars = record.vars;
    for (let k = 0; k < components.length; ++k) {
        if (components[k].page === page) {
            if (components[k].type === 'primitive/Box') {
                components_render.push(
                    <Box  id={k} comps={components}
                            vw={0.9 * window.innerWidth} vars={vars} />
                );
            } else if (components[k].type === 'primitive/Label') {
                components_render.push(
                    <Label id={k} comps={components}
                            vw={0.9 * window.innerWidth} vars={vars} />
                );
            } else if (components[k].type === 'primitive/Button') {
                components_render.push(
                    <Button id={k} comps={components}
                            vw={0.9 * window.innerWidth} vars={vars} />
                );
            } else if (components[k].type === 'primitive/Input') {
                components_render.push(
                    <Input id={k} comps={components}
                            vw={0.9 * window.innerWidth} vars={vars} />
                );
            } else if (components[k].type === 'primitive/List') {
                components_render.push(
                    <List id={k} comps={components}
                            vw={0.9 * window.innerWidth} vars={vars} />
                );
            } else if (components[k].type === 'primitive/Check') {
                components_render.push(
                    <Check id={k} comps={components}
                            vw={0.9 * window.innerWidth} vars={vars} />
                );
            } else if (components[k].type === 'primitive/Radio') {
                components_render.push(
                    <Radio id={k} comps={components}
                            vw={0.9 * window.innerWidth} vars={vars} />
                );
            } else if (components[k].type === 'primitive/Drop') {
                components_render.push(
                    <Drop id={k} comps={components}
                            vw={0.9 * window.innerWidth} vars={vars} />
                );
            } else if (components[k].type === 'primitive/Image') {
                components_render.push(
                    <Image  id={k} comps={components}
                            vw={0.9 * window.innerWidth} vars={vars} />
                );
            } else if (components[k].type === 'intermediate/Signature') {
                components_render.push(
                    <Signature id={k} comps={components}
                            vw={0.9 * window.innerWidth} vars={vars} />
                );
            } else if (components[k].type === 'intermediate/InputTable') {
                components_render.push(
                    <InputTable id={k} comps={components}
                            vw={0.9 * window.innerWidth} vars={vars} />
                );
            } else if (components[k].type === 'intermediate/Snapshot') {
                components_render.push(
                    <Snapshot id={k} comps={components}
                            vw={0.9 * window.innerWidth} vars={vars} />
                );
            }
        }
    } return <div style={{
        position: 'relative',
        background: '#ffffff', 
        width: '90vw',
        height: (record.pages[page].height * 90).toString() + 'vw',
        marginLeft: '5vw',
        marginTop: '2.5vmax'
    }}>{components_render}</div>;
}

export default function SearchRecords({}) {

    // const [value, onChange] = useState(new Date());
    // <div style={{
    //     backgroundColor: '#ffac0a',
    //     display: 'inline-block'
    // }}>
    //     <DateTimePicker onChange={onChange} value={value} />
    // </div>

    const location = useLocation();
    const navigate = useNavigate();

    const id = useSelector(selectId);
    const token = useSelector(selectToken);

    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ isRendered, setIsRendered ] = useState(true);
    const [ isDeleting, setIsDeleting ] = useState(false);
    const [ isShowingLink, setIsShowingLink ] = useState(false);
    const [ isFiltering, setIsFiltering ] = useState(false);
    const [ filters, setFilters ] = useState([
        [], // datetime
        [], // flag
        [] // name
    ]); // array of filtering conditions

    const downloadRef = useRef();

    function downloadRecord (doc, page_num, num_of_pages) {
        return new Promise((res) => {
            let downloadDiv = document.getElementById("downloadDiv");
            console.log(downloadDiv);
            domToImage.toJpeg(downloadDiv, { quality: 1.0 })
            .then(function (dataUrl) {
                if (page_num !== 0) {
                    doc.addPage(downloadDiv.offsetWidth >= downloadDiv.offsetHeight ? [downloadDiv.offsetWidth, downloadDiv.offsetWidth] : [downloadDiv.offsetWidth, downloadDiv.offsetHeight], downloadDiv.offsetWidth >= downloadDiv.offsetHeight ? 'landscape' : 'portrait');
                }
                doc.addImage(dataUrl, 'JPEG', 0, 0, downloadDiv.offsetWidth, downloadDiv.offsetHeight);
                if (page_num === num_of_pages) {
                    doc.save("record.pdf");
                } res(true);
            })
            .catch(function (error) {
                console.error("domToImage error", error);
                res(true);
            });
        });
    };

    const [ allFlags, setAllFlags ] = useState([]);
    const [ allFlagValues, setAllFlagValues ] = useState([]);
    let all_flags_render = [];
    for (let k = 0; k < allFlags.length; ++k) {
        if (filters[1].length !== 0) {
            let flag_buttons_subrender = [];
            for (let j = 0; j < allFlagValues[k].length; ++j) {
                flag_buttons_subrender.push(<a href='#' className='panel-button' style={{
                    backgroundColor: filters[1][0][allFlags[k]] !== allFlagValues[k][j] ? '#000' : '#faf3dd',
                    color: filters[1][0][allFlags[k]] !== allFlagValues[k][j] ? '#faf3dd' : '#000',
                    marginBottom: '0vmax',
                    marginLeft: '0.5vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    if (filters[1][0][allFlags[k]] !== allFlagValues[k][j]) {
                        let objToInsert = filters[1][0];
                        objToInsert[allFlags[k]] = allFlagValues[k][j];
                        setFilters([filters[0], [objToInsert], filters[2]]);
                    } else {
                        let objToInsert = filters[1][0];
                        objToInsert[allFlags[k]] = 0;
                        setFilters([filters[0], [objToInsert], filters[2]]);
                    }
                }}>{allFlagValues[k][j]}</a>);
            }
            all_flags_render.push(<div style={{
                width: '60%',
                marginLeft: '20%'
            }}><p style={{
                fontSize: '1.15vmax',
                fontWeight: '300',
                display: 'inline-block',
                marginRight: '0vmax',
                marginTop: '1vmax'
            }}>{allFlags[k]}:</p>{flag_buttons_subrender}<br/></div>);
        }
    }

    const [ allRecords, setAllRecords ] = useState([]);
    if (!isLoaded) {
        axios.get(`${process.env.REACT_APP_DEV_SERVER}` + 'api/records', {
            headers: {
                Authorization: 'Bearing ' + token
            }
        }).then((response) => {
            let all_records = [];
            for (let k = 0; k < response.data.length; ++k) {
                if (response.data[k]['form'] === location.state['_id']) {
                    let pushForm = true;
                    if (filters[0].length !== 0) {
                        let record_datetime = new Date(
                            parseInt(response.data[k]['datetime'][5]),
                            parseInt(response.data[k]['datetime'][4]) - 1,
                            parseInt(response.data[k]['datetime'][3]),
                            parseInt(response.data[k]['datetime'][2]),
                            parseInt(response.data[k]['datetime'][1]),
                            parseInt(response.data[k]['datetime'][0])
                        );
                        if (record_datetime.getTime() >= filters[0][0].start && record_datetime.getTime() <= filters[0][0].end) {
                            // all_records.push(response.data[k]);
                            // continue;
                        } else {
                            pushForm = false;
                        }
                    }
                    
                    if (filters[1].length !== 0 && pushForm) {
                        let flagFilter = filters[1][0];
                        let recordFlags = [[], []];
                        // console.log("flagFilter", flagFilter);
                        for (let j = 0; j < response.data[k].externals.flags.length; ++j) {
                            if (!recordFlags[0].includes(response.data[k].externals.flags[j].name)) {
                                recordFlags[0].push(response.data[k].externals.flags[j].name);
                                recordFlags[1].push(response.data[k].externals.flags[j].values[response.data[k].externals.flags[j].value]);
                            }
                        }
                        // console.log("recordFlags", recordFlags);
                        let passForm = true;
                        for (let j = 0; j < recordFlags[0].length; ++j) {
                            if (flagFilter[recordFlags[0][j]] !== 0 && flagFilter[recordFlags[0][j]] !== recordFlags[1][j]) {
                                passForm = false;
                                break;
                            }
                        } pushForm = passForm;
                    } if (filters[2].length !== 0 && pushForm) {
                        let nameFilter = filters[2][0];
                        if (!response.data[k].name.includes(nameFilter)) {
                            pushForm = false;
                        }
                    } if (pushForm) {
                        all_records.push(response.data[k]);
                    }
                }
            } setAllRecords(all_records);
            setIsLoaded(true);
            setIsRendered(false);
        }).catch((e) => {
            setIsLoaded(0);
            setIsRendered(false);
        });
    }

    const [ filteredRecords, setFilteredRecords ] = useState([]);
    if (!isRendered) {
        let filtered_records = [];
        for (let k = 0; k < allRecords.length; ++k) {
            let timestamp = allRecords[k].datetime;
            let flag_render = [];
            for (let j = 0; j < allRecords[k].externals.flags.length; ++j) {
                if (!allFlags.includes(allRecords[k].externals.flags[j].name)) {
                    let new_all_flags = [...allFlags];
                    new_all_flags.push(allRecords[k].externals.flags[j].name);
                    let new_all_flag_values = [...allFlagValues];
                    new_all_flag_values.push(allRecords[k].externals.flags[j].values);
                    setAllFlags(new_all_flags);
                    setAllFlagValues(new_all_flag_values);
                }
                flag_render.push(<i className='fa-solid fa-flag' style={{
                    fontSize: '1.15vmax',
                    color: allRecords[k].externals.flags[j].colors[allRecords[k].externals.flags[j].value],
                    border: '0pt',
                    marginRight: '0.5vmax'
                }}></i>);
            }
            filtered_records.push(<div style={{
                width: '100%',
                marginTop: '0',
                marginBottom: '0',
                border: '0.5pt solid #faf3dd',
                borderTop: '0pt solid #faf3dd',
                textAlign: 'center'
            }}>
                { flag_render }
                <p style={{
                    margin: '0',
                    padding: '1vmax',
                    display: 'inline-block',
                    fontWeight: '300',
                    fontSize: '1.15vmax'
                }}><b>{allRecords[k].name}</b> created at <b>
                    {timestamp[2]}:{timestamp[1]}:{timestamp[0]}</b> on <b>{timestamp[4]}/{timestamp[3]}/{timestamp[5]}
                </b></p>
                <a href='#' className='panel-button' style={{
                    marginRight: '1vmax',
                    marginLeft: '2vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    navigate('/records/edit', {
                        state: {...allRecords[k]}
                    });
                }}>Edit</a>
                <a href='#' className='panel-button' style={{
                    marginRight: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    setIsShowingLink(k);
                }}>Link</a>
                <a href='#' className='panel-button' style={{
                    marginRight: '1vmax'
                }} onClick={async (e) => {
                    e.preventDefault();
                    let downloadDiv = document.getElementById("downloadDiv");
                    downloadDiv.innerHTML = renderToStaticMarkup(contentFor(allRecords[k], 0));
                    const doc = new jsPDF({
                        format: downloadDiv.offsetWidth >= downloadDiv.offsetHeight ? [downloadDiv.offsetWidth, downloadDiv.offsetWidth] : [downloadDiv.offsetWidth, downloadDiv.offsetHeight],
                        unit: 'px',
                        orientation: downloadDiv.offsetWidth >= downloadDiv.offsetHeight ? 'landscape' : 'portrait'
                    });
                    await downloadRecord(doc, 0, allRecords[k].pages.length - 1);
                    for (let j = 1; j < allRecords[k].pages.length; ++j) {
                        downloadDiv.innerHTML = renderToStaticMarkup(contentFor(allRecords[k], j));
                        await downloadRecord(doc, j, allRecords[k].pages.length - 1);
                    }
                }}>Save as PDF</a>
                <a href='#' className='panel-button' onClick={(e) => {
                    e.preventDefault();
                    setIsDeleting(k);
                }}>Delete</a>
            </div>);
        } setFilteredRecords(filtered_records);
        setIsRendered(true);
    }

    return <div style={{
        overflow: 'hidden',
        height: '100vh'
    }}>
        <div style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100vh',
                backgroundColor: '#0F1108',
                overflow: 'scroll'
            }}>

                {/* <div style={{
                    height: 'calc(4vmax + 12vh)',
                    width: '100%',
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    backgroundColor: '#0F1108',
                    zIndex: '9'
                }}>
                    <div className='logo'>
                        <object data="/logo_black.svg" type="image/svg+xml"></object>
                    </div>
                </div>
                <NavBar /> */}
                <Header />

                <div style={{
                    color: '#FAF3DD',
                    position: 'absolute',
                    top: 'calc(4vmax + 12vh + 1pt)',
                    width: '100%',
                    height: 'calc(100vh - 4vmax - 12vh - 1pt)',
                    overflow: 'scroll',
                    textAlign: 'center'
                }}>
                    <h1 style={{
                        fontWeight: '300',
                        fontSize: '2vmax'
                    }}>Records for <b>{location.state.name}</b></h1>
                    <div style={{
                        width: '60%',
                        marginLeft: '20%',
                        marginTop: '4vmax'
                    }}>
                        <a href='#' className='panel-button' style={{
                            marginRight: '1vmax'
                        }} onClick={(e) => {
                            e.preventDefault();
                            setIsFiltering(true);
                        }}>Filter</a>
                        <a href='#' className='panel-button' onClick={(e) => {
                            e.preventDefault();

                            function renderLabelText(text, vars) {
                                try {
                                    let parsedText = text;
                                
                                    let mathRegex = /\[(.*?)\](\d*)f(\d*)/g;
                                    let matchResults = [...parsedText.matchAll(mathRegex)];
                                    matchResults.forEach(result => {
                                        let mathExp = process_vars(result[1], vars);
                                        let evaluatedExp = 0;
                                        try {
                                            evaluatedExp = math.evaluate(mathExp).toString();
                                            if (result[3] != null) {
                                                evaluatedExp = parseFloat(evaluatedExp).toFixed(parseInt(result[3]));
                                            }
                                        } catch (e) {
                                            // do nothing
                                        }
                                        parsedText = parsedText.replace(result[0], evaluatedExp);
                                    });
                                
                                    parsedText = process_vars(parsedText, vars);
                                    return parsedText;
                                } catch (e) {
                                    return text;
                                }
                            }

                            if (allRecords.length > 0) {
                                let rowsForEachRecord = 1;
                                let csvFile = ["Record Name", "Date Created", "Time Created", "Date Last Modified", "Time Last Modified", "Time Lapsed"];

                                allRecords[0].externals.flags.forEach(flag => {
                                    let flagName = flag.name.replaceAll(',', '');
                                    csvFile.push(`Flag: ${flagName}`);
                                });

                                allRecords[0].content.forEach(element => {
                                    if (element.type === "intermediate/InputTable") {
                                        if (element.data.length > rowsForEachRecord) {
                                            rowsForEachRecord = element.data.length;
                                        }
                                        
                                        csvFile.push(element.name.replaceAll(',', ''));

                                        for (let k = 0; k < element.data[0].length; ++k) {
                                            csvFile.push(`${element.colData[k].name.replaceAll(',', '')}`);
                                        }
                                    }
                                });

                                allRecords[0].content.forEach(element => {
                                    if (element.type === 'primitive/Input' || element.type === 'primitive/Radio' || element.type === 'primitive/Check' || element.type === 'primitive/Drop') {
                                        csvFile.push(element.name.replaceAll(',', ''));
                                    } else if (element.type === 'primitive/Label') {
                                        if (element.props.hasOwnProperty('appearsInCSV')) {
                                            csvFile.push(element.name.replaceAll(',', ''));
                                        }
                                    }
                                });

                                csvFile.push('Record URI');
                                csvFile = [csvFile];
                                allRecords.forEach(inputRecord => {
                                    let reorderedRecordContent = [[], []]; // first index contains tables, next index contains all else
                                    inputRecord.content.forEach(element => {
                                        if (element.type === 'intermediate/InputTable') {
                                            reorderedRecordContent[0].push({...element});
                                        } else {
                                            reorderedRecordContent[1].push({...element});
                                        }
                                    });
                                    
                                    let record = {
                                        ...inputRecord,
                                    };

                                    record.content = reorderedRecordContent[0].concat(reorderedRecordContent[1]);
                                    // console.log(record);

                                    // add record name, date and time stamps
                                    // CHANGE THESE LINES TO REFLECT REAL RECORD created_at AND updated_at
                                    let record_created_at = '2024-01-18 6:34:55+0000';
                                    let record_updated_at = '2024-02-02 23:7:3+0000';

                                    let created_at_date = new Date(record_created_at);
                                    let updated_at_date = new Date(record_updated_at);

                                    // -- utility functions --
                                    const date_from = (date) => {
                                        let monthString = `${date.getMonth() + 1}`;
                                        let dayString = `${date.getDate()}`;
                                        let yearString = `${date.getYear()}`;
                                        
                                        if (monthString.length === 1) {
                                            monthString = '0' + monthString;
                                        } if (dayString.length === 1) {
                                            dayString = '0' + dayString;
                                        }
                                    
                                        return `${monthString}/${dayString}/${yearString}`;
                                    };

                                    const time_from = (date) => {
                                        let hoursString = `${date.getUTCHours()}`;
                                        let minutesString = `${date.getMinutes()}`;
                                        let secondsString = `${date.getSeconds()}`;
                                        
                                        if (hoursString.length === 1) {
                                            hoursString = '0' + hoursString;
                                        } if (minutesString.length === 1) {
                                            minutesString = '0' + minutesString;
                                        } if (secondsString.length === 1) {
                                            secondsString = '0' + secondsString;
                                        }
                                        
                                        return `${hoursString}:${minutesString}:${secondsString}`;
                                    };

                                    const difference_between = (date1, date2) => {
                                        let diff = date2 - date1; // in milliseconds
                                        let hourDiff = Math.floor(diff / (1000 * 60 * 60));
                                        let minuteDiff = Math.floor(diff / (1000 * 60)) - (60 * hourDiff);
                                        let secondDiff = Math.floor(diff / (1000)) - (60 * 60 * hourDiff) - (60 * minuteDiff);
                                        
                                        let hrStr = `${hourDiff}`;
                                        let mnStr = `${minuteDiff}`;
                                        let scStr = `${secondDiff}`;
                                        
                                        if (hrStr.length === 1) {
                                            hrStr = '0' + hrStr;
                                        } if (mnStr.length === 1) {
                                            mnStr = '0' + mnStr;
                                        } if (scStr.length === 1) {
                                            scStr = '0' + scStr;
                                        }
                                        
                                        return `${hrStr}:${mnStr}:${scStr}`;
                                    };
                                    // -- utility functions --

                                    // console.log(difference_between(created_at_date, updated_at_date));

                                    let csvRow = [
                                        [
                                            record.name.toString().replaceAll(',', ''),
                                            // `${record.datetime[4]}/${record.datetime[3]}/${record.datetime[5]}`,
                                            // `${record.datetime[2]}:${record.datetime[1]}:${record.datetime[0]}`,
                                            date_from(created_at_date),
                                            time_from(created_at_date),
                                            date_from(updated_at_date),
                                            time_from(updated_at_date),
                                            difference_between(created_at_date, updated_at_date)
                                        ]
                                    ];

                                    for (let k = 0; k < rowsForEachRecord - 1; ++k) {
                                        let rowToPush = [];
                                        for (let j = 0; j < csvFile.length; ++j) {
                                            rowToPush.push("");
                                        }
                                        csvRow.push(rowToPush);
                                    }

                                    record.externals.flags.forEach(flag => {
                                        csvRow[0].push(`${flag.values[flag.value]}`.replaceAll(',', ''));
                                    });

                                    let pushForward = 0;
                                    for (let k = 0; k < record.content.length; k++) {
                                        let k_ = k + pushForward;
                                        let element = record.content[k];
                                        if (element.type === 'primitive/Input') {
                                            csvRow[0].push(element.props.value.replaceAll(',', ''));
                                        } else if (element.type === 'primitive/Label') {
                                            if (element.props.hasOwnProperty('appearsInCSV')) {
                                                csvRow[0].push(renderLabelText(element.props.text, inputRecord.vars).replaceAll(',', ''));
                                            }
                                        } else if (element.type === 'primitive/Radio') {
                                            try {
                                                csvRow[0].push(element.props.content[parseInt(element.props.selected)].replaceAll(',', ''));
                                            } catch (e) {
                                                csvRow[0].push('');
                                            }
                                        } else if (element.type === 'primitive/Check') {
                                            let checkContent = [];
                                            element.props.selected.forEach(id => {
                                                checkContent.push(element.props.content[parseInt(id)].replaceAll(',', ''));
                                            }); csvRow[0].push(checkContent.join('/'));
                                        } else if (element.type === 'primitive/Drop') {
                                            try {
                                                csvRow[0].push(element.props.content[parseInt(element.props.selected)].replaceAll(',', ''));
                                            } catch (e) {
                                                csvRow[0].push('');
                                            }
                                        } else if (element.type === 'intermediate/InputTable') {
                                            let currentCol = 6 + allRecords[0].externals.flags.length + k_; //! 6 indicate the length of the recordname and datetime columns of the csv. it must change with changes in those columns.
                                            for (let j = 0; j < element.data.length; ++j) {
                                                csvRow[j][currentCol] = `Row ${j + 1}`;
                                            } for (let i = 0; i < element.data.length; ++i) {
                                                for (let j = 1; j < element.data[0].length + 1; ++j) {
                                                    if (element.colData[j - 1].type === "Drop") {
                                                        try {
                                                            csvRow[i][currentCol + j] = element.colData[j - 1].values[element.data[i][j - 1]].replaceAll(',', '');
                                                        } catch (e) {
                                                            csvRow[i][currentCol + j] = '';
                                                        }
                                                    } else {
                                                        if (element.colData[j - 1].type === "Label") {
                                                            csvRow[i][currentCol + j] = renderTableLabelText(record.content, k, element.data[i][j - 1], i, process_table_vars).replaceAll(',', '');
                                                        } else {
                                                            try {
                                                                csvRow[i][currentCol + j] = element.data[i][j - 1].toString().replaceAll(',', '');
                                                            } catch (e) {
                                                                try {
                                                                    csvRow[i][currentCol + j] = element.data[i][j - 1].toString();
                                                                } catch (e) {
                                                                    csvRow[i][currentCol + j] = '';
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            } pushForward = pushForward + element.data[0].length;
                                        }
                                    } csvRow[0].push(
                                        `${window.location.href.split('/')[2]}/records/edit/${id}/${record._id}`
                                    );
                                    for (let k = 0; k < csvRow.length; ++k) {
                                        csvFile.push(csvRow[k]);
                                    }
                                });
                                var csv = csvFile.map(function(d){
                                    return d.join();
                                }).join('\n');
                                const blob = new Blob([csv], { type: "text/csv" });
                                const url = URL.createObjectURL(blob);
                                const link = document.createElement("a");
                                link.download = 'Data for ' + location.state.name;
                                link.href = url;
                                link.click();
                            }
                        }}>Export Data to CSV</a>
                        <i className="fa-solid fa-arrow-rotate-right pointy" style={{
                            fontSize: '1.2vmax',
                            marginLeft: '1.5vmax',
                        }} onClick={() => {
                            setIsLoaded(false);
                        }}></i>
                    </div>
                    { isLoaded && isRendered ? <>{ allRecords.length > 0 ? <><div style={{
                        borderTop: '0.5pt solid #faf3dd',
                        width: '60%',
                        marginTop: '2vmax',
                        marginLeft: '20%'
                    }}>{filteredRecords}</div><div style={{
                        width: '100%',
                        height: '20%'
                    }}></div></> : <h2 style={{
                        fontWeight: '300',
                        fontSize: '1.5vmax',
                        marginTop: '2vmax'
                    }}>No Records</h2> }</> : <>
                        <h2 style={{
                        fontWeight: '300',
                        fontSize: '1.5vmax',
                        marginTop: '2vmax'
                    }}>
                            { isLoaded === 0 ? 'Network Error' : 'Loading...' }
                        </h2>
                    </> }
                </div>
        </div>
        { isDeleting !== false ? <Modal width='15' height='15'>
            <p style={{
                fontWeight: '400',
                fontSize: '1.25vmax',
                marginTop: '3vh'
            }}>Confirm Deletion?</p>
            <a href='#' className='panel-button' style={{
                marginRight: '1vmax'
            }} onClick={(e) => {
                e.preventDefault();
                axios.delete(`${process.env.REACT_APP_DEV_SERVER}` + 'api/records', {
                    headers: {
                        Authorization: 'Bearing ' + token
                    },
                    params: {
                       _id: allRecords[isDeleting]._id 
                    }
                }).then((response) => {
                    setIsLoaded(false);
                    setIsDeleting(false);
                }).catch((e) => {
                    console.error(e);
                })
            }}>Yes</a>
            <a href='#' className='panel-button' onClick={(e) => {
                e.preventDefault();
                setIsDeleting(false);
            }}>No</a>
        </Modal> : <></> }
        { isShowingLink !== false ? <Modal width='50' height='20'>
            <p style={{
                fontWeight: '400',
                fontSize: '1.25vmax',
                marginTop: '4vh'
            }}>Link for {allRecords[isShowingLink].name}</p>
            <p style={{
                fontWeight: '300',
                fontSize: '1vmax',
                marginTop: '2vh',
                marginBottom: '2vh'
            }} onClick={(e) => {
                navigator.clipboard.writeText(`${window.location.href.split('/')[2]}/records/edit/${id}/${allRecords[isShowingLink]._id}`);
            }} className='pointy'>{window.location.href.split('/')[2]}/records/edit/{id}/{allRecords[isShowingLink]._id}</p>
            <a href='#' className='panel-button' onClick={(e) => {
                e.preventDefault();
                setIsShowingLink(false);
            }}>Ok</a>
        </Modal> : <></> }
        {
            isFiltering ? <Modal width='30' height='35'>
            <p style={{
                fontWeight: '400',
                fontSize: '1.25vmax',
                marginTop: '4vh'
            }}>Filters</p>
            <a href='#' className='panel-button' style={{
                backgroundColor: filters[0].length === 0 ? '#000' : '#faf3dd',
                color: filters[0].length === 0 ? '#faf3dd' : '#000',
                marginBottom: '0vmax'
            }} onClick={(e) => {
                e.preventDefault();
                if (filters[0].length === 0) {
                    setFilters([[
                        {
                            start: new Date(),
                            end: new Date()
                        }
                    ], filters[1], filters[2]]);
                } else {
                    setFilters([[], filters[1], filters[2]]);
                }
            }}>By Date & Time</a>
            <br/>
            { filters[0].length !== 0 ? 
                <>
                    <p style={{
                        fontSize: '1.15vmax',
                        fontWeight: '300',
                        display: 'inline-block',
                        marginRight: '1vmax',
                        marginTop: '2vmax'
                    }}>Start:</p>
                    <div style={{
                        // backgroundColor: '#BFB2FA',
                        display: 'inline-block',
                        border: '1pt #faf3dd solid',
                        margin: '0'
                    }}>
                        <Datetime onChange={(newValue) => {
                            setFilters([[
                                {
                                    start: newValue,
                                    end: filters[0][0].end
                                }
                            ], filters[1], filters[2]])
                        }} value={filters[0][0].start} />
                    </div>
                    <br/>
                    <p style={{
                        fontSize: '1.15vmax',
                        fontWeight: '300',
                        display: 'inline-block',
                        marginRight: '1vmax',
                        marginBottom: '2vmax'
                    }}>End:</p>
                    <div style={{
                        // backgroundColor: '#BFB2FA',
                        border: '1pt #faf3dd solid',
                        display: 'inline-block',
                        marginBottom: '2vmax'
                    }}>
                        <Datetime onChange={(newValue) => {
                            setFilters([[
                                {
                                    start: filters[0][0].start,
                                    end: newValue
                                }
                            ], filters[1], filters[2]])
                        }} value={filters[0][0].end} />
                    </div>
                </>
            : <></> }
            <br />
            <a href='#' className='panel-button' style={{
                backgroundColor: filters[1].length === 0 ? '#000' : '#faf3dd',
                color: filters[1].length === 0 ? '#faf3dd' : '#000',
                marginBottom: '0vmax'
            }} onClick={(e) => {
                e.preventDefault();
                if (filters[1].length === 0) {
                    let objToInsert = {};
                    for (let k = 0; k < allFlags.length; ++k) {
                        objToInsert[allFlags[k]] = 0;
                    }
                    setFilters([filters[0], [objToInsert], filters[2]]);
                } else {
                    setFilters([filters[0], [], filters[2]]);
                }
            }}>By Flag</a>
            <br/>
            { filters[1].length !== 0 ? 
                <>
                    {all_flags_render}
                </>
            : <></> }
            <br/>
            <a href='#' className='panel-button' style={{
                backgroundColor: filters[2].length === 0 ? '#000' : '#faf3dd',
                color: filters[2].length === 0 ? '#faf3dd' : '#000',
                marginBottom: '0vmax'
            }} onClick={(e) => {
                e.preventDefault();
                if (filters[2].length === 0) {
                    setFilters([filters[0], filters[1], [""]]);
                } else {
                    setFilters([filters[0], filters[1], []]);
                }
            }}>By Name</a>
            <br/>
            { filters[2].length !== 0 ? 
                <>
                    <p style={{
                        fontSize: '1.15vmax',
                        fontWeight: '300',
                        display: 'inline-block',
                        marginRight: '1vmax',
                        marginTop: '1vmax',
                        marginBottom: '2vmax'
                    }}>Name: </p><input className='panel-input' value={filters[2][0]} onChange={(e) => {
                        setFilters([
                            filters[0], filters[1], [e.target.value]
                        ]);
                    }}></input>
                </>
            : <></> }
            <br />
            <a href='#' className='panel-button' onClick={(e) => {
                e.preventDefault();
                setIsLoaded(false);
                setIsFiltering(false);
            }}>Apply</a>
        </Modal> : <></>
        }
        <div style={{
            marginTop: '150vh',
            background: '#fff'
        }}>
            <div id='downloadDiv' ref={downloadRef} style={{
                // width: `${window.innerWidth}px`,
                // height: `${window.innerWidth * 2}px`
            }}></div>
        </div>
    </div>;

}