
import {
    useState,
    useEffect
} from "react";

import Modal from "../../../views/utils/Modal";

// commands

import IfConditionalCommand from "./commands/conditionals/IfConditionalCommand";

import StartEventCommand from "./commands/events/StartEventCommand";
import ChangeEventCommand from "./commands/events/ChangeEventCommand";
import SaveEventCommand from "./commands/events/SaveEventCommand";

import BooleanExpressionCommand from "./commands/expressions/BooleanExpressionCommand";
import NumberExpressionCommand from "./commands/expressions/NumberExpressionCommand";
import StringExpressionCommand from "./commands/expressions/StringExpressionCommand";
import ColorExpressionCommand from "./commands/expressions/ColorExpressionCommand";
import CurrentTimeExpressionCommand from "./commands/expressions/CurrentTimeExpressionCommand";

import LAndOperatorCommand from "./commands/operators/LAndOperatorCommand";
import LOrOperatorCommand from "./commands/operators/LOrOperatorCommand";
import LNotOperatorCommand from "./commands/operators/LNotOperatorCommand";
import OGrOperatorCommand from "./commands/operators/OGrOperatorCommand";
import OGrEqOperatorCommand from "./commands/operators/OGrEqOperatorCommand";
import OLeOperatorCommand from "./commands/operators/OLeOperatorCommand";
import OLeEqOperatorCommand from "./commands/operators/OLeEqOperatorCommand";
import OEqOperatorCommand from "./commands/operators/OEqOperatorCommand";
import TIsNumOperatorCommand from "./commands/operators/TIsNumOperatorCommand";
import TIsStrOperatorCommand from "./commands/operators/TIsStrOperatorCommand";
import AIsEmptyOperatorCommand from "./commands/operators/AIsEmptyOperatorCommand";
import AIncludesOperatorCommand from "./commands/operators/AIncludesOperatorCommand";
import QAddOperatorCommand from "./commands/operators/QAddOperatorCommand";
import QSubtractOperatorCommand from "./commands/operators/QSubtractOperatorCommand";
import QMultiplyOperatorCommand from "./commands/operators/QMultiplyOperatorCommand";
import QDivideOperatorCommand from "./commands/operators/QDivideOperatorCommand";
import QModulusOperatorCommand from "./commands/operators/QModulusOperatorCommand";
import QFloorOperatorCommand from "./commands/operators/QFloorOperatorCommand";
import QCeilingOperatorCommand from "./commands/operators/QCeilingOperatorCommand";
import QRoundOperatorCommand from "./commands/operators/QRoundOperatorCommand";
import QAbsOperatorCommand from "./commands/operators/QAbsOperatorCommand";
import QLDigitsOperatorCommand from "./commands/operators/QLDigitsOperatorCommand";
import QMaxOperatorCommand from "./commands/operators/QMaxOperatorCommand";
import QMinOperatorCommand from "./commands/operators/QMinOperatorCommand";
import TIsEmailOperatorCommand from "./commands/operators/TIsEmailOperatorCommand";
import TIsDateOperatorCommand from "./commands/operators/TIsDateOperatorCommand";
import TIsBlankOperatorCommand from "./commands/operators/TIsBlankOperatorCommand";
import QLWordsOperatorCommand from "./commands/operators/QLWordsOperatorCommand";
import QLDayOperatorCommand from "./commands/operators/QLDayOperatorCommand";
import QLMonthOperatorCommand from "./commands/operators/QLMonthOperatorCommand";
import QLYearOperatorCommand from "./commands/operators/QLYearOperatorCommand";
import TIsTimeOperatorCommand from "./commands/operators/TIsTimeOperatorCommand";
import TIsUndecoratedOperatorCommand from "./commands/operators/TIsUndecoratedOperatorCommand";

import ErrorSignalCommand from "./commands/signals/ErrorSignalCommand";
import WarningSignalCommand from "./commands/signals/WarningSignalCommand";
import DisableSignalCommand from "./commands/signals/DisableSignalCommand";
import EnableSignalCommand from "./commands/signals/EnableSignalCommand";
import DisableSelfSignalCommand from "./commands/signals/DisableSelfSignalCommand";

import SetVariableCommand from "./commands/variables/SetVariableCommand";
import GetVariableCommand from "./commands/variables/GetVariableCommand";
import SelfValueCommand from "./commands/variables/SelfValueCommand";

import TableDisableSignalCommand from "./commands/signals/TableDisableSignalCommand";
import TableEnableSignalCommand from "./commands/signals/TableEnableSignalCommand";
import TableSetVariableCommand from "./commands/variables/TableSetVariableCommand";
import TableGetVariableCommand from "./commands/variables/TableGetVariableCommand";
import TableSelfValueCommand from "./commands/variables/TableSelfValueCommand";
import TableErrorSignalCommand from "./commands/signals/TableErrorSignalCommand";
import TableDisableRowSignalCommand from "./commands/signals/TableDisableRowSignalCommand";
import TableWarningSignalCommand from "./commands/signals/TableWarningSignalCommand";

export default function ScriptView({ id, comps, setComps, setIsShowingScript, vars, setVars }) {

    const [ isScriptRendered, setIsScriptRendered ] = useState(false);
    const [ scriptRender, setScriptRender ] = useState([
        {
            type: "event/start",
            children: []
        },
        {
            type: "event/change",
            children: []
        },
        {
            type: "event/save",
            children: []
        }
    ]);

    const [ showImportExport, setShowImportExport ] = useState(false);
    const [ uploadedFile, setUploadedFile ] = useState(null);

    const [ previousScript, setPreviousScript ] = useState({});
    const [ startAndChangeScriptsLinked, setStartAndChangeScriptsLinked ] = useState(false);

    useEffect(() => {
        setStartAndChangeScriptsLinked(true);
    }, []);

    let render_child_components = (parentIndices, listOfChildren) => {
        let children_render = [];
        for (let k = 0; k < listOfChildren.length; ++k) {
            // add child element code to children_render
            if (listOfChildren[k].type === 'signal/error') {
                children_render.push(<ErrorSignalCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} />);
            } else if (listOfChildren[k].type === 'signal/warning') {
                children_render.push(<WarningSignalCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} />);
            } else if (listOfChildren[k].type === 'signal/disable') {
                children_render.push(<DisableSignalCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} />);
            } else if (listOfChildren[k].type === 'signal/disableSelf') {
                children_render.push(<DisableSelfSignalCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} />);
            } else if (listOfChildren[k].type === 'signal/table/disable') {
                children_render.push(<TableDisableSignalCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} />);
            } else if (listOfChildren[k].type === 'signal/table/disableRow') {
                children_render.push(<TableDisableRowSignalCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} />);
            } else if (listOfChildren[k].type === 'signal/table/error') {
                children_render.push(<TableErrorSignalCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} />);
            } else if (listOfChildren[k].type === 'signal/table/warning') {
                children_render.push(<TableWarningSignalCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} />);
            } else if (listOfChildren[k].type === 'signal/enable') {
                children_render.push(<EnableSignalCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} />);
            } else if (listOfChildren[k].type === 'signal/table/enable') {
                children_render.push(<TableEnableSignalCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} />);
            } else if (listOfChildren[k].type === 'self/table/value') {
                children_render.push(<TableSelfValueCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} />);
            } else if (listOfChildren[k].type === 'conditional/if') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children),
                    render_child_components([...parentIndices].concat([k, 1]), listOfChildren[k].children[1].children),
                    render_child_components([...parentIndices].concat([k, 2]), listOfChildren[k].children[2].children)
                ];
                children_render.push(<IfConditionalCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'expression/boolean') {
                children_render.push(<BooleanExpressionCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} />);
            } else if (listOfChildren[k].type === 'variable/set') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k]), listOfChildren[k].children)
                ];
                children_render.push(<SetVariableCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} vars={vars} setVars={setVars} />);
            } else if (listOfChildren[k].type === 'variable/table/set') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k]), listOfChildren[k].children)
                ];
                children_render.push(<TableSetVariableCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} vars={vars} setVars={setVars} />);
            } else if (listOfChildren[k].type === 'variable/table/get') {
                children_render.push(<TableGetVariableCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} />);
            } else if (listOfChildren[k].type === 'variable/get') {
                children_render.push(<GetVariableCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered}
                                                            isScriptRendered={isScriptRendered} vars={vars} setVars={setVars} />);
            } else if (listOfChildren[k].type === 'expression/number') {
                children_render.push(<NumberExpressionCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} />);
            } else if (listOfChildren[k].type === 'expression/string') {
                children_render.push(<StringExpressionCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} />);
            } else if (listOfChildren[k].type === 'expression/color') {
                children_render.push(<ColorExpressionCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/logical/and') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children),
                    render_child_components([...parentIndices].concat([k, 1]), listOfChildren[k].children[1].children)
                ];
                children_render.push(<LAndOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/logical/or') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children),
                    render_child_components([...parentIndices].concat([k, 1]), listOfChildren[k].children[1].children)
                ];
                children_render.push(<LOrOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/logical/not') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children)
                ];
                children_render.push(<LNotOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/ordinal/g') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children),
                    render_child_components([...parentIndices].concat([k, 1]), listOfChildren[k].children[1].children)
                ];
                children_render.push(<OGrOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/ordinal/geq') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children),
                    render_child_components([...parentIndices].concat([k, 1]), listOfChildren[k].children[1].children)
                ];
                children_render.push(<OGrEqOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/ordinal/l') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children),
                    render_child_components([...parentIndices].concat([k, 1]), listOfChildren[k].children[1].children)
                ];
                children_render.push(<OLeOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/ordinal/leq') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children),
                    render_child_components([...parentIndices].concat([k, 1]), listOfChildren[k].children[1].children)
                ];
                children_render.push(<OLeEqOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/ordinal/eq') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children),
                    render_child_components([...parentIndices].concat([k, 1]), listOfChildren[k].children[1].children)
                ];
                children_render.push(<OEqOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'self/value') {
                children_render.push(<SelfValueCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/type/isNum') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children)
                ];
                children_render.push(<TIsNumOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/type/isStr') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children)
                ];
                children_render.push(<TIsStrOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/array/isEmpty') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children)
                ];
                children_render.push(<AIsEmptyOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/array/includes') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children),
                    render_child_components([...parentIndices].concat([k, 1]), listOfChildren[k].children[1].children)
                ];
                children_render.push(<AIncludesOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/quant/add') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children),
                    render_child_components([...parentIndices].concat([k, 1]), listOfChildren[k].children[1].children)
                ];
                children_render.push(<QAddOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/quant/subtract') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children),
                    render_child_components([...parentIndices].concat([k, 1]), listOfChildren[k].children[1].children)
                ];
                children_render.push(<QSubtractOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/quant/multiply') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children),
                    render_child_components([...parentIndices].concat([k, 1]), listOfChildren[k].children[1].children)
                ];
                children_render.push(<QMultiplyOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/quant/divide') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children),
                    render_child_components([...parentIndices].concat([k, 1]), listOfChildren[k].children[1].children)
                ];
                children_render.push(<QDivideOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/quant/modulus') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children),
                    render_child_components([...parentIndices].concat([k, 1]), listOfChildren[k].children[1].children)
                ];
                children_render.push(<QModulusOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/quant/floor') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children)
                ];
                children_render.push(<QFloorOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/quant/ceiling') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children)
                ];
                children_render.push(<QCeilingOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/quant/round') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children)
                ];
                children_render.push(<QRoundOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/quant/abs') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children)
                ];
                children_render.push(<QAbsOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/qual/digits') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children)
                ];
                children_render.push(<QLDigitsOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/quant/max') {
                let children_array = [
                    // render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children),
                    // render_child_components([...parentIndices].concat([k, 1]), listOfChildren[k].children[1].children)
                ];
                for (let j = 0; j < listOfChildren[k].numOfChildren; ++j) {
                    children_array.push(render_child_components([...parentIndices].concat([k, j]), listOfChildren[k].children[j].children))
                }
                children_render.push(<QMaxOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/quant/min') {
                let children_array = [
                    // render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children),
                    // render_child_components([...parentIndices].concat([k, 1]), listOfChildren[k].children[1].children)
                ];
                for (let j = 0; j < listOfChildren[k].numOfChildren; ++j) {
                    children_array.push(render_child_components([...parentIndices].concat([k, j]), listOfChildren[k].children[j].children))
                }
                children_render.push(<QMinOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/type/isEmail') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children)
                ];
                children_render.push(<TIsEmailOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/type/isDate') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children)
                ];
                children_render.push(<TIsDateOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/type/isBlank') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children)
                ];
                children_render.push(<TIsBlankOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/qual/words') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children)
                ];
                children_render.push(<QLWordsOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/qual/day') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children)
                ];
                children_render.push(<QLDayOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/qual/month') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children)
                ];
                children_render.push(<QLMonthOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/qual/year') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children)
                ];
                children_render.push(<QLYearOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/type/isTime') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children)
                ];
                children_render.push(<TIsTimeOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            } else if (listOfChildren[k].type === 'expression/currentTime') {
                children_render.push(<CurrentTimeExpressionCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} />);
            } else if (listOfChildren[k].type === 'operator/type/isUndecorated') {
                let children_array = [
                    render_child_components([...parentIndices].concat([k, 0]), listOfChildren[k].children[0].children)
                ];
                children_render.push(<TIsUndecoratedOperatorCommand id={id} comps={comps} setComps={setComps} indices={parentIndices.concat([k])}
                                                            setIsScriptRendered={setIsScriptRendered} childrenArray={children_array}
                                                            isScriptRendered={isScriptRendered} />);
            }
        } return children_render;
    }

    if (!isScriptRendered) {
        if (startAndChangeScriptsLinked) {
            let start_script_as_string = JSON.stringify(comps[id].script[0].children);
            let change_script_as_string = JSON.stringify(comps[id].script[1].children);
            if (start_script_as_string !== JSON.stringify(previousScript[0].children)) {
                let new_comps = [...comps];
                new_comps[id].script[1].children = JSON.parse(JSON.stringify(new_comps[id].script[0].children));
                setComps(new_comps);
            } else if (change_script_as_string !== JSON.stringify(previousScript[1].children)) {
                let new_comps = [...comps];
                new_comps[id].script[0].children = JSON.parse(JSON.stringify(new_comps[id].script[1].children));
                setComps(new_comps);
            }
        }
        let start_render = <StartEventCommand id={id} comps={comps} setComps={setComps} indices={[0]}
                                                setIsScriptRendered={setIsScriptRendered}>
            { render_child_components([0], comps[id].script[0].children) }
        </StartEventCommand>;
        let change_render = '';
        let save_render = '';
        if (comps[id].script.length > 1) {
            change_render = <ChangeEventCommand id={id} comps={comps} setComps={setComps} indices={[1]}
            setIsScriptRendered={setIsScriptRendered}>
                { render_child_components([1], comps[id].script[1].children) }
            </ChangeEventCommand>;
            save_render = <SaveEventCommand id={id} comps={comps} setComps={setComps} indices={[2]}
                setIsScriptRendered={setIsScriptRendered}>
                { render_child_components([2], comps[id].script[2].children) }
            </SaveEventCommand>
        }
        if (comps[id].script.length === 3)
        setScriptRender([
            start_render,
            <br/>,
            change_render,
            <br />,
            save_render
        ]);
        setPreviousScript(JSON.parse(JSON.stringify(comps[id].script)));
        setIsScriptRendered(true);
    }

    return <div>
        <Modal width='75' height='77.5' marginTop='20vh' fullScreen={true}>
            <h1 style={{
                color: '#FAF3DD',
                fontWeight: '300',
                margin: '1vmax 0',
                padding: '1vmax 0',
                fontSize: '1.75vmax',
                display: 'block'
        }}>Edit Script</h1>
        <div style={{
            height: '69%',
            overflow: 'scroll',
            borderTop: '1pt solid #faf3dd',
            borderBottom: '1pt solid #faf3dd',
            padding: '2.5%',
            textAlign: 'left'
        }}>
            <i className="fa-solid fa-link panel-button" style={{
                position: 'absolute',
                right: showImportExport ? '10vmax' : '7vmax',
                top: '7.5vmax',
                backgroundColor: startAndChangeScriptsLinked ? "#faf3dd" : "#000000",
                color: startAndChangeScriptsLinked ? "#000000" : "#faf3dd",
                fontSize: '1vmax',
                padding: '0.5vmax'
            }} onClick={(e) => {
                e.stopPropagation();
                setStartAndChangeScriptsLinked(!startAndChangeScriptsLinked);
                setIsScriptRendered(false);
            }}></i>
            <i className="fa-solid fa-broom panel-button" style={{
                position: 'absolute',
                right: showImportExport ? '7vmax' : '4vmax',
                top: '7.5vmax',
                fontSize: '1vmax',
                padding: '0.5vmax'
            }} onClick={(e) => {
                e.stopPropagation();
                let new_comps = [...comps];
                for (let k = 0; k < new_comps[id].script.length; ++k) {
                    new_comps[id].script[k].children = [];
                } setComps(new_comps);
                setIsScriptRendered(false);
            }}></i>
            {showImportExport ? <div style={{
                position: 'absolute',
                right: '1.5vmax',
                top: '7.5vmax',
                textAlign: 'right'
            }}>
                <a href='#' className='panel-button' style={{
                    marginBottom: '-1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    let scriptText = JSON.stringify(comps[id].script);
                    const blob = new Blob([scriptText], { type: "text/plain;charset=utf-8" });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.download = 'AtlasScript Component';
                    link.href = url;
                    link.click();
                }}>Export</a>
                <br /> <br />
                <input type="file" name="file" className="panel-button" style={{
                    border: '0',
                    width: '50%'
                }} onChange={(e) => {
                    setUploadedFile(e.target.files[0]);
                }} />
                <a href='#' className='panel-button' style={{
                    marginLeft: '1vmax',
                    marginRight: '0vmax',
                    marginTop: '0'
                }} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const reader = new FileReader();
                    reader.onload = event => {
                        // event.target.result
                        try {
                            let parsedObj = JSON.parse(event.target.result);
                            if (
                                parsedObj.length === 3 &&
                                parsedObj[0].type === "event/start" &&
                                parsedObj[1].type === "event/change" &&
                                parsedObj[2].type === "event/save"
                            ) {
                                let new_comps = [...comps];
                                new_comps[id].script[0].children = new_comps[id].script[0].children.concat(parsedObj[0].children);
                                new_comps[id].script[1].children = new_comps[id].script[1].children.concat(parsedObj[1].children);
                                new_comps[id].script[2].children = new_comps[id].script[2].children.concat(parsedObj[2].children);
                                setComps(new_comps);
                                setIsScriptRendered(false);
                            }
                        } catch (err) {

                        }
                    };
                    reader.onerror = error => console.error("Could not process file");
                    reader.readAsText(uploadedFile);
                }}>Import</a>
                <i className="fa-solid fa-caret-up panel-button" style={{
                    position: 'absolute',
                    right: '1vmax',
                    top: '6.5vmax',
                    fontSize: '1.2vmax'
                }} onClick={(e) => {
                    e.stopPropagation();
                    setShowImportExport(false);
                }}></i>
            </div> : <i className="fa-solid fa-caret-down panel-button" style={{
                position: 'absolute',
                right: '1vmax',
                top: '7.5vmax',
                fontSize: '1.2vmax'
            }} onClick={(e) => {
                e.stopPropagation();
                setShowImportExport(true);
            }}></i>}
            { scriptRender }
        </div>
        <div style={{
            position: 'absolute',
            bottom: '0',
            width: '100%',
            backgroundColor: '#000000'
        }}>
            <a href='' className='panel-button' style={{
                marginBottom: '1vmax'
            }} onClick={(e) => {
                e.preventDefault();
                setIsShowingScript(false);
            }}>Okay</a>
        </div>
        </Modal>
    </div>;

}