
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function NavBarLink (props) {

    const navigate = useNavigate();

    const [ colors, setColors ] = useState(['#FAF3DD', '#0F1108']);

    return (
        <a href='' style={{
            textDecoration: 'none',
            color: colors[0],
            backgroundColor: colors[1],
            fontSize: '1.25vmax',
            fontWeight: '400',
            display: 'block',
            paddingTop: '1vmax',
            paddingBottom: '1vmax',
            borderBottom: 'solid 1.5pt #FAF3DD',
            transition: 'color 0.1s, background-color 0.1s'
        }} onMouseEnter={(e) => {
            setColors(["#0F1108", "#FAF3DD"]);
        }} onMouseLeave={(e) => {
            setColors(['#FAF3DD', '#0F1108']);
        }} onClick={(e) => {
            e.preventDefault();
            navigate(props.to, {
                state: props.data
            });
        }}>
            { props.children }
        </a>
    );

}