
import { createSlice } from '@reduxjs/toolkit';

export const idSlice = createSlice({

    name: 'id',

    initialState: {
        value: ''
    },

    reducers: {

        setId: (state, action) => {
            state.value = action.payload;
        }

    }

});

export const { setId } = idSlice.actions;

export const selectId = state => state.id.value;

export default idSlice.reducer;