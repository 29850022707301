
import { useState, useEffect, useRef } from 'react';
import React from 'react';

import { Rnd } from 'react-rnd';

import process_vars from '../../forms/process_vars';
import execute_script from './execute_script';

const boxShadowRadius = 15;

export default function Radio ({ id, comps, setter, vw, vars, setVars, editable }) {

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    let boxShadow = "0px 0px 0px #ff4f42";

    if (comps[id].error) {
        boxShadow =  `0px 0px ${boxShadowRadius}px #ff4f42`;
    } else if (comps[id].hasOwnProperty('warning')) {
        if (comps[id].warning) {
            boxShadow = `0px 0px ${boxShadowRadius}px #d35400`;
        }
    } else if (comps[id].disable) {
        boxShadow = `0px 0px ${boxShadowRadius}px #4e6df5`;
    }

    let innerContent = [];

    for (let k = 0; k < comps[id].props.content.length; ++k) {
        let left_margin = '0.5vmax';
        if (process_vars(comps[id].props.alignment === 'center')) {
            left_margin = '40%';
        } else if (process_vars(comps[id].props.alignment === 'right')) {
            left_margin = '80%';
        }
        innerContent.push(
            <div style={{
                display: 'flex',
                width: '100%',
                height: (100 / comps[id].props.content.length).toString() + '%',
                alignItems: "center"
            }} onClick={(e) => {
                if (editable) {
                    if (!comps[id].disable) {
                        let new_comps = [...comps];
                        new_comps[id].props.selected = k;
                        setter(new_comps);
                        execute_script(1, id, comps, setter, vars, setVars);
                    }
                }
            }}>
                <input type='radio' style={{
                    marginLeft: left_margin
                }}
                    id={process_vars(comps[id].props.content[k], vars)}
                    value={process_vars(comps[id].props.content[k], vars)}
                    checked={k === parseInt(comps[id].props.selected)} />
                <label style={{
                    marginLeft: '0.5vmax'
                }}
                    for={process_vars(comps[id].props.content[k], vars)}>
                        {process_vars(comps[id].props.content[k], vars)}
                </label>
            </div>
        );
    }

    return (
        <div>
            <Rnd className='comp' style={{
                color: process_vars(comps[id].props.color, vars),
                backgroundColor: process_vars(comps[id].props.backgroundColor, vars),
                overflow: 'hidden',
                fontSize: process_vars(comps[id].props.fontSize, vars) + 'vmax',
                fontWeight: process_vars(comps[id].props.bold, vars) ? '700' : '400',
                fontStyle: process_vars(comps[id].props.italic, vars) ? 'italic' : '',
                textDecoration: process_vars(comps[id].props.underlined, vars) ? 'underline' : '',
                zIndex: '0',
                border: process_vars(comps[id].props.borderEnabled, vars) ?
                            `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px',
                position: 'absolute',
                boxShadow: boxShadow,
                transition: 'box-shadow 0.5s'
            }} position={{
                x: process_vars(comps[id].position[0], vars) * vw,
                y: process_vars(comps[id].position[1], vars) * vw
            }} size={{
                width: process_vars(comps[id].size[0], vars) * vw,
                height: process_vars(comps[id].size[1], vars) * vw
            }} enableResizing={false} disableDragging={true}>
                { innerContent }
                <div style={{
                    position: 'absolute',
                    top: '0.5vmax',
                    right: '1.5vmax',
                }}>
                    <i className="fa-solid fa-broom pointy" style={{
                        position: 'absolute',
                        fontSize: '0.75vmax',
                        color: process_vars(comps[id].props.color, vars),
                        opacity: 0.25,
                    }} onClick={() => {
                        if (!comps[id].disable && editable) {
                            let new_comps = [...comps];
                            new_comps[id].props.selected = '';
                            setter(new_comps);
                            execute_script(1, id, comps, setter, vars, setVars);
                            forceUpdate();
                        }
                    }}></i>
                </div>
                </Rnd>
        </div>
    );

}