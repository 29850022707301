
import { useState, useEffect } from "react";
import Modal from "../../../views/utils/Modal";

import React from 'react';
import { useSelector } from 'react-redux';
import { selectId } from '../../../redux/features/idSlice';
import { selectUsername } from "../../../redux/features/usernameSlice";
import { selectToken } from '../../../redux/features/tokenSlice';
import { useNavigate } from "react-router-dom";

const axios = require('axios');

export default function Settings ({
    comps,
    setComps,
    setView,
    pages,
    vars,
    externals,
    parent,
    name,
    setName,
    dbId,
    setDbId
}) {

    const [ status, setStatus ] = useState(0); // -1 => error, 0 => nothing, 1 => success, 2 => success with leave

    const username = useSelector(selectUsername);
    const token = useSelector(selectToken);

    const navigate = useNavigate();

    let form_obj = {
        name: name,
        content: comps,
        pages: pages,
        vars: vars,
        externals: externals,
        parent: parent
    };

    const [ parent_name, setParent_name ] = useState('Loading...');
    useEffect(() => {
        if (parent !== '') {
            axios.get(`${process.env.REACT_APP_DEV_SERVER}` + 'api/forms', {
                params: {
                    _id: parent
                },
                headers: {
                    Authorization: 'Bearing ' + token
                }
            }).then((res1) => {
                setParent_name(res1.data[0].name);
            }).catch((err) => {});
        }
    }, []);

    return (
        <>
        <Modal width={30} height={40}>
            <h1 style={{
                color: '#FAF3DD',
                fontWeight: '300',
                margin: '0',
                padding: '1vmax 0',
                fontSize: '1.75vmax',
                borderBottom: '1px #FAF3DD solid',
                display: 'block'
            }}>Form Settings</h1>
            <div style={{
                height: '27.5vh',
                overflow: 'scroll'
            }}>
                <p className="panel-label" style={{
                    fontSize: '1.25vmax',
                    fontWeight: '300',
                    marginTop: '2vmax',
                    display: 'inline-block'
                }}>Form Name:</p>
                <input className='panel-input' value={name} onChange={(e) => {
                    setName(e.target.value);
                }} />
                <p className="panel-label" style={{
                    fontSize: '1.25vmax',
                    fontWeight: '300',
                    marginTop: '0.5vmax',
                    display: 'block'
                }}>Current User: <span style={{
                    fontWeight: '400'
                }}>{username}</span></p>
                <p className="panel-label" style={{
                    fontSize: '1.25vmax',
                    fontWeight: '300',
                    marginTop: '0.5vmax',
                    display: 'block'
                }}>Parent: <span style={{
                    fontWeight: '400'
                }}>{parent === '' ? 'Root' : parent_name}</span></p>
                <br /> <br /> <br />
            </div>
            <div style={{
                position: 'absolute',
                bottom: '0',
                width: '100%',
                backgroundColor: '#000000'
            }}>
                <a href='' className='panel-button' style={{
                    margin: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    if (dbId === '') {
                        axios
                            .post(`${process.env.REACT_APP_DEV_SERVER}` + 'api/forms', form_obj, {
                                headers: {
                                    Authorization: 'Bearing ' + token
                                }
                            })
                            .then((response) => {
                                setStatus(1);
                            })
                            .catch((error) => {
                                setStatus(-1);
                            });
                    } else {
                        axios.
                            patch(`${process.env.REACT_APP_DEV_SERVER}` + 'api/forms', {
                                query: {
                                    _id: dbId
                                }, params: form_obj
                            }, {
                                headers: {
                                    Authorization: 'Bearing ' + token
                                }
                            })
                            .then((response) => {
                                setStatus(1);
                            })
                            .catch((error) => {
                                setStatus(-1);
                            })
                    }
                }}>Save</a>
                <a href='' className='panel-button' style={{
                    margin: '1vmax 1vmax 1vmax 0vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    if (dbId === '') {
                        axios
                            .post(`${process.env.REACT_APP_DEV_SERVER}` + 'api/forms', form_obj, {
                                headers: {
                                    Authorization: 'Bearing ' + token
                                }
                            })
                            .then((response) => {
                                setStatus(2);
                            })
                            .catch((error) => {
                                setStatus(-1);
                            });
                    } else {
                        axios.
                            patch(`${process.env.REACT_APP_DEV_SERVER}` + 'api/forms', {
                                query: {
                                    _id: dbId
                                }, params: form_obj
                            }, {
                                headers: {
                                    Authorization: 'Bearing ' + token
                                }
                            })
                            .then((response) => {
                                setStatus(2);
                            })
                            .catch((error) => {
                                setStatus(-1);
                            })
                    }
                }}>Save & Exit</a>
                <a href='' className='panel-button' style={{
                    marginBottom: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    setView('');
                }}>Close</a>
            </div>
        </Modal>
        { status === 1 ? <Modal width='15' height='21.5'>
            <p style={{
                fontWeight: '400',
                fontSize: '1.25vmax',
                marginTop: '3vh',
                padding: '1vmax'
            }}>Your form was successfully saved.</p>
            <a href='#' className='panel-button' onClick={(e) => {
                e.preventDefault();
                setStatus(0);
            }}>Ok</a>
        </Modal> : <></> }
        { status === 2 ? <Modal width='15' height='21.5'>
            <p style={{
                fontWeight: '400',
                fontSize: '1.25vmax',
                marginTop: '3vh',
                padding: '1vmax'
            }}>Your form was successfully saved.</p>
            <a href='#' className='panel-button' onClick={(e) => {
                e.preventDefault();
                setStatus(0);
                navigate('/forms');
            }}>Ok</a>
        </Modal> : <></> }
        { status === -1 ? <Modal width='15' height='21.5'>
            <p style={{
                fontWeight: '400',
                fontSize: '1.25vmax',
                marginTop: '3vh',
                padding: '1vmax'
            }}>Your form could not be saved.</p>
            <a href='#' className='panel-button' onClick={(e) => {
                e.preventDefault();
                setStatus(0);
            }}>Ok</a>
        </Modal> : <></> }
        </>
    );

}