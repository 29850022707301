
import { useState } from 'react';

export default function ActionButton ({ icon, id, click }) {

    const [ hovered, setHovered ] = useState(false);

    return (
        <i style={{
            color: hovered ? '#0F1108' : '#FAF3DD',
            backgroundColor: hovered ? '#FAF3DD' : '#0F1108',
            width: '3.95vw',
            paddingTop: '1.15vw',
            paddingBottom: '1.15vw',
            fontSize: '1.5vmax',
            borderTop: 'solid 1pt #FAF3DD',
            borderRight: id !== 4 ? 'solid 1pt #FAF3DD': '',
            cursor: hovered ? 'pointer' : 'auto',
            transition: 'color 0.1s, background-color 0.1s'
        }} className={'fa-solid fa-' + icon}
            onMouseEnter={(e) => {
                setHovered(true);
            }} onMouseLeave={(e) => {
                setHovered(false);
            }} onClick={click}></i>
    );

}