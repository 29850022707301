
import { useEffect, useState } from "react";

export default function FormIcon ({
    id,
    current,
    setCurrent,
    data,
    idOn,
    search,
    place,
    setPlace,
    placeId,
    setPlaceId,
    setStatus
}) {

    const [ hovered, setHovered ] = useState(false);

    let color = '#FAF3DD';
    let border = 'solid 1pt #FAF3DD';
    if (hovered) {
        color = '#ffbaec';
        border = 'solid 1pt #ffbaec';
    } if (current === id) {
        color = '#0F1108';
        border = 'solid 1pt #FAF3DD';
    }

    return (
        <div onClick={(e) => {
            e.stopPropagation();
            setCurrent(id);
        }} onDoubleClick={(e) => {
            e.stopPropagation();
            if (search === '') {
                let new_place = [...place];
                new_place.push(data.name);
                setPlace(new_place);
                let new_placeId = [...placeId];
                new_placeId.push(data._id);
                setPlaceId(new_placeId);
                setCurrent(-1);
                setStatus(1);
            }
        }} style={{
            width: '9.7%',
            border: border,
            textAlign: 'center',
            margin: '1vmax 0vmax 0vmax 1vmax',
            color: color,
            backgroundColor: id === current ? '#FAF3DD' : '#0F1108',
            transition: 'color 0.1s, border 0.1s, background-color 0.1s',
            display: 'inline-block'
        }} onMouseEnter={() => {
            setHovered(true);
        }} onMouseLeave={() => {
            setHovered(false);
        }} className='pointy'>
            <i className="fa-solid fa-file" style={{
                marginTop: '1.5vmax',
                fontSize: '1.5vmax'
            }}></i>
            <p style={{
                fontSize: '1.25vmax',
                width: '75%',
                display: 'block',
                overflowY: 'auto',
                margin: '0 auto',
                marginTop: '1.5vmax',
                marginBottom: idOn ? '0.25vmax' : '1.5vmax',
                whiteSpace: 'nowrap',
                // textOverflow: 'ellipsis',
                overflowX: 'scroll'
            }} className='noscroll'>{data.name}</p>
            {idOn ? <p style={{
                fontSize: '0.5vmax',
                width: '75%',
                display: 'block',
                overflowY: 'hidden',
                margin: '0 auto',
                marginBottom: '1.5vmax'
            }} className='noscroll'>{data._id}</p> : <></>}
        </div>
    );

}