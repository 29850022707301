
import Header from "./Header";

export default function Help () {

    return <>
        <div style={{
            background: '#0F1108',
            width: '100%',
            height: '100vh',
            position: 'absolute',
            top: '0',
            left: '0'
        }}>
            <Header />
            <div style={{
                color: "#FAF3DD",
                position: 'absolute',
                top: 'calc(4vmax + 16vh + 1pt)',
                width: '100%',
                height: 'calc(100vh - 4vmax - 12vh - 1pt)',
                overflow: 'scroll'
            }}>
                <h1 style={{
                    textAlign: 'center',
                    fontSize: '3vmax',
                    fontWeight: '500',
                    marginTop: '1vmax',
                    display: 'block',
                    marginBottom: '3vmax',
                    // fontFamily: 'Playfair Display, sans-serif'
                }}>Atlas Tutorials</h1>
                <p style={{
                    fontSize: '1.5vmax',
                    fontWeight: '300',
                    textAlign: 'center',
                    marginTop: '5vh'
                }}>Coming soon...</p>
            </div>
        </div>
    </>;

}