
// index.js
// Contains root routing and app structural information
// Acts as the entry-point of the front-end

// standard
import React from 'react';
import ReactDOM from 'react-dom/client';
import store from './redux/store';
import { Provider } from 'react-redux';

// routing
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';

// components
import Home from './views/root/Home';
import CreateForm from './forms/views/CreateForm/CreateForm';
import CreateRecord from './records/views/CreateRecord';
import SearchRecords from './records/views/SearchRecords';
import EditRecord from './records/views/EditRecord';
import Forms from './forms/views/Forms';
import Records from './records/Records';
import Settings from './settings/Settings';
import EditRecordURI from './records/views/EditRecordURI';
import Help from './views/utils/Help';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Routes>
          <Route path='/'>
            <Route index element={<Home />} />
            <Route path='forms/'>
              <Route index element={<Forms />} />
              <Route path='create' element={<CreateForm />} />
            </Route>
            <Route path='records/'>
              <Route index element={<Records />} />
              <Route path='create' element={<CreateRecord />} />
              <Route path='search' element={<SearchRecords />} />
              <Route path='edit' element={<EditRecord />} />
              <Route path='edit/:userId/:recordId' element={<EditRecordURI />} />
            </Route>
            <Route path='settings/'>
              <Route index element={<Settings />} />
            </Route>
            <Route path='help/'>
              <Route index element={<Help />} />
            </Route>
          </Route>
        </Routes>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);