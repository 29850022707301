
import Modal from "../../views/utils/Modal";
import { useState, useEffect } from "react";

import { useSelector } from 'react-redux';

import { selectId } from '../../redux/features/idSlice';
import { selectToken } from '../../redux/features/tokenSlice';

const axios = require('axios');

export default function FormView ({ 
    reference,
    comps,
    setComps,
    content,
    position,
    size,
    pages,
    setPages,
    currentPage,
    setCurrentPage,
    setSelectedEl,
    setContextMenu,
    css 
}) {

    const id = useSelector(selectId);
    const token = useSelector(selectToken);

    const [ view, setView ] = useState('');
    // '' == no action, 'left' == add page to left, 'right' == add page to right

    const [ visibilityLoaded, setVisibilityLoaded ] = useState(false); // refreshes visibility
    const [ refreshVisibility, setRefreshVisibility ] = useState(false); // refreshes visibility
    const [ isManagingVisibility, setIsManagingVisibility ] = useState(false);
    const [ visibilityStatus, setVisibilityStatus ] = useState(0);
    const [ visibilityList, setVisibilityList ] = useState([]);

    const [ editabilityLoaded, setEditabilityLoaded ] = useState(false); // refreshes editability
    const [ refreshEditability, setRefreshEditability ] = useState(false); // refreshes editability
    const [ isManagingEditability, setIsManagingEditability ] = useState(false);
    const [ editabilityStatus, setEditabilityStatus ] = useState(0);
    const [ editabilityList, setEditabilityList ] = useState([]);

    const [ isAddingGroup, setIsAddingGroup ] = useState(false);
    const [ isAddingEditGroup, setIsAddingEditGroup ] = useState(false);

    const [ members, setMembers ] = useState([]);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_DEV_SERVER}` + 'api/users', {
            headers: {
                Authorization: 'Bearing ' + token
            }
        }).then((response) => {
            setMembers(response.data[0].members);
        }).catch((error) => {
            alert("Network Error");
        });
    }, []);

    if (!visibilityLoaded) {
        if (pages[currentPage].visibleTo === 'all') {
            setVisibilityStatus(0);
        } else {
            setVisibilityStatus(1);
            setVisibilityList(pages[currentPage].visibleTo);
        } setVisibilityLoaded(true);
    }

    if (refreshVisibility) {
        if (pages[currentPage].visibleTo === 'all') {
            setVisibilityStatus(0);
        } else {
            setVisibilityStatus(1);
            setVisibilityList(pages[currentPage].visibleTo);
        } setRefreshVisibility(false);
    }

    if (!editabilityLoaded) {
        if (pages[currentPage].editableTo === 'all') {
            setEditabilityStatus(0);
        } else {
            setEditabilityStatus(1);
            setEditabilityList(pages[currentPage].editableTo);
        } setEditabilityLoaded(true);
    }

    if (refreshEditability) {
        if (pages[currentPage].editableTo === 'all') {
            setEditabilityStatus(0);
        } else {
            setEditabilityStatus(1);
            setEditabilityList(pages[currentPage].editableTo);
        } setRefreshEditability(false);
    }

    let selected_members_render = [];
    let members_render = [];

    let selected_edit_members_render = [];
    let edit_members_render = [];

    try {
        for (let k = 0; k < visibilityList.length; ++k) {
            selected_members_render.push(
                <div style={{
                    fontWeight: '300',
                    fontSize: '1vmax',
                    width: '90%',
                    marginLeft: '5%',
                    padding: '1vmax 0',
                    textAlign: 'center',
                    border: '1pt solid #faf3dd',
                    marginTop: k === 0 ? '2vmax' : '0'
                }}>
                    { visibilityList[k] }
                    <a href='#' className='panel-button' style={{
                        marginLeft: '1vmax',
                        border: '0pt',
                        borderLeft: '1pt solid #faf3dd',
                        padding: '0.5vmax 1vmax'
                    }} onClick={(e) => {
                        e.preventDefault();
                        let new_visibility_list = [...visibilityList];
                        new_visibility_list.splice(k, 1);
                        setVisibilityList(new_visibility_list);
                    }}>Delete</a>
                </div>
            );
        }

        for (let k = 0; k < members.length; ++k) {
            members_render.push(
                <a href='#' className='panel-button' style={{
                    fontWeight: '300',
                    fontSize: '1vmax',
                    padding: '1vmax',
                    width: '90%'
                }} onClick={(e) => {
                    e.preventDefault();
                    if (!visibilityList.includes(members[k].name)) {
                        let new_visibility_list = [...visibilityList];
                        new_visibility_list.push(members[k].name);
                        setVisibilityList(new_visibility_list);
                        setIsAddingGroup(false);
                    }
                }}>{members[k].name}</a>
            );
        }

        for (let k = 0; k < editabilityList.length; ++k) {
            selected_edit_members_render.push(
                <div style={{
                    fontWeight: '300',
                    fontSize: '1vmax',
                    width: '90%',
                    marginLeft: '5%',
                    padding: '1vmax 0',
                    textAlign: 'center',
                    border: '1pt solid #faf3dd',
                    marginTop: k === 0 ? '2vmax' : '0'
                }}>
                    { editabilityList[k] }
                    <a href='#' className='panel-button' style={{
                        marginLeft: '1vmax',
                        border: '0pt',
                        borderLeft: '1pt solid #faf3dd',
                        padding: '0.5vmax 1vmax'
                    }} onClick={(e) => {
                        e.preventDefault();
                        let new_editability_list = [...editabilityList];
                        new_editability_list.splice(k, 1);
                        setEditabilityList(new_editability_list);
                    }}>Delete</a>
                </div>
            );
        }

        for (let k = 0; k < members.length; ++k) {
            edit_members_render.push(
                <a href='#' className='panel-button' style={{
                    fontWeight: '300',
                    fontSize: '1vmax',
                    padding: '1vmax',
                    width: '90%'
                }} onClick={(e) => {
                    e.preventDefault();
                    if (!editabilityList.includes(members[k].name)) {
                        let new_editability_list = [...editabilityList];
                        new_editability_list.push(members[k].name);
                        setEditabilityList(new_editability_list);
                        setIsAddingEditGroup(false);
                    }
                }}>{members[k].name}</a>
            );
        }
    } catch (err) {

    }

    function wrap (str) {
        return 'calc(' + str + ')';
    }

    return (
        <div id='fv'>
            <div ref={reference} className='form-view' style={{
                position: 'absolute',
                top: wrap(position.y),
                left: wrap(position.x),
                width: wrap(size.w),
                height: (pages[currentPage].height * 75).toString() + 'vw',
                // border: 'solid 1pt #FAF3DD',
                background: '#fff',
                overflow: 'hidden',
                zIndex: '0',
                ...css
            }} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedEl(-1);
                setContextMenu([false, -1, [0, 0]]);
            }}>
                {content}
            </div>
            <div style={{
                position: 'absolute',
                top: wrap(position.y + ' + ' + (pages[currentPage].height * 75).toString() + 'vw'),
                left: wrap(position.x),
                width: wrap(size.w),
                height: '50vh',
                zIndex: '0',
                color: '#FAF3DD',
                textAlign: 'center'
            }}>
                <i class="fa-solid fa-angle-left panel-button" style={{
                    marginTop: '2vmax',
                    fontSize: '1.5vmax',
                    marginLeft: '1vmax',
                    marginRight: '30%'
                }} onClick={() => {
                    if (currentPage !== 0) {
                        setCurrentPage(currentPage - 1);
                        setVisibilityLoaded(false);
                        setEditabilityLoaded(false);
                    } else {
                        setView('left');
                    }
                }}></i>
                <i class="fa-solid fa-angle-up panel-button" style={{
                    marginTop: '2vmax',
                    fontSize: '1.5vmax',
                    marginLeft: '1vmax',
                    marginRight: '1vmax'
                }} onClick={() => {
                    if (pages[currentPage].height >= 0.3) {
                        let new_pages = [...pages];
                        new_pages[currentPage].height -= .1;
                        setPages(new_pages);
                    }
                }}></i>
                <i class="fa-solid fa-angle-down panel-button" style={{
                    marginTop: '2vmax',
                    fontSize: '1.5vmax',
                    marginLeft: '1vmax',
                    marginRight: '1vmax'
                }} onClick={() => {
                    let new_pages = [...pages];
                    new_pages[currentPage].height += .1;
                    setPages(new_pages);
                }}></i>
                <i class="fa-solid fa-angle-right panel-button" style={{
                    marginTop: '2vmax',
                    fontSize: '1.5vmax',
                    marginLeft: '30%',
                    marginRight: '1vmax'
                }} onClick={() => {
                    if (currentPage !== pages.length - 1) {
                        setCurrentPage(currentPage + 1);
                        setVisibilityLoaded(false);
                        setEditabilityLoaded(false);
                    } else {
                        setView('right');
                    }
                }}></i>
                <br />
                <a href='#' className='panel-button' style={{
                    fontSize: '1vmax',
                    fontWeight: '300',
                    padding: '0.5vmax',
                    marginTop: '3vmax',
                    marginBottom: '0'
                }} onClick={(e) => {
                    e.preventDefault();
                    setRefreshVisibility(true);
                    setIsManagingVisibility(true);
                }}>Visibility</a>
                <a href='#' className='panel-button' style={{
                    fontSize: '1vmax',
                    fontWeight: '300',
                    padding: '0.5vmax',
                    marginTop: '3vmax',
                    marginBottom: '0',
                    marginLeft: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    setRefreshEditability(true);
                    setIsManagingEditability(true);
                }}>Editability</a>
                <br />
                <p className='panel-label' style={{
                    fontSize: '1vmax',
                    marginTop: '3vmax',
                    fontWeight: '300',
                    display: 'inline-block'
                }}>Page {currentPage + 1}/{pages.length}</p>
            </div>
            {view === 'left' ? <Modal width='15' height='15'>
                <p style={{
                    fontWeight: '400',
                    fontSize: '1.25vmax',
                    marginTop: '3vh'
                }}>Create new page?</p>
                <a href='#' className='panel-button' style={{
                    marginRight: '1vmax'
                }} onClick={() => {
                    let new_comps = [...comps];
                    for (let k = 0; k < new_comps.length; k++) {
                        new_comps[k].page += 1;
                    } setComps(new_comps);
                    let new_pages = [...pages];
                    new_pages.splice(0, 0, {
                        height: 0.7,
                        visibleTo: 'all',
                        editableTo: 'all'
                    }); setPages(new_pages);
                    setView('');
                    setVisibilityLoaded(false);
                    setEditabilityLoaded(false);
                }}>Yes</a>
                <a href='#' className='panel-button' onClick={() => {
                    setView('');
                }}>No</a>
            </Modal> : <></>}
            {view === 'right' ? <Modal width='15' height='15'>
                <p style={{
                    fontWeight: '400',
                    fontSize: '1.25vmax',
                    marginTop: '3vh'
                }}>Create new page?</p>
                <a href='#' className='panel-button' style={{
                    marginRight: '1vmax'
                }} onClick={() => {
                    let new_pages = [...pages];
                    new_pages.push({
                        height: 0.7,
                        visibleTo: 'all',
                        editableTo: 'all'
                    }); setPages(new_pages);
                    setCurrentPage(currentPage + 1);
                    setView('');
                    setVisibilityLoaded(false);
                    setEditabilityLoaded(false);
                }}>Yes</a>
                <a href='#' className='panel-button' onClick={() => {
                    setView('');
                }}>No</a>
            </Modal> : <></>}
            { isManagingVisibility ? <Modal width='40' height='50'>
                <h1 style={{
                        color: '#FAF3DD',
                        fontWeight: '300',
                        margin: '1vmax 0',
                        padding: '1vmax 0',
                        fontSize: '1.5vmax',
                        display: 'block'
                }}>Manage Visibility of Page {currentPage + 1}</h1>
                <div style={{
                    height: '65%',
                    overflow: 'scroll'
                }}>
                    <div style={{
                        color: '#faf3dd',
                        fontWeight: '300',
                        fontSize: '1.15vmax'
                    }}>
                        This page is visible to <select name="visibility" id="visibility" className='panel-dropdown' onChange={(e) => {
                            setVisibilityStatus(parseInt(e.target.value));
                        }} value={visibilityStatus} style={{
                            marginLeft: '0.25vmax'
                        }}>
                            <option value={0}>everyone</option>
                            <option value={1}>the following</option>
                            <option value={2}>all except the following</option>
                        </select>
                        { visibilityStatus !== 0 ? selected_members_render : <></> }
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    bottom: '0',
                    width: '100%',
                    backgroundColor: '#000000'
                }}>
                    {visibilityStatus !== 0 ? <a href='' className='panel-button' style={{
                        marginBottom: '1vmax',
                        marginRight: '1vmax'
                    }} onClick={(e) => {
                        e.preventDefault();
                        setIsAddingGroup(true);
                    }}>Add Group</a> : <></>}
                    <a href='' className='panel-button' style={{
                        marginBottom: '1vmax'
                    }} onClick={(e) => {
                        e.preventDefault();
                        let new_pages = [...pages];
                        if (visibilityStatus === 0) {
                            new_pages[currentPage] = {
                                height: pages[currentPage].height,
                                visibleTo: 'all',
                                editableTo: pages[currentPage].editableTo
                            };
                        } else if (visibilityStatus === 1) {
                            new_pages[currentPage] = {
                                height: pages[currentPage].height,
                                visibleTo: visibilityList,
                                editableTo: pages[currentPage].editableTo
                            }
                        } else {
                            let transformed_visibility_list = [];
                            members.forEach(member => {
                                if (!visibilityList.includes(member.name)) {
                                    transformed_visibility_list.push(member.name);
                                }
                            }); new_pages[currentPage] = {
                                height: pages[currentPage].height,
                                visibleTo: transformed_visibility_list,
                                editableTo: pages[currentPage].editableTo
                            };
                        }
                        setPages(new_pages);
                        setVisibilityStatus(0);
                        setVisibilityList([]);
                        setIsManagingVisibility(false);
                    }}>Save</a>
                </div>
            </Modal> : <></> }
            { isAddingGroup ? <Modal width='40' height='50'>
                <h1 style={{
                        color: '#FAF3DD',
                        fontWeight: '300',
                        margin: '1vmax 0',
                        padding: '1vmax 0',
                        fontSize: '1.5vmax',
                        display: 'block'
                }}>Add Group</h1>
                <div style={{
                    height: '65%',
                    overflow: 'scroll'
                }}>
                    <div style={{
                        color: '#faf3dd',
                        fontWeight: '300',
                        fontSize: '1.15vmax'
                    }}>
                        { members_render }
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    bottom: '0',
                    width: '100%',
                    backgroundColor: '#000000'
                }}>
                    <a href='' className='panel-button' style={{
                        marginBottom: '1vmax'
                    }} onClick={(e) => {
                        e.preventDefault();
                        setIsAddingGroup(false);
                    }}>Exit</a>
                </div>
            </Modal> : <></> }
            { isManagingEditability ? <Modal width='40' height='50'>
                <h1 style={{
                        color: '#FAF3DD',
                        fontWeight: '300',
                        margin: '1vmax 0',
                        padding: '1vmax 0',
                        fontSize: '1.5vmax',
                        display: 'block'
                }}>Manage Editability of Page {currentPage + 1}</h1>
                <div style={{
                    height: '65%',
                    overflow: 'scroll'
                }}>
                    <div style={{
                        color: '#faf3dd',
                        fontWeight: '300',
                        fontSize: '1.15vmax'
                    }}>
                        This page is editable to <select name="visibility" id="visibility" className='panel-dropdown' onChange={(e) => {
                            setEditabilityStatus(parseInt(e.target.value));
                        }} value={editabilityStatus} style={{
                            marginLeft: '0.25vmax'
                        }}>
                            <option value={0}>everyone</option>
                            <option value={1}>the following</option>
                            <option value={2}>all except the following</option>
                        </select>
                        { editabilityStatus !== 0 ? selected_edit_members_render : <></> }
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    bottom: '0',
                    width: '100%',
                    backgroundColor: '#000000'
                }}>
                    {editabilityStatus !== 0 ? <a href='' className='panel-button' style={{
                        marginBottom: '1vmax',
                        marginRight: '1vmax'
                    }} onClick={(e) => {
                        e.preventDefault();
                        setIsAddingEditGroup(true);
                    }}>Add Group</a> : <></>}
                    <a href='' className='panel-button' style={{
                        marginBottom: '1vmax'
                    }} onClick={(e) => {
                        e.preventDefault();
                        let new_pages = [...pages];
                        if (editabilityStatus === 0) {
                            new_pages[currentPage] = {
                                height: pages[currentPage].height,
                                visibleTo: pages[currentPage].visibleTo,
                                editableTo: 'all'
                            };
                        } else if (editabilityStatus === 1) {
                            new_pages[currentPage] = {
                                height: pages[currentPage].height,
                                visibleTo: pages[currentPage].visibleTo,
                                editableTo: editabilityList
                            }
                        } else {
                            let transformed_editability_list = [];
                            members.forEach(member => {
                                if (!editabilityList.includes(member.name)) {
                                    transformed_editability_list.push(member.name);
                                }
                            }); new_pages[currentPage] = {
                                height: pages[currentPage].height,
                                editableTo: transformed_editability_list,
                                visibleTo: pages[currentPage].visibleTo
                            };
                        }
                        setPages(new_pages);
                        setEditabilityStatus(0);
                        setEditabilityList([]);
                        setIsManagingEditability(false);
                    }}>Save</a>
                </div>
            </Modal> : <></> }
            { isAddingEditGroup ? <Modal width='40' height='50'>
                <h1 style={{
                        color: '#FAF3DD',
                        fontWeight: '300',
                        margin: '1vmax 0',
                        padding: '1vmax 0',
                        fontSize: '1.5vmax',
                        display: 'block'
                }}>Add Group</h1>
                <div style={{
                    height: '65%',
                    overflow: 'scroll'
                }}>
                    <div style={{
                        color: '#faf3dd',
                        fontWeight: '300',
                        fontSize: '1.15vmax'
                    }}>
                        { edit_members_render }
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    bottom: '0',
                    width: '100%',
                    backgroundColor: '#000000'
                }}>
                    <a href='' className='panel-button' style={{
                        marginBottom: '1vmax'
                    }} onClick={(e) => {
                        e.preventDefault();
                        setIsAddingEditGroup(false);
                    }}>Exit</a>
                </div>
            </Modal> : <></> }
        </div>
    );

}