
import { useState } from "react";
import Modal from "../../../views/utils/Modal";

export default function ViewVars ({ comps, setComps, setView, vars, setVars }) {

    let vars_list = [];
    for (let k = 0; k < vars.length; k++) {
        vars_list.push(
            <div style={{
                padding: '0.5vmax',
                textAlign: 'justify',
                border: '1px #FAF3DD solid',
                borderTop: k !== 0 ? '' : '1px #FAF3DD solid',
                margin: '2vmax 2vmax 0 2vmax',
                marginTop: k !== 0 ? '' : '2vmax',
                backgroundColor: '#000'
            }}>
                <div style={{
                    width: '75%',
                    display: 'inline-block'
                }}>
                    <select className='panel-dropdown' style={{
                        fontSize: '1vmax',
                        width: '32.5%'
                    }} onChange={(e) => {
                        let new_vars = [...vars];
                        new_vars[k].type = e.target.value;
                        if (e.target.value === 'Color') {
                            new_vars[k].value = '#fff';
                        } else if (e.target.value === 'Number') {
                            new_vars[k].value = '1';
                        } else if (e.target.value === 'String') {
                            new_vars[k].value = 'Hello, World';
                        } else if (e.target.value === 'Boolean') {
                            new_vars[k].value = 'true';
                        }
                        setVars(new_vars);
                    }}
                            value={vars[k].type}>
                        <option value='Number'>Number</option>
                        <option value='String'>String</option>
                        <option value='Boolean'>Boolean</option>
                        <option value='Color'>Color</option>
                    </select>
                    <input className='panel-input' style={{
                        marginLeft: '0.75vmax',
                        fontSize: '1vmax',
                        width: '20%'
                    }} value={vars[k].name} onChange={(e) => {
                        let new_vars = [...vars];
                        new_vars[k].name = e.target.value;
                        setVars(new_vars);
                    }} />
                    <p className='panel-label' style={{
                        marginLeft: '0.5vmax',
                        fontSize: '1.25vmax'
                    }}>=</p>
                    {vars[k].type === 'Number' || 
                        vars[k].type === 'String' ? <input className='panel-input' style={{
                        fontSize: '1vmax',
                        width: '20%'
                    }} value={vars[k].value} onChange={(e) => {
                        let new_vars = [...vars];
                        new_vars[k].value = e.target.value;
                        setVars(new_vars);
                    }} /> : <></>}
                    {
                        vars[k].type === 'Color' ? 
                        <input type='color' className='panel-color-input' onChange={(e) => {
                            let new_vars = [...vars];
                            new_vars[k].value = e.target.value;
                            setVars(new_vars);
                        }} value={vars[k].value} />
                        : <></>
                    }
                    {
                        vars[k].type === 'Boolean' ?
                        <select className='panel-dropdown' style={{
                            fontSize: '1vmax',
                            width: '22.5%'
                        }} onChange={(e) => {
                            let new_vars = [...vars];
                            new_vars[k].value = e.target.value;
                            setVars(new_vars);
                        }} value={vars[k].value}>
                            <option value='true'>True</option>
                            <option value='false'>False</option>
                        </select> : <></>
                    }
                </div>
                <div style={{
                    width: '25%',
                    display: 'inline-block'
                }}>
                    <i class="fa-solid fa-angles-down pointy" style={{
                        color: '#FAF3DD',
                        fontSize: '1.25vmax',
                        margin: '0 1vmax 0 0vmax'
                    }} onClick={() => {
                        if (k !== vars.length - 1) {
                            let new_vars = [...vars];
                            let current = new_vars[k];
                            new_vars[k] = new_vars[k + 1];
                            new_vars[k + 1] = current;
                            setVars(new_vars);
                        }
                    }}></i>
                    <i class="fa-solid fa-angles-up pointy" style={{
                        color: '#FAF3DD',
                        fontSize: '1.25vmax',
                        margin: '0 0vmax 0 0vmax'
                    }} onClick={() => {
                        if (k !== 0) {
                            let new_vars = [...vars];
                            let current = new_vars[k];
                            new_vars[k] = new_vars[k - 1];
                            new_vars[k - 1] = current;
                            setVars(new_vars);
                        }
                    }}></i>
                    <i class="fa-solid fa-trash pointy" style={{
                        color: '#FAF3DD',
                        fontSize: '1.25vmax',
                        margin: '0 0vmax 0 1.5vmax'
                    }} onClick={() => {
                        let new_vars = [...vars];
                        new_vars.splice(k, 1);
                        setVars(new_vars);
                    }}></i>
                </div>
            </div>
        );
    }

    return (
        <>
        <Modal width={30} height={40}>
            <h1 style={{
                color: '#FAF3DD',
                fontWeight: '300',
                margin: '0',
                padding: '1vmax 0',
                fontSize: '1.75vmax',
                borderBottom: '1px #FAF3DD solid',
                display: 'block'
            }}>View Variables</h1>
            <div style={{
                height: '27.5vh',
                overflow: 'scroll'
            }}>
                {vars_list}
                <br /> <br /> <br />
            </div>
            <div style={{
                position: 'absolute',
                bottom: '0',
                width: '100%',
                backgroundColor: '#000000'
            }}>
                <a href='' className='panel-button' style={{
                    margin: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    let new_vars = [...vars];
                    new_vars.push({
                        name: 'New',
                        type: 'Number',
                        value: '1'
                    }); setVars(new_vars);
                }}>Add Variable</a>
                <a href='' className='panel-button' style={{
                    marginBottom: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    setView('');
                }}>Ok</a>
            </div>
        </Modal>
        </>
    );

}