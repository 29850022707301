
import { useEffect, useState } from "react";

const commandColor = '#dfe6e9';

 // Has params: props.id, props.comps, props.setComps, props.indices, props.setIsScriptRendered, props.setScriptErrorsExist
export default function WarningSignalCommand (props) {

    const [ evaluatedErrors, setEvaluatedErrors ] = useState(false);
    const [ color, setColor ] = useState(commandColor);

    let message = "Loading...";
    let thisObj = props.comps[props.id].script[props.indices[0]];
    for (let k = 0; k < props.indices.length - 1; ++k) {
        thisObj = thisObj.children[props.indices[k + 1]];
    }
    
    try {
        message = thisObj.message;
    } catch (e) {

    }

    // if (!evaluatedErrors) {
    //     if (/\S/.test(message)) {
    //         setColor('#faf3dd');
    //         setEvaluatedErrors(true);
    //     } else {
    //         setColor('#FC427B');
    //         setEvaluatedErrors(true);
    //     }
    // }

    // useEffect(() => {
    //     if (/\S/.test(message)) {
    //         setColor('#faf3dd');
    //         setEvaluatedErrors(true);
    //     } else {
    //         setColor('#FC427B');
    //         setEvaluatedErrors(true);
    //     }
    // });

    return <>
        <div style={{
            marginBottom: '2vmax',
            overflow: 'auto',
            display: 'inline-block',
            marginRight: '4vmax',
            verticalAlign: 'top'
        }}>
            <div style={{
                padding: '1vmax',
                backgroundColor: color,
                color: '#000',
                display: 'inline-block',
                fontSize: '1.15vmax',
                fontFamily: "Work Sans, Lato, sans-serif",
                fontWeight: '500'
            }}>
                WARNING
            </div>
            <a className='panel-button' style={{
                padding: '1vmax',
                display: 'inline-block',
                fontSize: '1.15vmax',
                fontFamily: "Work Sans, Lato, sans-serif",
                fontWeight: '300',
                border: '0pt',
                borderLeft: `1pt solid #faf3dd`,
                marginLeft: '1vmax'
            }} onClick={(e) => {
                e.preventDefault();
                let new_comps = [...props.comps];
                let thisObj = new_comps[props.id].script[props.indices[0]];
                for (let k = 0; k < props.indices.length - 2; ++k) {
                    thisObj = thisObj.children[props.indices[k + 1]];
                } thisObj.children.splice(props.indices[props.indices.length - 1], 1);
                props.setComps(new_comps);
                props.setIsScriptRendered(false);
            }}>
                Delete
            </a>
            {/* Copy Command Icon */}
           <i className='fa-solid fa-copy pointy comp panel-button' style={{
               padding: '1vmax',
               display: 'inline-block',
               fontSize: '1.15vmax',
               fontWeight: '300',
               border: '0pt',
               borderLeft: `1pt solid #faf3dd`,
               marginLeft: '1vmax'
           }} onClick={(e) => {
               e.preventDefault();
               let thisObj = props.comps[props.id].script[props.indices[0]];
               for (let k = 0; k < props.indices.length - 1; ++k) {
                    thisObj = thisObj.children[props.indices[k + 1]];
                }
               navigator.clipboard.writeText(JSON.stringify(thisObj));
           }}></i>
            <div style={{
                padding: '2vmax 0',
                borderLeft: `3pt solid ${color}`,
                borderBottom: `1pt dashed ${color}`,
                paddingLeft: '2vmax',
                fontFamily: "Work Sans, Lato, sans-serif",
                fontWeight: '500',
                paddingRight: '2vmax',
                fontSize: '1vmax'
            }} className='code-block'>
                PRINT <input style={{
                    fontWeight: '300',
                    marginLeft: '0.5vmax',
                    padding: '0.5vmax',
                    minWidth: '10vmax'
                }} className='panel-input' value={message} onChange={(e) => {
                    let new_comps = [...props.comps];
                    let thisObj = new_comps[props.id].script[props.indices[0]];
                    for (let k = 0; k < props.indices.length - 1; ++k) {
                        thisObj = thisObj.children[props.indices[k + 1]];
                    } thisObj.message = e.target.value;
                    props.setComps(new_comps);
                    props.setIsScriptRendered(false);
                    setEvaluatedErrors(false);
                }} placeholder="No Error"></input>
            </div>
        </div>
    </>;

}