
import { Rnd } from 'react-rnd';
import {
    useState,
    useEffect,
    useRef
} from 'react';

import '../component.css';
import process_vars from '../../process_vars';

import ScriptView from '../script/ScriptView';
import Modal from '../../../views/utils/Modal';

export default function InputTable ({ id, comps, setter, vw, gridSize, sel, setSel, hov, setHov, vars, setVars, contextMenu, setContextMenu }) {

    let background_color = '';
    if (hov === id) {
        background_color = '#FAF3DD';
    } else {
        background_color = process_vars(comps[id].props.backgroundColor, vars);
    } if (sel === id) {
        background_color = process_vars(comps[id].props.backgroundColor, vars);
    }

    let innerContent = [];
    let columnHeadings = []
    let rowHeadings = [];

    let optionsView = [];
    let optionsSelectRender = [];

    const [ columnEditor, setColumnEditor ] = useState([ false, -1 ]); // columnEditorShowing?, columnId

    try {
        let rows = comps[id].data.length;
        let cols = comps[id].data[0].length;

        let totalWidth = 0;
        for (let j = 0; j < comps[id].colData.length; ++j) {
            totalWidth = totalWidth + comps[id].colData[j].width;
        }

        for (let j = 0; j < cols; ++j) {

            let totalWidthBeforeThisColumn = 0;
            for (let p = 0; p < j; ++p) {
                totalWidthBeforeThisColumn = totalWidthBeforeThisColumn + comps[id].colData[p].width;
            }

            for (let i = 0; i < rows; ++i) {

                let width = (process_vars(comps[id].size[0], vars) * vw) * (comps[id].colData[j].width / totalWidth);
                let height = (process_vars(comps[id].size[1], vars) * vw)/rows;
                let topPos = (i * height);
                let leftPos = (totalWidthBeforeThisColumn / totalWidth) * vw * process_vars(comps[id].size[0], vars);

                let contentToPush = <></>;
                if (comps[id].colData[j].type === "Input") {
                    contentToPush = <div style={{
                        position: 'absolute',
                        width: `${width}px`,
                        height: `${height}px`,
                        top: `calc(${topPos}px + 0vmax)`,
                        left: `calc(${leftPos}px + 0.5vmax)`,
                        fontSize: `${process_vars(comps[id].props.fontSize, vars)}vmax`,
                        backgroundColor: `${process_vars(comps[id].props.backgroundColor, vars)}`,
                        color: `${process_vars(comps[id].props.color, vars)}`,
                        border: process_vars(comps[id].props.borderEnabled, vars) ?
                            `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px'
                        // paddingTop: `${height / }px`,
                    }} onClick={(e) => {
                        e.stopPropagation();
                    }}><input style={{
                        border: 'none',
                        height: '95%',
                        width: '90%',
                        backgroundColor: `${process_vars(comps[id].props.backgroundColor, vars)}`
                    }} value={comps[id].data[i][j]} onChange={(e) => {
                        let new_comps = [...comps];
                        new_comps[id].data[i][j] = e.target.value;
                        setter(new_comps);
                    }}></input></div>;
                } else if (comps[id].colData[j].type === "Label") {
                    contentToPush = <div style={{
                        position: 'absolute',
                        width: `${width}px`,
                        height: `${height}px`,
                        top: `calc(${topPos}px + 0vmax)`,
                        left: `calc(${leftPos}px + 0.5vmax)`,
                        fontSize: `${process_vars(comps[id].props.fontSize, vars)}vmax`,
                        backgroundColor: `${process_vars(comps[id].props.backgroundColor, vars)}`,
                        color: `${process_vars(comps[id].props.color, vars)}`,
                        border: process_vars(comps[id].props.borderEnabled, vars) ?
                            `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px'
                        // paddingTop: `${height / }px`,
                    }} onClick={(e) => {
                        e.stopPropagation();
                    }}><input style={{
                        border: 'none',
                        height: '95%',
                        width: '90%',
                        backgroundColor: `${process_vars(comps[id].props.backgroundColor, vars)}`,
                        textAlign: comps[id].colData[j].align
                    }} value={comps[id].data[i][j]} onChange={(e) => {
                        let new_comps = [...comps];
                        new_comps[id].data[i][j] = e.target.value;
                        setter(new_comps);
                    }} onDoubleClick={(e) => {
                        e.target.select();
                    }}></input></div>;
                } else if (comps[id].colData[j].type === "Check") {
                    contentToPush = <div style={{
                        position: 'absolute',
                        width: `${width}px`,
                        height: `${2 * height / 3}px`,
                        top: `calc(${topPos}px + 0vmax)`,
                        left: `calc(${leftPos}px + 0.5vmax)`,
                        fontSize: `${process_vars(comps[id].props.fontSize, vars)}vmax`,
                        backgroundColor: `${process_vars(comps[id].props.backgroundColor, vars)}`,
                        color: `${process_vars(comps[id].props.color, vars)}`,
                        border: process_vars(comps[id].props.borderEnabled, vars) ?
                            `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px',
                        paddingTop: `${height / 3}px`,
                    }} onClick={(e) => {
                        e.stopPropagation();
                    }}>
                        <input type="checkbox" style={{
                            fontSize: '1vmax'
                        }} checked={comps[id].data[i][j]} onClick={(e) => {
                            let new_comps = [...comps];
                            new_comps[id].data[i][j] = !new_comps[id].data[i][j];
                            setter(new_comps);
                        }}></input>
                    </div>;
                } else if (comps[id].colData[j].type === "Drop") {

                    let optionsSelectRender = [];
                    for (let k = 0; k < comps[id].colData[j].values.length; ++k) {
                        optionsSelectRender.push(<option value={comps[id].colData[j].values[k]}>{comps[id].colData[j].values[k]}</option>)
                    }

                    contentToPush = <div style={{
                        position: 'absolute',
                        width: `${width}px`,
                        height: `${2 * height / 3}px`,
                        top: `calc(${topPos}px + 0vmax)`,
                        left: `calc(${leftPos}px + 0.5vmax)`,
                        fontSize: `${process_vars(comps[id].props.fontSize, vars)}vmax`,
                        backgroundColor: `${process_vars(comps[id].props.backgroundColor, vars)}`,
                        color: `${process_vars(comps[id].props.color, vars)}`,
                        border: process_vars(comps[id].props.borderEnabled, vars) ?
                            `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px',
                        paddingTop: `${height / 3}px`,
                    }} onClick={(e) => {
                        e.stopPropagation();
                    }}>
                        <select className='panel-select' style={{
                            fontSize: '1vmax'
                        }} value={comps[id].colData[j].values[comps[id].data[i][j]]} onChange={(e) => {
                            let new_comps = [...comps];
                            new_comps[id].data[i][j] = comps[id].colData[j].values.indexOf(e.target.value);
                            setter(new_comps);
                        }}>
                            { optionsSelectRender }
                        </select>
                    </div>;
                } else if (comps[id].colData[j].type === "Sign") {
                    contentToPush = <div style={{
                        position: 'absolute',
                        width: `${width}px`,
                        height: `${height}px`,
                        top: `calc(${topPos}px + 0vmax)`,
                        left: `calc(${leftPos}px + 0.5vmax)`,
                        fontSize: `${process_vars(comps[id].props.fontSize, vars)}vmax`,
                        backgroundColor: `${process_vars(comps[id].props.backgroundColor, vars)}`,
                        color: `${process_vars(comps[id].props.color, vars)}`,
                        border: process_vars(comps[id].props.borderEnabled, vars) ?
                            `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px'
                        // paddingTop: `${height / }px`,
                    }} onClick={(e) => {
                        e.stopPropagation();
                    }}><p style={{
                        fontWeight: '300',
                        padding: '15%'
                    }}>Signature Region</p></div>;
                }

                innerContent.push(contentToPush);
            }
        }

        for (let k = 0; k < cols; ++k) {
            let totalWidthBeforeThisColumn = 0;
            for (let p = 0; p < k; ++p) {
                totalWidthBeforeThisColumn = totalWidthBeforeThisColumn + comps[id].colData[p].width;
            }
            let scrollPos = document.getElementById("wrapper").scrollTop;
            let topPosition = `calc(-2.5vmax)`;
            let leftPosition = `calc(${totalWidthBeforeThisColumn / totalWidth * comps[id].size[0] * vw}px + 7px)`;
            let navbarHeight = window.innerHeight * 0.075;
            if (scrollPos >= comps[id].position[1] * vw + navbarHeight && scrollPos <= comps[id].position[1] * vw + navbarHeight + comps[id].size[1] * vw) {
                let deltaScroll = scrollPos - (comps[id].position[1] * vw + navbarHeight);
                topPosition = `calc(${deltaScroll}px + 2vmax)`;
            }
            columnHeadings.push(
                <div style={{
                    display: 'table'
                }}>
                    <div style={{
                        display: 'table-row',
                        width: `${(comps[id].colData[k].width / totalWidth) * vw * comps[id].size[0]}px`,
                        height: navbarHeight / 2.75,
                        fontSize: '1vmax',
                        position: 'fixed',
                        // position: 'absolute',
                        padding: '0.3vmax 0',
                        top: topPosition,
                        left: leftPosition,
                        zIndex: '5',
                        overflowY: 'scroll',
                        textAlign: 'center',
                        // overflowX: 'hidden'
                        // overflowWrap: 'break-word'
                    }} className='panel-button' onClick={(e) => {
                        e.stopPropagation();
                        if (!columnEditor[0]) {
                            setColumnEditor([ true, k ]);
                        }
                    }}>
                        <div style={{
                            display: 'table-cell',
                            verticalAlign: 'middle',
                            textAlign: 'center',
                            // background: 'red',
                            width: `${(comps[id].colData[k].width / totalWidth) * vw * comps[id].size[0]}px`,
                            height: navbarHeight / 2.75,
                        }}>{comps[id].colData[k].name}</div>
                    </div>
                </div>
            );
        }

        for (let k = 0; k < rows; ++k) {
            let elementHeight = comps[id].size[1] * vw;
            rowHeadings.push(<div style={{
                width: '2vmax',
                fontSize: '1vmax',
                position: 'fixed',
                top: `${(100/rows) * k}%`,
                display: 'inline-block',
                left: '-2.3vmax',
                height: `calc(${elementHeight / rows}px - ${(elementHeight / rows) / 2}px + 1vmax)`,
                padding: '0',
                paddingTop: `calc(${(elementHeight / rows) / 2}px - 0.5vmax)`
            }} className='panel-button' onClick={(e) => {
                e.preventDefault();
                let shouldDelete = window.confirm('Delete this row?'); // TODO: Replace this with Modal, later
                if (shouldDelete && comps[id].data.length > 1) {
                    let new_comps = [...comps];
                    new_comps[id].data.splice(k, 1);
                    new_comps[id].error.splice(k, 1);
                    new_comps[id].warning.splice(k, 1);
                    new_comps[id].vars.splice(k, 1);
                    new_comps[id].disable.splice(k, 1);
                    setter(new_comps); 
                }
            }}>{k + 1}</div>)
        }

        for (let k = 0; k < comps[id].colData[columnEditor[1]].values.length; ++k) {
            optionsView.push(<>
                <br />
                <input className='panel-input' value={comps[id].colData[columnEditor[1]].values[k]} style={{
                    width: '25%',
                    marginBottom: k < comps[id].colData[columnEditor[1]].values.length - 1 ? '0.5vmax' : '1vmax',
                    textAlign: 'center'
                }} onChange={(e) => {
                    let new_comps = [...comps];
                    new_comps[id].colData[columnEditor[1]].values[k] = e.target.value;
                    setter(new_comps);
                }}></input>
            </>)
        }

    } catch (err) {

    }

    return (
        <div>
            <Rnd className='comp' style={{
                backgroundColor: background_color,
                textAlign: 'center',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: 'visible',
                zIndex: '0',
                border: '#000 solid 0px'
            }} position={{
                x: process_vars(comps[id].position[0], vars) * vw,
                y: process_vars(comps[id].position[1], vars) * vw
            }} size={{
                width: process_vars(comps[id].size[0], vars) * vw,
                height: process_vars(comps[id].size[1], vars) * vw
            }} onDragStop={(e, d) => {
                let new_comps = [...comps];
                new_comps[id].position = [
                    d.x / vw,
                    d.y / vw
                ];
                setter(new_comps);
            }} onResize={(e, direction, ref, delta, position) => {
                let new_comps = [...comps];
                new_comps[id].position = [
                    position.x / vw,
                    position.y / vw
                ];
                new_comps[id].size = [
                    ref.offsetWidth / vw,
                    ref.offsetHeight / vw
                ];
                setter(new_comps);
            }} minWidth={window.innerWidth * 0.05} minHeight={window.innerWidth * 0.025} bounds='.form-view' 
            dragGrid={[
                gridSize,
                gridSize
            ]} resizeGrid={[
                gridSize,
                gridSize
            ]} onMouseEnter={() => {
                setHov(id);
            }} onMouseLeave={() => {
                setHov(-1);
            }} onDoubleClick={() => {
                setSel(id);
            }} onContextMenu={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setContextMenu([true, id, [e.clientX, e.clientY]]);
            }} onClick={(e) => {
                e.stopPropagation();
            }}>
                { columnHeadings }
                { rowHeadings }
                {innerContent}

                {/* Column Manager */}
                <div style={{
                    position: 'absolute',
                    left: '100%',
                    width: '5vmax',
                    height: '100%',
                    display: 'inline-block'
                }}>
                    <i className='fa-solid fa-plus panel-button' style={{
                        padding: '0.5vmax',
                        fontSize: '1vmax',
                        marginTop: `calc(${comps[id].size[1] * vw/2}px - 3vmax)`
                    }} onClick={(e) => {
                        e.stopPropagation();
                        let new_comps = [...comps];
                        for (let k = 0; k < comps[id].data.length; ++k) {
                            new_comps[id].data[k].push("");
                            new_comps[id].disable[k].push(false);
                            new_comps[id].error[k].push("");
                            new_comps[id].warning[k].push("");
                        } new_comps[id].colData.push({ type: "Input", name: 'New', width: 1 });
                        setter(new_comps);
                    }}></i> <br />
                    <i className='fa-solid fa-minus panel-button' style={{
                        padding: '0.5vmax',
                        fontSize: '1vmax',
                        marginTop: '1vmax'
                    }} onClick={(e) => {
                        e.stopPropagation();
                        if (comps[id].data[0].length > 1) {
                            let new_comps = [...comps];
                            for (let k = 0; k < comps[id].data.length; ++k) {
                                new_comps[id].data[k].splice(new_comps[id].data[k].length - 1, 1);
                                new_comps[id].disable[k].splice(new_comps[id].disable[k].length - 1, 1);
                                new_comps[id].error[k].splice(new_comps[id].error[k].length - 1, 1);
                                new_comps[id].warning[k].splice(new_comps[id].warning[k].length - 1, 1);
                            } new_comps[id].colData.splice(new_comps[id].colData.length - 1, 1);
                            setter(new_comps);
                        }
                    }}></i>
                </div>

                {/* Row Manager */}
                <div style={{
                    position: 'absolute',
                    left: '0%',
                    top: '100%',
                    width: '102%',
                    height: '5vmax',
                    display: 'block'
                }}>
                    <i className='fa-solid fa-plus panel-button' style={{
                        padding: '0.5vmax',
                        fontSize: '1vmax',
                        marginTop: '1.5vmax'
                    }} onClick={(e) => {
                        e.stopPropagation();
                        let new_comps = [...comps];
                        let new_row = [...comps[id].data[comps[id].data.length - 1]];
                        // for (let k = 0; k < new_comps[id].data[0].length; ++k) {
                        //     new_row.push("");
                        // }
                        new_comps[id].data.push(new_row);
                        new_comps[id].error.push([...new_comps[id].error[new_comps[id].error.length - 1]]);
                        new_comps[id].warning.push([...new_comps[id].warning[new_comps[id].warning.length - 1]]);
                        new_comps[id].vars.push([...new_comps[id].vars[new_comps[id].vars.length - 1]]);
                        new_comps[id].disable.push([...new_comps[id].disable[new_comps[id].disable.length - 1]]);
                        setter(new_comps);
                    }}></i>
                    <i className='fa-solid fa-minus panel-button' style={{
                        padding: '0.5vmax',
                        fontSize: '1vmax',
                        marginLeft: '1vmax'
                    }} onClick={(e) => {
                        e.stopPropagation();
                        if (comps[id].data.length > 1) {
                            let new_comps = [...comps];
                            new_comps[id].data.splice(new_comps[id].data.length - 1, 1);
                            new_comps[id].error.splice(new_comps[id].error.length - 1, 1);
                            new_comps[id].warning.splice(new_comps[id].warning.length - 1, 1);
                            new_comps[id].vars.splice(new_comps[id].vars.length - 1, 1);
                            new_comps[id].disable.splice(new_comps[id].disable.length - 1, 1);
                            setter(new_comps);
                        }
                    }}></i>
                </div>
            </Rnd>
            { columnEditor[0] ? <Modal width="30" height="40">
                <div style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '87.5%',
                    overflowY: 'scroll'
                }}>
                    <p style={{
                        fontSize: '1.5vmax',
                        fontWeight: '300'
                    }}>Edit Column {columnEditor[1] + 1}</p>
                    <br />
                    <p style={{
                        fontSize: '1vmax',
                        fontWeight: '300',
                        display: 'inline-block',
                        marginRight: '0.75vmax'
                    }}>Name:</p>
                    <input className='panel-input' value={comps[id].colData[columnEditor[1]].name} onChange={(e) => {
                        let new_comps = [...comps];
                        new_comps[id].colData[columnEditor[1]].name = e.target.value;
                        setter(new_comps);
                    }}></input>
                    <br />
                    <p style={{
                        fontSize: '1vmax',
                        fontWeight: '300',
                        display: 'inline-block',
                        marginRight: '0.75vmax'
                    }}>Width ({comps[id].colData[columnEditor[1]].width}):</p>
                    <input type='range' min='1' max='10' value={comps[id].colData[columnEditor[1]].width} onChange={(e) => {
                        let new_comps = [...comps];
                        new_comps[id].colData[columnEditor[1]].width = parseInt(e.target.value);
                        setter(new_comps);
                    }}></input>
                    <br />
                    <p style={{
                        fontSize: '1vmax',
                        fontWeight: '300',
                        display: 'inline-block',
                        marginRight: '0.75vmax'
                    }}>Type:</p>
                    <a href='#' style={{
                        fontSize: '1vmax',
                        fontWeight: '300',
                        display: 'inline-block',
                        marginRight: '0.75vmax',
                        backgroundColor: comps[id].colData[columnEditor[1]].type === "Label" ? "#faf3dd" : "#000",
                        color: comps[id].colData[columnEditor[1]].type === "Label" ? "#000" : "#faf3dd"
                    }} className='panel-button' onClick={(e) => {
                        e.preventDefault();
                        let new_comps = [...comps];
                        new_comps[id].colData[columnEditor[1]] = { type: "Label", name: new_comps[id].colData[columnEditor[1]].name, width: new_comps[id].colData[columnEditor[1]].width, align: 'center' };
                        for (let k = 0; k < new_comps[id].data.length; ++k) {
                            new_comps[id].data[k][columnEditor[1]] = "";
                        }
                        setter(new_comps);
                    }}>Label</a>
                    <a href='#' style={{
                        fontSize: '1vmax',
                        fontWeight: '300',
                        display: 'inline-block',
                        marginRight: '0.75vmax',
                        backgroundColor: comps[id].colData[columnEditor[1]].type === "Input" ? "#faf3dd" : "#000",
                        color: comps[id].colData[columnEditor[1]].type === "Input" ? "#000" : "#faf3dd"
                    }} className='panel-button' onClick={(e) => {
                        e.preventDefault();
                        let new_comps = [...comps];
                        new_comps[id].colData[columnEditor[1]] = { type: "Input", name: new_comps[id].colData[columnEditor[1]].name, width: new_comps[id].colData[columnEditor[1]].width };
                        for (let k = 0; k < new_comps[id].data.length; ++k) {
                            new_comps[id].data[k][columnEditor[1]] = "";
                        }
                        setter(new_comps);
                    }}>Input</a>
                    <a href='#' style={{
                        fontSize: '1vmax',
                        fontWeight: '300',
                        display: 'inline-block',
                        marginRight: '0.75vmax',
                        backgroundColor: comps[id].colData[columnEditor[1]].type === "Check" ? "#faf3dd" : "#000",
                        color: comps[id].colData[columnEditor[1]].type === "Check" ? "#000" : "#faf3dd"
                    }} className='panel-button' onClick={(e) => {
                        e.preventDefault();
                        let new_comps = [...comps];
                        new_comps[id].colData[columnEditor[1]] = { type: "Check", name: new_comps[id].colData[columnEditor[1]].name, width: new_comps[id].colData[columnEditor[1]].width };
                        for (let k = 0; k < new_comps[id].data.length; ++k) {
                            new_comps[id].data[k][columnEditor[1]] = false;
                        }
                        setter(new_comps);
                    }}>Check</a>
                    <a href='#' style={{
                        fontSize: '1vmax',
                        fontWeight: '300',
                        display: 'inline-block',
                        marginRight: '0.75vmax',
                        backgroundColor: comps[id].colData[columnEditor[1]].type === "Drop" ? "#faf3dd" : "#000",
                        color: comps[id].colData[columnEditor[1]].type === "Drop" ? "#000" : "#faf3dd"
                    }} className='panel-button' onClick={(e) => {
                        e.preventDefault();
                        let new_comps = [...comps];
                        new_comps[id].colData[columnEditor[1]] = { type: "Drop", values: [ "A", "B", "C" ], name: new_comps[id].colData[columnEditor[1]].name, width: new_comps[id].colData[columnEditor[1]].width };
                        for (let k = 0; k < new_comps[id].data.length; ++k) {
                            new_comps[id].data[k][columnEditor[1]] = 0;
                        }
                        setter(new_comps);
                    }}>Drop</a>
                    <a href='#' style={{
                        fontSize: '1vmax',
                        fontWeight: '300',
                        display: 'inline-block',
                        marginRight: '0.75vmax',
                        backgroundColor: comps[id].colData[columnEditor[1]].type === "Sign" ? "#faf3dd" : "#000",
                        color: comps[id].colData[columnEditor[1]].type === "Sign" ? "#000" : "#faf3dd"
                    }} className='panel-button' onClick={(e) => {
                        e.preventDefault();
                        let new_comps = [...comps];
                        new_comps[id].colData[columnEditor[1]] = { type: "Sign", name: new_comps[id].colData[columnEditor[1]].name, width: new_comps[id].colData[columnEditor[1]].width };
                        for (let k = 0; k < new_comps[id].data.length; ++k) {
                            new_comps[id].data[k][columnEditor[1]] = '';
                        }
                        setter(new_comps);
                    }}>Sign</a>
                    <br />

                    {comps[id].colData[columnEditor[1]].type === "Drop" ? <>
                        <p style={{
                            fontSize: '1vmax',
                            fontWeight: '300',
                            display: 'inline-block',
                            marginRight: '0.75vmax'
                        }}>Options</p>
                        {optionsView}
                        <br />
                        <a href='#' className='panel-button' style={{
                            marginBottom: '2vmax',
                            marginRight: '1vmax'
                        }} onClick={(e) => {
                            e.preventDefault();
                            let new_comps = [...comps];
                            new_comps[id].colData[columnEditor[1]].values.push("New Option");
                            setter(new_comps);
                        }}>Add</a>
                        <a href='#' className='panel-button' onClick={(e) => {
                            e.preventDefault();
                            if (comps[id].colData[columnEditor[1]].values.length > 1) {
                                let new_comps = [...comps];
                                new_comps[id].colData[columnEditor[1]].values.splice(new_comps[id].colData[columnEditor[1]].values.length - 1, 1);
                                setter(new_comps);
                            }
                        }}>Delete</a>
                    </> : <></>}
                    {
                        comps[id].colData[columnEditor[1]].type === "Label" ? <>
                            <p className='panel-label'>Alignment: </p>
                            <i className='fa-solid fa-align-left panel-icon' style={{
                                color: comps[id].colData[columnEditor[1]].align === 'left' ? '#0F1108' : '#FAF3DD',
                                backgroundColor: comps[id].colData[columnEditor[1]].align === 'left' ? '#FAF3DD' : '#0F1108'
                            }} onClick={() => {
                                let new_comps = [...comps];
                                comps[id].colData[columnEditor[1]].align = 'left';
                                setter(new_comps);
                            }}></i>
                            <i className='fa-solid fa-align-center panel-icon' style={{
                                color: comps[id].colData[columnEditor[1]].align === 'center' ? '#0F1108' : '#FAF3DD',
                                backgroundColor: comps[id].colData[columnEditor[1]].align === 'center' ? '#FAF3DD' : '#0F1108'
                            }} onClick={() => {
                                let new_comps = [...comps];
                                comps[id].colData[columnEditor[1]].align = 'center';
                                setter(new_comps);
                            }}></i>
                            <i className='fa-solid fa-align-right panel-icon' style={{
                                color: comps[id].colData[columnEditor[1]].align === 'right' ? '#0F1108' : '#FAF3DD',
                                backgroundColor: comps[id].colData[columnEditor[1]].align === 'right' ? '#FAF3DD' : '#0F1108'
                            }} onClick={() => {
                                let new_comps = [...comps];
                                comps[id].colData[columnEditor[1]].align = 'right';
                                setter(new_comps);
                            }}></i>
                        </> : <></>
                    }
                    <br />
                    <a href='#' className='panel-button' onClick={(e) => {
                        // do we have more than one column?
                        if (comps[id].data[0].length > 1) {
                            let new_comps = [...comps];
                            for (let k = 0; k < comps[id].data.length; ++k) {
                                new_comps[id].data[k].splice(columnEditor[1], 1);
                                new_comps[id].disable[k].splice(columnEditor[1], 1);
                                new_comps[id].error[k].splice(columnEditor[1], 1);
                                new_comps[id].warning[k].splice(columnEditor[1], 1);
                            } new_comps[id].colData.splice(columnEditor[1], 1);
                            setColumnEditor([ false, -1 ]);
                            setter(new_comps);
                        }
                    }}>Delete Column</a>
                    <br />
                    <br />
                    <br />
                </div>
                <div style={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    width: '100%',
                    height: '12.5%'
                }}>
                    <a href='#' className='panel-button' onClick={(e) => {
                        e.preventDefault();
                        setColumnEditor([ false, -1 ]);
                    }}>Ok</a>
                </div>
            </Modal> : <></> }
        </div>
    );

}

export function InputTableProperties ({ id, comps, setter, setSel, vars, setVars }) {

    const [ isShowingScript, setIsShowingScript ] = useState(false);
    const [ isShowingVariables, setIsShowingVariables ] = useState(false);
    const [ hasRenderedVariables, setHasRenderedVariables ] = useState(false);
    const [ variablesRender, setVariablesRender ] = useState([]);

    if (!hasRenderedVariables) {
        let variables_render = [];
        for (let k = 0; k < comps[id].vars[0].length; ++k) {
            variables_render.push(<div style={{
                width: '90%',
                padding: '0.5vmax',
                marginBottom: '0.5vmax',
                marginLeft: 'calc(5% - 2pt - 0.5vmax)',
                fontWeight: '300'
            }}>
                <select className='panel-dropdown' style={{
                    fontSize: '1vmax',
                    width: '20%',
                    marginRight: '1vmax'
                }} onChange={(e) => {
                    let new_comps = [...comps];
                    for (let j = 0; j < comps[id].vars.length; ++j) {
                        new_comps[id].vars[j][k].type = e.target.value;
                        if (e.target.value === 'Color') {
                            new_comps[id].vars[j][k].value = "#ffffff";
                        } else if (e.target.value === 'Number') {
                            new_comps[id].vars[j][k].value = "1";
                        } else if (e.target.value === 'String') {
                            new_comps[id].vars[j][k].value = "Hello, World!";
                        } else if (e.target.value === 'Boolean') {
                            new_comps[id].vars[j][k].value = "true";
                        }
                    } console.log(new_comps[id].vars);
                    setter(new_comps);
                    setHasRenderedVariables(false);
                }}
                        value={comps[id].vars[0][k].type}>
                    <option value='Number'>Number</option>
                    <option value='String'>String</option>
                    <option value='Boolean'>Boolean</option>
                    <option value='Color'>Color</option>
                </select>
                <input value={comps[id].vars[0][k].name} className='panel-input' style={{
                    width: '20%',
                    marginRight: '0.5vmax'
                }} onChange={(e) => {
                    let new_comps = [...comps];
                    for (let j = 0; j < comps[id].vars.length; ++j) {
                        new_comps[id].vars[j][k].name = e.target.value;
                    }
                    setter(new_comps);
                    setHasRenderedVariables(false);
                }}></input> = {comps[id].vars[0][k].type === 'Color' ? <input type='color' className='panel-color-input' value={comps[id].vars[0][k].value} style={{
                    width: '20%',
                    marginLeft: '0.5vmax'
                }} onChange={(e) => {
                    let new_comps = [...comps];
                    for (let j = 0; j < comps[id].vars.length; ++j) {
                        new_comps[id].vars[j][k].value = e.target.value;
                    }
                    setter(new_comps);
                    setHasRenderedVariables(false);
                }}></input> :
                            (comps[id].vars[0][k].type === 'Boolean' ? <select className='panel-dropdown' style={{
                                fontSize: '1vmax',
                                width: '20%',
                                marginRight: '1vmax'
                            }} onChange={(e) => {
                                let new_comps = [...comps];
                                for (let j = 0; j < comps[id].vars.length; ++j) {
                                    new_comps[id].vars[j][k].value = e.target.value;
                                }
                                setter(new_comps);
                                setHasRenderedVariables(false);
                            }}
                                    value={comps[id].vars[0][k].value}>
                                <option value='true'>True</option>
                                <option value='false'>False</option>
                            </select> : <input value={comps[id].vars[0][k].value} className='panel-input' style={{
                    width: '20%',
                    marginLeft: '0.5vmax'
                }} onChange={(e) => {
                    let new_comps = [...comps];
                    for (let j = 0; j < comps[id].vars.length; ++j) {
                        new_comps[id].vars[j][k].value = e.target.value;
                    }
                    setter(new_comps);
                    setHasRenderedVariables(false);
                }}></input>)}
                <i className='fa-solid fa-xmark pointy' style={{
                    marginLeft: '2vmax',
                    fontSize: '1.25vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    let new_comps = [...comps];
                    for (let j = 0; j < comps[id].vars.length; ++j) {
                        new_comps[id].vars[j].splice(k, 1);
                    }
                    setter(new_comps);
                    setHasRenderedVariables(false);
                }}></i>
            </div>);
        } setVariablesRender(variables_render);
        setHasRenderedVariables(true);
    }

    //* Column Names Editable
    let columnNamesEditableComponent = <></>;
    if ('columnNamesEditable' in comps[id]) {
        //* Checkbox for toggling editability
        columnNamesEditableComponent = <>
            <p className='panel-label'>Column Names Editable: </p>
            <input type="checkbox" checked={comps[id].columnNamesEditable} onClick={(e) => {
                let new_comps = [...comps];
                new_comps[id].columnNamesEditable = !new_comps[id].columnNamesEditable;
                setter(new_comps);
            }}></input>
            <br /><br />
        </>;
    } else {
        //* If feature is not found, equips element with 'columnNamesEditable' key
        let new_comps = [...comps];
        new_comps[id].columnNamesEditable = false;
        setter(new_comps);
    }

    return (<>
        <br></br>
        <a className='panel-button' onClick={(e) => {
            e.preventDefault();
            setIsShowingScript(true);
        }}>Edit Script</a>
        <a className='panel-button' style={{
            marginLeft: '1vmax'
        }} onClick={(e) => {
            e.preventDefault();
            setIsShowingVariables(true);
        }}>Variables</a>
        <br /><br />
        <p className='panel-label'>Font Size: </p>
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.fontSize = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.fontSize} style={{width: '30%'}} placeholder='Number' />
        <br /><br />
        <p className='panel-label'>Position: </p>
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].position[0] = e.target.value;
            setter(new_comps);
        }} value={comps[id].position[0]} style={{width: '15%'}} placeholder='X' />
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].position[1] = e.target.value;
            setter(new_comps);
        }} value={comps[id].position[1]} style={{width: '15%',
            marginLeft: '0.5vmax'}} placeholder='Y' />
        <br /> <br />
        <p className='panel-label'>Dimensions: </p>
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].size[0] = e.target.value;
            setter(new_comps);
        }} value={comps[id].size[0]} style={{width: '15%'}} placeholder='Width' />
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].size[1] = e.target.value;
            setter(new_comps);
        }} value={comps[id].size[1]} style={{width: '15%',
            marginLeft: '0.5vmax'}} placeholder='Height' />
        <br /> <br />
        {columnNamesEditableComponent}
        <p className='panel-label'>Background Color: </p>
        <input className='panel-input' style={{
            width: '17.5%',
            marginRight: '0.75vmax'
        }} onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.backgroundColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.backgroundColor} />
        <p className='panel-label'>or</p>
        <input type='color' className='panel-color-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.backgroundColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.backgroundColor} />
        <br /> <br />
        <p className='panel-label'>Color: </p>
        <input className='panel-input' style={{
            width: '17.5%',
            marginRight: '0.75vmax'
        }} onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.color = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.color} />
        <p className='panel-label'>or</p>
        <input type='color' className='panel-color-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.color = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.color} />
        <br /> <br />
        <p className='panel-label'>Border Enabled: </p>
        <input type="checkbox" checked={comps[id].props.borderEnabled} onClick={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderEnabled = !new_comps[id].props.borderEnabled;
            setter(new_comps);
        }}></input>
        <br /><br />
        <p className='panel-label'>Border Color: </p>
        <input className='panel-input' style={{
            width: '17.5%',
            marginRight: '0.75vmax'
        }} onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.borderColor} />
        <p className='panel-label'>or</p>
        <input type='color' className='panel-color-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.borderColor} />
        <br /><br />
        <p className='panel-label'>Border Width:</p>
        <input type='range' min='1' max='10' value={comps[id].props.borderWidth} onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderWidth = parseInt(e.target.value);
            setter(new_comps);
        }}></input>
        <br /><br />
        <a className='panel-button' onClick={(e) => {
            e.preventDefault();
            setSel(-1);
        }}>Ok</a>
        <a className='panel-button' onClick={(e) => {
            e.preventDefault();
            let new_comps = [...comps];
            new_comps.splice(id, 1);
            setSel(-1);
            setter(new_comps);
        }} style={{
            marginLeft: '1vmax'
        }}>Delete</a>
        <br /> <br />
        { isShowingScript ? <ScriptView id={id} comps={comps} setComps={setter}
                                        setIsShowingScript={setIsShowingScript}
                                        vars={vars} setVars={setVars} /> : <></> }
        { isShowingVariables ? <Modal width='35' height='45'>
            <div style={{
                width: '100%',
                marginTop: '3.5vh',
                fontSize: '1.5vmax',
                fontWeight: '300'
            }}>Table Variables</div>
            <div style={{
                width: '100%',
                height: '26vh',
                marginTop: '4vh',
                overflow: 'scroll'
            }}>
                { variablesRender }
            </div>
            <div style={{
                width: '100%',
                height: '4vh',
                position: 'absolute',
                bottom: '0',
                background: '#000'
            }}>
                <a href='#' className='panel-button' style={{
                    marginRight: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    let new_comps = [...comps];
                    for (let k = 0; k < new_comps[id].vars.length; ++k) {
                        new_comps[id].vars[k].push({
                            name: "New",
                            type: "Number",
                            value: "0"
                        });
                    } setHasRenderedVariables(false);
                }}>Add Variable</a>
                <a href='#' className='panel-button' onClick={(e) => {
                    e.preventDefault();
                    setIsShowingVariables(false);
                }}>Ok</a>
            </div>
        </Modal> : <></> }
    </>);

}

export function InputTableAdder ({ comps, setComps, setView, page, setSelectedEl }) {

    const [ hovered, setHovered ] = useState(false);

    return (
        <div style={{
            border: '1px #FAF3DD solid',
            display: 'inline-block',
            padding: '1vmax',
            color: hovered ? 'black' : '#FAF3DD',
            backgroundColor: hovered ? '#FAF3DD' : 'black',
            transition: 'background-color 0.1s, color 0.1s',
            marginLeft: '1vmax'
        }} onMouseEnter={() => {
            setHovered(true);
        }} onMouseLeave={() => {
            setHovered(false);
        }} onClick={(e) => {
            e.preventDefault();
            let new_comps = [...comps];
            new_comps.push({
                page: page,
                type: 'intermediate/InputTable',
                name: 'Input Table',
                colData: [ // type is an element in [ Label, Input, Check, Drop, Sign ]
                    { type: "Input", name: "1", width: 1 }, // column width is computed proportionally
                    { type: "Input", name: "2", width: 1 } // e.g. here, the second column's width is 1 / (1 + 1) = 50% of the table width
                    // { type: "Check" }
                    // { type: "Drop", values: ["True", "False"] }
                ],
                columnNamesEditable: false,
                data: [
                    ["", ""],
                    ["", ""]
                ],
                position: [0.475, 0.15 + document.getElementById("wrapper").scrollTop / document.getElementById("wrapper").offsetWidth],
                size: [0.20, 0.1],
                props: {
                    backgroundColor: '#ffffff',
                    color: '#000000',
                    fontSize: 1,
                    borderEnabled: true,
                    borderColor: '#000000',
                    borderWidth: 1
                }, script: [
                    {
                        type: "event/start",
                        children: []
                    },
                    {
                        type: "event/change",
                        children: []
                    },
                    {
                        type: "event/save",
                        children: []
                    }
                ],
                error: [ ["", ""], ["", ""] ], // cell-wise
                warning: [ ["", ""], ["", ""] ], // cell-wise
                disable: [[false, false], [false, false]], // column-wise
                vars: [[], []] // { name: String, type: <Boolean, String, Number, Color>, value: Data }, row-wise
            });
            setComps(new_comps);
            setView('');
            setSelectedEl(comps.length);
        }} className='pointy'>
            <i className="fa-solid fa-table" style={{
                color: hovered ? 'black' : '#FAF3DD'
            }}></i>
            <p className='panel-label' style={{
                color: hovered ? 'black' : '#FAF3DD',
                marginLeft: '1vmax',
                fontSize: '1.15vmax'
            }}>Input Table</p>
        </div>
    );

}