
import { Rnd } from 'react-rnd';
import {
    useState,
    useRef,
    createRef
} from 'react';

import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";

import process_vars from '../../forms/process_vars';
import Modal from '../../views/utils/Modal';

const boxShadowRadius = 15;

export default function Snapshot ({ id, comps, setter, vw, vars, setVars, editable }) {

    const [ isCapturingSnapshot, setIsCapturingSnapshot ] = useState(false);

    let boxShadow = "0px 0px 0px #ff4f42";

    if (comps[id].error) {
        boxShadow =  `0px 0px ${boxShadowRadius}px #ff4f42`;
    } else if (comps[id].hasOwnProperty('warning')) {
        if (comps[id].warning) {
            boxShadow = `0px 0px ${boxShadowRadius}px #d35400`;
        }
    } else if (comps[id].disable) {
        boxShadow = `0px 0px ${boxShadowRadius}px #4e6df5`;
    }

    let innerContent = <></>;

    return (
       <>
        <div>
                <Rnd className='comp' style={{
                    backgroundColor: process_vars(comps[id].props.backgroundColor, vars),
                    textAlign: 'center',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: 'hidden',
                    zIndex: '0',
                    border: process_vars(comps[id].props.borderEnabled, vars) ?
                                `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px',
                    position: 'absolute',
                    boxShadow: boxShadow,
                    transition: 'box-shadow 0.5s'
                }} position={{
                    x: process_vars(comps[id].position[0], vars) * vw,
                    y: process_vars(comps[id].position[1], vars) * vw
                }} size={{
                    width: process_vars(comps[id].size[0], vars) * vw,
                    height: process_vars(comps[id].size[1], vars) * vw
                }} minWidth={window.innerWidth * 0.05} minHeight={window.innerWidth * 0.025}
                aspectRatio={comps[id].aspectRatio[0] / comps[id].aspectRatio[1]}
                disableDragging={true}
                enableResizing={false}
                lockAspectRatio={true}
                onClick={(e) => {
                    e.stopPropagation();
                }}>
                    {/* <img src={comps[id].data} draggable="false" style={{
                        width: '100%',
                        height: '100%'
                    }} onClick={(e) => {
                        e.preventDefault();
                    }}></img> */}
                    <div className='pointy' style={{
                        textAlign: 'center',
                        width: '100%',
                    }} onClick={(e) => {
                        if (editable && !comps[id].disable) {
                            setIsCapturingSnapshot(true);
                        }
                    }}>
                        {comps[id].data === '' ? <><i className='fa-solid fa-camera' style={{
                            color: 'black',
                            fontSize: '1.5vmax',
                        }}></i>
                        <p style={{
                            width: '100%',
                            textAlign: 'center',
                            fontWeight: '300',
                            color: 'black'
                        }}>
                            Capture Snapshot
                        </p></> : <img style={{ width: "100%" }} src={comps[id].data} alt="snapshot" />}
                    </div>
                </Rnd>
            </div>
            {
                isCapturingSnapshot ?
                    <Modal width='50' height='60'>
                        <SnapshotEditor id={id} comps={comps} setter={setter} />
                        <div style={{
                            position: 'absolute',
                            bottom: '0',
                            width: '100%',
                            backgroundColor: '#000000'
                        }}>
                            <a href='' className='panel-button' style={{
                                marginBottom: '1vmax'
                            }} onClick={(e) => {
                                setIsCapturingSnapshot(false);
                                e.preventDefault();
                            }}>Exit</a>
                        </div>
                    </Modal>
                : <></>
            }
        </>
    );

}

function SnapshotEditor({
    id, comps, setter
}) {
    const defaultSrc = comps[id].data === '' ? 'https://picsum.photos/200' : comps[id].data;

    const [image, setImage] = useState(defaultSrc);
    const [cropData, setCropData] = useState("#");
    const cropperRef = createRef();
    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
        files = e.dataTransfer.files;
        } else if (e.target) {
        files = e.target.files;
        }
        const reader = new FileReader();
            reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = (e) => {
        e.preventDefault();
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            let new_comps = [...comps];
            new_comps[id].data = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
            setter(new_comps);
        }
    };

    return (
        <div style={{
            height: '48vh',
            padding: '4vh 2vh 0vh 2vh',
            overflow: 'scroll',
        }}>
        <div style={{ width: "100%" }}>
            <input type="file" onChange={onChange} style={{
            }} />
            <br />
            <br />
            <Cropper
            ref={cropperRef}
            style={{ height: '25vh', width: "100%" }}
            zoomTo={0.5}
            initialAspectRatio={1}
            aspectRatio={comps[id].aspectRatio[0] / comps[id].aspectRatio[1]}
            lockAspectRatio={true}
            preview=".img-preview"
            src={image}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            guides={true}
            />
        </div>
        <a href='' className='panel-button' style={{
            marginTop: '2vmax',
            fontSize: '1vmax',
        }} onClick={getCropData}>
                Save
        </a>
        <div style={{
            marginTop: '3vmax',
            opacity: '0',
        }}>
            <div className="box" style={{
                width: "50%",
                float: "right",
                display: 'inline-block',
                padding: '10px',
                boxSizing: 'border-box',
            }}>
            <h1 style={{
                fontSize: '1.5vmax',
            }}>Preview</h1>
            <div
                className="img-preview"
                style={{
                    width: "100%",
                    float: "left",
                    height: "15vh",
                    overflow: 'hidden',
                }}
            />
            </div>
            <div
            className="box"
            style={{
                width: "50%",
                float: "right",
                height: "15vh",
                display: 'inline-block',
                padding: '10px',
                boxSizing: 'border-box',
            }}
            >
            <h1 style={{
                fontSize: '1.5vmax',
            }}>
                <span>Cropped Image</span>
            </h1>
            <img style={{ width: "100%" }} src={cropData} alt="cropped" />
            </div>
        </div>
        <br style={{ clear: "both" }} />
        </div>
    );
}