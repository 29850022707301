
import { createSlice } from '@reduxjs/toolkit';

export const modeSlice = createSlice({

    name: 'mode',

    initialState: {
        value: ''
    },

    reducers: {

        setMode: (state, action) => {
            state.value = action.payload;
        }

    }

});

export const { setMode } = modeSlice.actions;

export const selectMode = state => state.mode.value;

export default modeSlice.reducer;