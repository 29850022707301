
import { useState } from 'react';

// function relativeIndex(id, comps) {
//     // relativeIndex is the index of this element in relation to other elements on this page
//     // e.g. an element could have the 5th absolute index, but could be the only page on the second page,
//     // so it would have a relativeIndex of 0.

//     let relativeIndex = 0;

//     let currentPage = parseInt(comps[id].page);

//     for (let k = 0; k < comps.length; ++k) {
//         if (parseInt(comps[k].page) == currentPage) {
//             if (k < id) {
//                 relativeIndex++;
//             }
//         }
//     }

//     return relativeIndex;
// }

export default function ListElement ({ id, comps, setComps, sel, setSel, hov, setHov }) {

    return (<div key={id} className='element pointy' onMouseOver={() => {
        setHov(id);
    }} onMouseLeave={() => {
        setHov(-1);
    }} style={{
        backgroundColor: hov === id ? '#FAF3DD' : '#0F1108',
        color: hov === id ? '#0F1108' : '#FAF3DD',
        transition: 'background-color 0.05s, color 0.05s'
    }} onDoubleClick={() => {
        // if (sel === -1) {
            setSel(id);
        // }
    }}>
        <i class="fa-solid fa-caret-right"></i>
        {sel !== id ?
            <p>{ comps[id].name + ' (' + id.toString() + ')' }</p> :
            <>
                <input style={{
                marginLeft: '0.5vmax',
                marginTop: '0.5vmax',
                marginBottom: '0.5vmax'
            }} className='panel-input' onChange={(e) => {
                let new_comps = [...comps];
                new_comps[id].name = e.target.value;
                setComps(new_comps);
            }} value={comps[id].name} />
            <p>{' (' + id.toString() + ')' }</p>
            <i class="fa-solid fa-angles-up" style={{
                fontSize: '1vmax',
                marginLeft: '20%'
            }} onClick={() => {
                let new_comps = [...comps];

                if (id !== 0) {
                    let above = comps[id - 1];
                    new_comps[id - 1] = new_comps[id];
                    new_comps[id] = above;
                    setComps(new_comps);
                    setSel(id - 1);
                }
            }}></i> <i class="fa-solid fa-angles-down" style={{
                fontSize: '1vmax',
                marginLeft: '0%'
            }} onClick={() => {
                let new_comps = [...comps];

                // let numberOfElementsInCurrentPage = 0;
                // comps.forEach(comp => {
                //     if (parseInt(comp.page) == parseInt(comps[id].page)) {
                //         numberOfElementsInCurrentPage++;
                //     }
                // });

                if (id !== comps.length - 1) {
                    let below = comps[id + 1];
                    new_comps[id + 1] = new_comps[id];
                    new_comps[id] = below;
                    setComps(new_comps);
                    setSel(id + 1);
                }
            }}></i></>
        }
    </div>);

}