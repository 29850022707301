
import {
    React,
    useEffect,
    useState
} from 'react';

import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { selectToken } from "../../redux/features/tokenSlice";

import FormIcon from "./FormIcon";
import Modal from "../../views/utils/Modal";
import Header from "../../views/utils/Header";

const axios = require('axios');

export default function Forms () {

    const navigate = useNavigate();

    const token = useSelector(selectToken);
    const [ status, setStatus ] = useState(1); // 0 => Loaded, 1 => Loading, -1 => Error
    const [ forms, setForms ] = useState([]);

    const [ res, setRes ] = useState({
        data: []
    });
    const [ del, setDel ] = useState(false);
    const [ isCopying, setIsCopying ] = useState(false);
    const [ newFormName, setNewFormName ] = useState('');

    const [ current, setCurrent ] = useState(-1);
    const [ idOn, setIdOn ] = useState(false);

    const [ search, setSearch ] = useState('');
    const [ place, setPlace ] = useState(['Root']);
    const [ placeId, setPlaceId ] = useState(['']);

    let place_render = '';
    if (search === '') {
        for (let k = 0; k < place.length; ++k) {
            if (k !== place.length - 1) {
                place_render += place[k] + ' > '
            } else {
                place_render += place[k];
            }
        } place_render = <p style={{
            marginLeft: '4.5vmax',
            display: 'inline',
            fontSize: '1.25vmax',
            paddingRight: '2vmax'
        }}>{place_render}</p>;
    } else {
        place_render = <p style={{
            marginLeft: '4.5vmax',
            display: 'inline',
            fontSize: '1.25vmax',
            paddingRight: '2vmax'
        }}>Search Ready...</p>
    }

    let update_forms = (place) => {
        try {
            if (status === 1) {
                if (search !== '') {
                    axios.get(`${process.env.REACT_APP_DEV_SERVER}` + 'api/forms', {
                        params: {
                            // name: search
                            parent: placeId[placeId.length - 1]
                        },
                        headers: {
                            Authorization: 'Bearing ' + token
                        }
                    })
                    .then((response) => {
                        setRes(response);
                        let forms_render = [];
                        for (let k = 0; k < response.data.length; ++k) {
                            try {
                                if (response.data[k].name.toLowerCase().includes(search)) {
                                    forms_render.push(<FormIcon id={k} current={current} setCurrent={setCurrent}
                                        data={response.data[k]} key={current} idOn={idOn}
                                        search={search} place={place} setPlace={setPlace}
                                        placeId={placeId} setPlaceId={setPlaceId} setStatus={setStatus} />);
                                }
                            } catch (e) {
                                // network error prevents search process from working
                            }
                        } setForms(forms_render);
                        setStatus(0);
                    }).catch((error) => {
                        setStatus(-1);
                    });
                } else {
                    axios.get(`${process.env.REACT_APP_DEV_SERVER}` + 'api/forms', {
                        params: {
                            parent: placeId[placeId.length - 1]
                        },
                        headers: {
                            Authorization: 'Bearing ' + token
                        }
                    })
                    .then((response) => {
                        setRes(response);
                        let forms_render = [];
                        for (let k = 0; k < response.data.length; ++k) {
                            forms_render.push(<FormIcon id={k} current={current} setCurrent={setCurrent}
                                data={response.data[k]} key={k} idOn={idOn} 
                                search={search} place={place} setPlace={setPlace}
                                placeId={placeId} setPlaceId={setPlaceId} setStatus={setStatus} />);
                        } setForms(forms_render);
                        setStatus(0);
                    }).catch((error) => {
                        setStatus(-1);
                    });
                }
            }
        } catch (err) {
            setStatus(-1);
        }
    };

    useEffect(() => {
        update_forms(place);
    }, []);
    
    useEffect(() => {
        update_forms(place);
    }, [status]);

    useEffect(() => {
        if (Object.keys(res).length !== 0) {
            let forms_render = [];
            for (let k = 0; k < res.data.length; ++k) {
                forms_render.push(<FormIcon id={k} current={current} setCurrent={setCurrent}
                    data={res.data[k]} key={k} idOn={idOn} 
                    search={search} place={place} setPlace={setPlace}
                    placeId={placeId} setPlaceId={setPlaceId} setStatus={setStatus} />);
            } setForms(forms_render);
        }
    }, [current]);

    useEffect(() => {
        if (Object.keys(res).length !== 0) {
            let forms_render = [];
            for (let k = 0; k < res.data.length; ++k) {
                forms_render.push(<FormIcon id={k} current={current} setCurrent={setCurrent}
                    data={res.data[k]} key={k} idOn={idOn}
                    search={search} place={place} setPlace={setPlace}
                    placeId={placeId} setPlaceId={setPlaceId} setStatus={setStatus} />);
            } setForms(forms_render);
        }
    }, [idOn]);

    return (
        <div style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100vh',
            backgroundColor: '#0F1108',
            overflow: 'scroll'
        }}>
    
            <Header />

            <div style={{
                color: '#FAF3DD',
                position: 'absolute',
                top: 'calc(4vmax + 12vh + 1pt)',
                width: '100%',
                height: 'calc(100vh - 4vmax - 12vh - 1pt)',
                overflow: 'scroll'
            }}>
                <h1 style={{
                    textAlign: 'center',
                    fontSize: '2.5vmax',
                    fontWeight: '300',
                    marginTop: '1vmax',
                    display: 'block',
                    marginBottom: '3vmax'
                }}>Forms</h1>
                <div style={{
                    border: 'solid 1pt #FAF3DD',
                    height: 'calc(100vh - 4vmax - 12vh - 1pt - 10vmax)',
                    width: '90%',
                    margin: '0 auto'
                }}>
                    <div style={{
                        width: '100%',
                        height: 'calc((100vh - 4vmax - 12vh - 1pt - 10vmax) / 10)',
                        borderBottom: 'solid 1pt #FAF3DD',
                        backgroundColor: '#FAF3DD',
                        color: '#0F1108',
                        display: 'table',
                        tableLayout: 'fixed'
                    }}>
                        <div style={{
                            height: 'calc((100vh - 4vmax - 12vh - 1pt - 10vmax) / 10)',
                            borderRight: 'solid 1pt #0F1108',
                            display: 'table-cell',
                            width: '25%',
                            whiteSpace: 'nowrap',
                            verticalAlign: 'middle',
                            textOverflow: 'ellipsis'
                        }}>
                            <div style={{
                                width: '100%',
                                overflow: 'auto',
                                paddingTop: 'calc((100vh - 4vmax - 12vh - 1pt - 10vmax) / 30)',
                                paddingBottom: 'calc((100vh - 4vmax - 12vh - 1pt - 10vmax) / 30)',
                                display: 'inline-block'
                            }} className='noscroll'>
                                <i className="fa-solid fa-folder-tree" style={{
                                    display: 'inline-block',
                                    fontSize: '1.5vmax',
                                    marginLeft: '0vmax',
                                    position: 'absolute',
                                    backgroundColor: '#FAF3DD',
                                    paddingLeft: '1.5vmax',
                                    paddingRight: '1vmax'
                                }}></i>
                                {place_render}
                            </div>
                        </div>
                        <div style={{
                            width: '30%',
                            borderRight: 'solid 1pt #0F1108',
                            height: 'calc((100vh - 4vmax - 12vh - 1pt - 10vmax) / 10)',
                            display: 'table-cell',
                            verticalAlign: 'middle'
                        }}>
                            <i class="fa-solid fa-arrow-left pointy" style={{
                                fontSize: '1.5vmax',
                                marginLeft: '1.5vmax',
                            }} onClick={() => {
                                if (place.length > 1) {
                                    let new_place = [...place];
                                    new_place.splice(new_place.length - 1, 1);
                                    setPlace(new_place);
                                    let new_placeId = [...placeId];
                                    new_placeId.splice(new_placeId.length - 1, 1);
                                    setPlaceId(new_placeId);
                                    setCurrent(-1);
                                    setStatus(1);
                                }
                            }}></i>
                            <i class="fa-solid fa-arrow-right pointy" style={{
                                fontSize: '1.5vmax',
                                marginLeft: '1vmax',
                            }}></i>
                            <i className="fa-solid fa-arrow-rotate-right pointy" style={{
                                fontSize: '1.5vmax',
                                marginLeft: '2.5vmax',
                            }} onClick={() => {
                                setStatus(1);
                            }}></i>
                            <input className='panel-input' style={{
                                width: '30%',
                                paddingTop: '0.3vmax',
                                paddingBottom: '0.3vmax',
                                backgroundColor: '#FAF3DD',
                                color: '#0F1108',
                                border: 'solid 1pt #0F1108',
                                marginLeft: '2.5vmax'
                            }} value={search} onChange={(e) => {
                                setCurrent(-1);
                                setSearch(e.target.value);
                                if (e.target.value === '') {
                                    setStatus(1);
                                }
                                // setStatus(1);
                            }}></input>
                            <i class="fa-solid fa-magnifying-glass" style={{
                                fontSize: '1vmax',
                                border: 'solid 1pt #0F1108',
                                borderLeft: 'none',
                                padding: '0.3vmax'
                            }} onClick={(e) => {
                                setStatus(1);
                            }}></i>
                            <i class="fa-solid fa-fingerprint panel-icon" style={{
                                border: 'solid 1pt #0F1108',
                                fontSize: '1.25vmax',
                                marginLeft: '1.5vmax',
                                color: idOn ? '#FAF3DD' : '#0F1108',
                                backgroundColor: idOn ? '#0F1108' : '#FAF3DD'
                            }} onClick={() => {
                                setIdOn(!idOn);
                            }}></i>
                        </div>
                        <div style={{
                            width: '45%',
                            height: 'calc((100vh - 4vmax - 12vh - 1pt - 10vmax) / 10)',
                            display: 'table-cell',
                            verticalAlign: 'middle',
                            whiteSpace: 'nowrap'
                        }} className='noscroll'>
                            { current !== -1 ? <><a href='' onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/forms/create', {
                                        state: res.data[current]
                                    });
                                }} className='panel-button' style={{
                                    fontSize: '0.9vmax',
                                    color: '#0F1108',
                                    border: 'solid 1pt #0F1108',
                                    backgroundColor: '#FAF3DD',
                                    marginLeft: '1.25vmax'
                                }}>Edit</a>
                                <a href='' onClick={(e) => {
                                    e.preventDefault();
                                    setDel(true);
                                }} className='panel-button' style={{
                                    fontSize: '0.9vmax',
                                    color: '#0F1108',
                                    border: 'solid 1pt #0F1108',
                                    backgroundColor: '#FAF3DD',
                                    marginLeft: '0.75vmax'
                                }}>Delete</a>
                                <a href='' onClick={(e) => {
                                    e.preventDefault();
                                    axios
                                        .post(`${process.env.REACT_APP_DEV_SERVER}` + 'api/forms', {
                                            name: `${res.data[current].name} (Child)`,
                                            content: res.data[current].content,
                                            pages: res.data[current].pages,
                                            vars: res.data[current].vars,
                                            externals: res.data[current].externals,
                                            parent: res.data[current]._id
                                        }, {
                                            headers: {
                                                Authorization: 'Bearing ' + token
                                            }
                                        })
                                        .then((response) => {
                                            let new_place = [...place];
                                            new_place.push(res.data[current].name);
                                            setPlace(new_place);
                                            let new_placeId = [...placeId];
                                            new_placeId.push(res.data[current]._id);
                                            setPlaceId(new_placeId);
                                            setCurrent(-1);
                                            setStatus(1);
                                        })
                                        .catch((error) => {});
                                }} className='panel-button' style={{
                                    fontSize: '0.9vmax',
                                    color: '#0F1108',
                                    border: 'solid 1pt #0F1108',
                                    backgroundColor: '#FAF3DD',
                                    marginLeft: '0.75vmax'
                                }}>New Child Form</a>
                                <a href='' onClick={(e) => {
                                    e.preventDefault();
                                    setIsCopying(true);
                                }} className='panel-button' style={{
                                    fontSize: '0.9vmax',
                                    color: '#0F1108',
                                    border: 'solid 1pt #0F1108',
                                    backgroundColor: '#FAF3DD',
                                    marginLeft: '0.75vmax'
                                }}>Duplicate</a>
                                <a href='' onClick={(e) => {
                                    e.preventDefault();
                                    setCurrent(-1);
                                }} className='panel-button' style={{
                                    fontSize: '0.9vmax',
                                    color: '#0F1108',
                                    border: 'solid 1pt #0F1108',
                                    backgroundColor: '#FAF3DD',
                                    marginLeft: '0.75vmax'
                                }}>Cancel</a>
                            </> : <>
                                <a href='' onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/forms/create');
                                }} className='panel-button' style={{
                                    fontSize: '0.9vmax',
                                    color: '#0F1108',
                                    border: 'solid 1pt #0F1108',
                                    backgroundColor: '#FAF3DD',
                                    marginLeft: '1.25vmax'
                                }}>Create New Form</a>
                            </> }
                        </div>
                    </div>
                    <div style={{
                        width: '100%',
                        height: 'calc((100vh - 4vmax - 12vh - 1pt - 10vmax) / 10 * 9 - 5pt)',
                        display: 'inline-block',
                        color: '#FAF3DD',
                        overflowY: 'scroll'
                    }} onClick={(e) => {
                        e.stopPropagation();
                        setCurrent(-1);
                    }}>
                        { status === 0 ? <>
                            {forms}
                            <br /><br />
                        </> : <></> }
                        { status === 1 ? <div style={{
                            marginTop: 'calc(((100vh - 4vmax - 12vh - 1pt - 10vmax) / 10 * 9 - 5pt - 3vmax) / 2)',
                            fontSize: '1.5vmax',
                            fontWeight: '300',
                            textAlign: 'center'
                        }}>Loading...</div> : <></> }
                        { status === -1 ? <div style={{
                            marginTop: 'calc(((100vh - 4vmax - 12vh - 1pt - 10vmax) / 10 * 9 - 5pt - 3vmax) / 2)',
                            fontSize: '1.5vmax',
                            fontWeight: '300',
                            textAlign: 'center'
                        }}>Error: Could not load forms</div> : <></> }
                        { res.data.length === 0 && status === 0 ? <div style={{
                            marginTop: 'calc(((100vh - 4vmax - 12vh - 1pt - 10vmax) / 10 * 9 - 5pt - 9vmax) / 2)',
                            fontSize: '1.5vmax',
                            fontWeight: '300',
                            textAlign: 'center'
                        }}>{
                            place_render === '' ? "Empty" : "No Child Forms"
                        }</div> : <></> }
                    </div>
                </div>
            </div>
            { del !== false && current !== -1 ? <Modal width='15' height='15'>
            <p style={{
                fontWeight: '400',
                fontSize: '1.25vmax',
                marginTop: '3vh'
            }}>Confirm Deletion?</p>
            <a href='#' className='panel-button' style={{
                marginRight: '1vmax'
            }} onClick={(e) => {
                e.preventDefault();
                axios.delete(`${process.env.REACT_APP_DEV_SERVER}` + 'api/forms', {
                    headers: {
                        Authorization: 'Bearing ' + token
                    },
                    params: {
                        _id: res.data[current]._id,
                        name: res.data[current].name,
                        pages: res.data[current].pages,
                        vars: res.data[current].vars,
                        externals: res.data[current].externals,
                        parent: res.data[current].parent
                    }
                })
                .then((response) => {
                    setCurrent(-1);
                    setStatus(1);
                    setDel(false);
                }).catch((error) => {
                    console.log(error);
                });
            }}>Yes</a>
            <a href='#' className='panel-button' onClick={(e) => {
                e.preventDefault();
                setDel(false);
            }}>No</a>
        </Modal> : <></> } { isCopying && del === false && current !== -1 ? <Modal width='20' height='20'>
            <p style={{
                fontWeight: '400',
                fontSize: '1.25vmax',
                marginTop: '3vh'
            }}>Duplicate Form?</p>
            <p style={{
                margin: '0',
                padding: '0',
                display: 'inline-block',
                marginBottom: '1.5vmax',
                marginTop: '0.5vmax',
                marginRight: '1vmax',
                fontSize: '1.25vmax'
            }}>New Name: </p><input className='panel-input' placeholder='New Form Name' value={newFormName} onChange={(e) => {
                setNewFormName(e.target.value);
            }}></input>
            <br/>
            <a href='#' className='panel-button' style={{
                marginRight: '1vmax'
            }} onClick={(e) => {
                e.preventDefault();
                if (newFormName !== res.data[current].name && /\S/.test(newFormName)) {
                    axios
                        .post(`${process.env.REACT_APP_DEV_SERVER}` + 'api/forms', {
                            name: newFormName,
                            content: res.data[current].content,
                            pages: res.data[current].pages,
                            vars: res.data[current].vars,
                            externals: res.data[current].externals,
                            parent: res.data[current].parent
                        }, {
                            headers: {
                                Authorization: 'Bearing ' + token
                            }
                        })
                        .then((response) => {
                            setNewFormName('');
                            setIsCopying(false);
                            setCurrent(-1);
                            setStatus(1);
                        })
                        .catch((error) => {});
                }
            }}>Yes</a>
            <a href='#' className='panel-button' onClick={(e) => {
                e.preventDefault();
                setIsCopying(false);
            }}>No</a>
        </Modal> : <></> }
        </div>
    );

}