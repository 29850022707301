
import { Rnd } from 'react-rnd';
import {
    useState,
    useEffect,
    useRef
} from 'react';

import '../component.css';
import process_vars from '../../process_vars';

import ScriptView from '../script/ScriptView';

export default function Input ({ id, comps, setter, vw, gridSize, sel, setSel, hov, setHov, vars, setVars, contextMenu, setContextMenu }) {

    let background_color = '';
    if (hov === id) {
        background_color = '#FAF3DD';
    } else {
        background_color = process_vars(comps[id].props.backgroundColor, vars);
    }

    let innerContent = <input style={{
        color: process_vars(comps[id].props.color, vars),
        backgroundColor: background_color,
        width: '100%',
        height: '100%',
        fontWeight: process_vars(comps[id].props.bold, vars) ? '700' : '400',
        fontStyle: process_vars(comps[id].props.italic, vars) ? 'italic' : '',
        textDecoration: process_vars(comps[id].props.underlined, vars) ? 'underline' : '',
        textAlign: process_vars(comps[id].props.alignment, vars),
        border: 'none'
    }} value={process_vars(comps[id].props.value, vars)}
        placeholder={process_vars(comps[id].props.placeholder, vars)}></input>;

    try {
        if (comps[id].props.hasOwnProperty('textarea')) {
            if (comps[id].props.textarea) {
                innerContent = 
                    <textarea style={{
                        color: process_vars(comps[id].props.color, vars),
                        fontWeight: process_vars(comps[id].props.bold, vars) ? '700' : '400',
                        fontStyle: process_vars(comps[id].props.italic, vars) ? 'italic' : '',
                        textDecoration: process_vars(comps[id].props.underlined, vars) ? 'underline' : '',
                        textAlign: process_vars(comps[id].props.alignment, vars),
                        border: 'none',
                        padding: '1% 1%',
                        marginTop: '0%',
                        height: '100%',
                        width: '100%',
                        resize: 'none',
                        backgroundColor: background_color,
                        outline: 'none',
                        outlineStyle: 'none',
                        boxShadow: 'none',
                        borderColor: 'transparent',
                    }} value={process_vars(comps[id].props.value, vars)} placeholder={process_vars(comps[id].props.placeholder, vars)} onChange={(e) => {
                    }}>
                    </textarea>;
            }
        }
    } catch (e) {
        // do nothing
    }

    return (
        <div>
            <Rnd className='comp' style={{
                textAlign: 'center',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: 'hidden',
                fontSize: process_vars(comps[id].props.fontSize, vars) + 'vmax',
                zIndex: '0',
                border: process_vars(comps[id].props.borderEnabled, vars) ?
                            `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px'
            }} position={{
                x: process_vars(comps[id].position[0], vars) * vw,
                y: process_vars(comps[id].position[1], vars) * vw
            }} size={{
                width: process_vars(comps[id].size[0], vars) * vw,
                height: process_vars(comps[id].size[1], vars) * vw
            }} onDragStop={(e, d) => {
                let new_comps = [...comps];
                new_comps[id].position = [
                    d.x / vw,
                    d.y / vw
                ];
                setter(new_comps);
            }} onResize={(e, direction, ref, delta, position) => {
                let new_comps = [...comps];
                new_comps[id].position = [
                    position.x / vw,
                    position.y / vw
                ];
                new_comps[id].size = [
                    ref.offsetWidth / vw,
                    ref.offsetHeight / vw
                ];
                setter(new_comps);
            }} minWidth={window.innerWidth * 0.05} minHeight={window.innerWidth * 0.025} bounds='.form-view' 
            dragGrid={[
                gridSize,
                gridSize
            ]} resizeGrid={[
                gridSize,
                gridSize
            ]} onMouseEnter={() => {
                setHov(id);
            }} onMouseLeave={() => {
                setHov(-1);
            }} onDoubleClick={() => {
                setSel(id);
            }} onContextMenu={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setContextMenu([true, id, [e.clientX, e.clientY]]);
            }} onClick={(e) => {
                e.stopPropagation();
            }}>{ innerContent }</Rnd>
        </div>
    );

}

export function InputProperties ({ id, comps, setter, setSel, vars, setVars }) {

    const [ isShowingScript, setIsShowingScript ] = useState(false);

    let textareaDefault = false;
    try {
        if (comps[id].props.hasOwnProperty('textarea')) {
            textareaDefault = comps[id].props.textarea;
        }
    } catch (e) {
        // do nothing
    }

    return (<>
        <br></br>
        <a className='panel-button' onClick={(e) => {
            e.preventDefault();
            setIsShowingScript(true);
        }}>Edit Script</a>
        <br /><br />
        <p className='panel-label'>Position: </p>
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].position[0] = e.target.value;
            setter(new_comps);
        }} value={comps[id].position[0]} style={{width: '15%'}} placeholder='X' />
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].position[1] = e.target.value;
            setter(new_comps);
        }} value={comps[id].position[1]} style={{width: '15%',
            marginLeft: '0.5vmax'}} placeholder='Y' />
        <br /> <br />
        <p className='panel-label'>Dimensions: </p>
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].size[0] = e.target.value;
            setter(new_comps);
        }} value={comps[id].size[0]} style={{width: '15%'}} placeholder='Width' />
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].size[1] = e.target.value;
            setter(new_comps);
        }} value={comps[id].size[1]} style={{width: '15%',
            marginLeft: '0.5vmax'}} placeholder='Height' />
        <br /> <br />
        <p className='panel-label'>Has Many Lines: </p>
        <input type="checkbox" checked={textareaDefault} onClick={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.textarea = !new_comps[id].props.textarea;
            setter(new_comps);
        }}></input>
        <br /><br />
        <p className='panel-label'>Initial Value: </p>
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.value = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.value} />
        <br /> <br />
        <p className='panel-label'>Placeholder: </p>
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.placeholder = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.placeholder} />
        <br /> <br />
        <p className='panel-label'>Size: </p>
        <input className='panel-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.fontSize = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.fontSize} />
        <br /> <br />
        <p className='panel-label'>Style: </p>
        <i className='fa-solid fa-bold panel-icon' style={{
            color: comps[id].props.bold ? '#0F1108' : '#FAF3DD',
            backgroundColor: comps[id].props.bold ? '#FAF3DD' : '#0F1108'
        }} onClick={() => {
            let new_comps = [...comps];
            new_comps[id].props.bold = !new_comps[id].props.bold;
            setter(new_comps);
        }}></i>
        <i className='fa-solid fa-italic panel-icon' style={{
            color: comps[id].props.italic ? '#0F1108' : '#FAF3DD',
            backgroundColor: comps[id].props.italic ? '#FAF3DD' : '#0F1108'
        }} onClick={() => {
            let new_comps = [...comps];
            new_comps[id].props.italic = !new_comps[id].props.italic;
            setter(new_comps);
        }}></i>
        <i className='fa-solid fa-underline panel-icon' style={{
            color: comps[id].props.underlined ? '#0F1108' : '#FAF3DD',
            backgroundColor: comps[id].props.underlined ? '#FAF3DD' : '#0F1108'
        }} onClick={() => {
            let new_comps = [...comps];
            new_comps[id].props.underlined = !new_comps[id].props.underlined;
            setter(new_comps);
        }}></i>
        <br /> <br />
        <p className='panel-label'>Alignment: </p>
        <i className='fa-solid fa-align-left panel-icon' style={{
            color: comps[id].props.alignment === 'left' ? '#0F1108' : '#FAF3DD',
            backgroundColor: comps[id].props.alignment === 'left' ? '#FAF3DD' : '#0F1108'
        }} onClick={() => {
            let new_comps = [...comps];
            new_comps[id].props.alignment = 'left';
            setter(new_comps);
        }}></i>
        <i className='fa-solid fa-align-center panel-icon' style={{
            color: comps[id].props.alignment === 'center' ? '#0F1108' : '#FAF3DD',
            backgroundColor: comps[id].props.alignment === 'center' ? '#FAF3DD' : '#0F1108'
        }} onClick={() => {
            let new_comps = [...comps];
            new_comps[id].props.alignment = 'center';
            setter(new_comps);
        }}></i>
        <i className='fa-solid fa-align-right panel-icon' style={{
            color: comps[id].props.alignment === 'right' ? '#0F1108' : '#FAF3DD',
            backgroundColor: comps[id].props.alignment === 'right' ? '#FAF3DD' : '#0F1108'
        }} onClick={() => {
            let new_comps = [...comps];
            new_comps[id].props.alignment = 'right';
            setter(new_comps);
        }}></i>
        <br /> <br />
        <p className='panel-label'>Text Color: </p>
        <input className='panel-input' style={{
            width: '17.5%',
            marginRight: '0.75vmax'
        }} onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.color = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.color} />
        <p className='panel-label'>or</p>
        <input type='color' className='panel-color-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.color = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.color} />
        <br /> <br />
        <p className='panel-label'>Background Color: </p>
        <input className='panel-input' style={{
            width: '17.5%',
            marginRight: '0.75vmax'
        }} onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.backgroundColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.backgroundColor} />
        <p className='panel-label'>or</p>
        <input type='color' className='panel-color-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.backgroundColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.backgroundColor} />
        <br /> <br />
        <p className='panel-label'>Border Enabled: </p>
        <input type="checkbox" checked={comps[id].props.borderEnabled} onClick={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderEnabled = !new_comps[id].props.borderEnabled;
            setter(new_comps);
        }}></input>
        <br /><br />
        <p className='panel-label'>Border Color: </p>
        <input className='panel-input' style={{
            width: '17.5%',
            marginRight: '0.75vmax'
        }} onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.borderColor} />
        <p className='panel-label'>or</p>
        <input type='color' className='panel-color-input' onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderColor = e.target.value;
            setter(new_comps);
        }} value={comps[id].props.borderColor} />
        <br /><br />
        <p className='panel-label'>Border Width:</p>
        <input type='range' min='1' max='10' value={comps[id].props.borderWidth} onChange={(e) => {
            let new_comps = [...comps];
            new_comps[id].props.borderWidth = parseInt(e.target.value);
            setter(new_comps);
        }}></input>
        <br /><br />
        <a className='panel-button' onClick={(e) => {
            e.preventDefault();
            setSel(-1);
        }}>Ok</a>
        <a className='panel-button' onClick={(e) => {
            e.preventDefault();
            let new_comps = [...comps];
            new_comps.splice(id, 1);
            setSel(-1);
            setter(new_comps);
        }} style={{
            marginLeft: '1vmax'
        }}>Delete</a>
        <br /> <br />
        { isShowingScript ? <ScriptView id={id} comps={comps} setComps={setter}
                                        setIsShowingScript={setIsShowingScript}
                                        vars={vars} setVars={setVars} /> : <></> }
    </>);

}

export function InputAdder ({ comps, setComps, setView, page, setSelectedEl }) {

    const [ hovered, setHovered ] = useState(false);

    return (
        <div style={{
            border: '1px #FAF3DD solid',
            display: 'inline-block',
            padding: '1vmax',
            color: hovered ? 'black' : '#FAF3DD',
            backgroundColor: hovered ? '#FAF3DD' : 'black',
            transition: 'background-color 0.1s, color 0.1s',
            marginLeft: '1vmax'
        }} onMouseEnter={() => {
            setHovered(true);
        }} onMouseLeave={() => {
            setHovered(false);
        }} onClick={(e) => {
            e.preventDefault();
            let new_comps = [...comps];
            new_comps.push({
                page: page,
                type: 'primitive/Input',
                name: 'Input',
                position: [0.475, 0.15 + document.getElementById("wrapper").scrollTop / document.getElementById("wrapper").offsetWidth],
                size: [0.10, 0.05],
                props: {
                    value: '',
                    placeholder: '',
                    fontSize: '',
                    bold: false,
                    italic: false,
                    underlined: false,
                    color: '#000',
                    backgroundColor: '#ffffff',
                    alignment: 'center',
                    borderEnabled: true,
                    borderColor: '#000000',
                    borderWidth: 1,
                    textarea: false
                }, script: [
                    {
                        type: "event/start",
                        children: []
                    },
                    {
                        type: "event/change",
                        children: []
                    },
                    {
                        type: "event/save",
                        children: []
                    }
                ],
                error: "",
                warning: "",
                disable: false
            });
            setComps(new_comps);
            setView('');
            setSelectedEl(comps.length);
        }} className='pointy'>
            <i className="fa-solid fa-keyboard" style={{
                color: hovered ? 'black' : '#FAF3DD'
            }}></i>
            <p className='panel-label' style={{
                color: hovered ? 'black' : '#FAF3DD',
                marginLeft: '1vmax',
                fontSize: '1.15vmax'
            }}>Input</p>
        </div>
    );

}