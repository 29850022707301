
import { useState, useEffect } from "react";
import NavBar from "../views/utils/NavBar";
import Modal from "../views/utils/Modal";

import React from 'react';
import { useSelector } from 'react-redux';
import { selectUsername } from "../redux/features/usernameSlice";
import { selectToken } from "../redux/features/tokenSlice";
import { selectMode } from '../redux/features/modeSlice';

import process_theme from "../utils/process_theme";
import Header from "../views/utils/Header";

const axios = require('axios');

export default function Settings () {

    const [ loaded, setLoaded ] = useState(false);

    const [ tab, setTab ] = useState('Profile'); // element of [ 'Profile', 'Preferences', 'Members', 'Log Out' ]
    const username = useSelector(selectUsername);
    const token = useSelector(selectToken);
    const mode = useSelector(selectMode);

    const [ organization, setOrganization ] = useState('Loading...');
    const [ theme, setTheme ] = useState('1');

    const [ group, setGroup ] = useState(-1); // if -1, then no group selected; else, given group selected
    const [ members, setMembers ] = useState([]); // only updated by server

    const [ newGroupModal, setNewGroupModal ] = useState([false, '']); // [ modal open?, new group name ]
    const [ editGroupModal, setEditGroupModal ] = useState([false, '', -1]); // [ modal open?, current group name, current group index ]

    const [ hoveredMember, setHoveredMember ] = useState(-1); // -1 = no hover, any other number = hover at index
    const [ selectedMember, setSelectedMember ] = useState(-1); // -1 = no selection, any other number = selection at index

    const [ numOfRecords, setNumOfRecords ] = useState(-1);

    let colors = process_theme(theme);

    useEffect(() => {
        return () => {
            setLoaded(true);
        };
    }, []);

    if (!loaded) {
        axios.get(`${process.env.REACT_APP_DEV_SERVER}` + 'api/users', {
            headers: {
                Authorization: 'Bearing ' + token
            }
        }).then((response) => {
            setOrganization(response.data[0].organization);
            setTheme(response.data[0].settings.theme);
            setMembers(response.data[0].members);
            setLoaded(true);
            axios.get(`${process.env.REACT_APP_DEV_SERVER}` + 'api/records', {
                headers: {
                    Authorization: 'Bearing ' + token
                },
                query: {
                    creator: response.data[0].id
                }
            }).then((res) => {
                let num_records = 0;
                for (let k = 0; k < res.data.length; ++k) {
                    num_records = num_records + res.data[k].pages.length;
                }
                setNumOfRecords(num_records);
                setLoaded(true);
            }).catch((error) => {
                setNumOfRecords(-2);
                setLoaded(true);
            });
        }).catch((error) => {
            setOrganization('Cannot Connect to Server');
            setLoaded(true);
        });
    }

    let groupView = [];
    for (let k = 0; k < members.length; ++k) {
        groupView.push(<><div className="pointy" style={{
            width: '100%',
            borderBottom: 'solid 0.75pt',
            borderColor: colors[1],
            overflowY: 'hidden',
            textAlign: 'center',
            backgroundColor: group === k ? colors[1] : colors[0],
            color: group === k ? colors[0] : colors[1],
            transition: 'background-color 0.2s, color 0.2s',
            position: 'relative'
        }} onClick={(e) => {
            e.stopPropagation();
            setGroup(k);
        }}>
            <h2 style={{
                fontWeight: group === k ? '500' : '300',
                fontSize: '1vmax',
                display: 'inline-block'
            }}>{ members[k].name }</h2>
            <i className="fa-solid fa-ellipsis" style={{
                position: 'absolute',
                right: '1vmax',
                top: '35%',
                fontSize: '1vmax',
                height: '100%',
                color: group === k ? colors[0] : colors[1]
            }} onClick={(e) => {
                e.stopPropagation();
                setEditGroupModal([true, members[k].name, k]);
            }}></i>
        </div>
        </>);
    }

    let memberView = <></>;
    if (group === -1) {
        memberView = <div style={{
            width: '100%',
            height: '100%',
            position: 'relative'
        }}>
            <h1 style={{
                fontWeight: '300',
                fontSize: '1.25vmax',
                marginTop: '0',
                position: 'absolute',
                top: 'calc(50% - 1.5vmax)',
                left: 'calc(42% - 2vmax)'
            }}>Select A Group To View</h1>
        </div>
    } else {
        let memberViewItems = [];
        for (let k = 0; k < members[group].members.length; ++k) {
            memberViewItems.push(<>
                <div className='pointy' style={{
                    width: '90%',
                    margin: '0 auto',
                    border: '0.75pt solid',
                    borderColor: colors[1],
                    marginTop: k === 0 ? '1.5vmax' : '0vmax',
                    // padding: '1vmax',
                    backgroundColor: hoveredMember === k ? colors[1] : colors[0],
                    color: hoveredMember === k ? colors[0] : colors[1],
                    transition: 'background-color 0.2s, color 0.2s, font-weight 1s',
                    position: 'relative'
                }} onMouseEnter={(e) => {
                    setHoveredMember(k);
                }} onMouseLeave={(e) => {
                    setHoveredMember(-1);
                }} onClick={(e) => {
                    if (selectedMember === -1)
                        setSelectedMember(k);
                }}>
                    <i className="fa-solid fa-user" style={{
                        fontSize: '1vmax',
                        // margin: '0',
                        marginLeft: '2%',
                        padding: '1vmax',
                        paddingBottom: '0.5vmax',
                        display: 'inline-block'
                    }}></i>
                    <span style={{
                        fontSize: '1vmax',
                        margin: '0 0 0 1vmax',
                        width: '30%',
                        textAlign: 'center',
                        fontWeight: hoveredMember === k ? '500' : '300',
                    }}>{members[group].members[k].name}</span>
                    <br />
                    <i className={"fa-solid" + " fa-" + (members[group].members[k].passcode === -1 ? 'un' : '') + "lock"} style={{
                       fontSize: '1vmax',
                       // margin: '0',
                       marginLeft: '2%',
                       padding: '1vmax',
                       paddingTop: '0',
                       display: 'inline-block'
                    }}></i>
                    <span style={{
                        fontSize: '1vmax',
                        margin: '0 0 0 1vmax',
                        width: '30%',
                        textAlign: 'center',
                        fontWeight: hoveredMember === k ? '500' : '300',
                    }}>{members[group].members[k].passcode === -1 ? 'No Passcode' : members[group].members[k].passcode}</span>
                </div>
            </>);
        } memberView = <>
            {memberViewItems}
            <div className='pointy' style={{
                fontWeight: '300',
                fontSize: '1vmax',
                width: '20%',
                textAlign: 'center',
                margin: '0 auto',
                border: '0.75pt solid',
                borderColor: colors[1],
                padding: '1vmax 0.5vmax',
                marginTop: '2vmax',
                marginBottom: '2vmax'
            }} onClick={(e) => {
                let new_members = [...members];
                new_members[group].members.push({
                    name: 'New ' + members[group].name + ' Member',
                    passcode: -1
                });
                axios.patch(`${process.env.REACT_APP_DEV_SERVER}` + 'api/users', {
                    query: {
                        username: username
                    },
                    params: {
                        "members": new_members
                    } }, {
                    headers: {
                        Authorization: 'Bearing ' + token
                    }
                }).then((res) => {
                    setLoaded(false);
                }).catch((e) => console.error(e));
            }}>Add Member</div>
        </>;
    }

    let page = <></>;
    if (tab == 'Profile') {
        page = <>
            <h2 style={{
                color: colors[1],
                fontSize: '1.5vmax',
                fontWeight: '300',
                marginTop: '3vmax'
            }}>Profile</h2>
            <p style={{
                color: colors[1],
                fontSize: '1.15vmax',
                fontWeight: '300',
                marginTop: '3vmax'
            }}>
                Username: <span style={{ fontWeight: '500' }}>{username}</span>
            </p>
            <p style={{
                color: colors[1],
                fontSize: '1.15vmax',
                fontWeight: '300',
                marginTop: '1.5vmax'
            }}>
                Organization: <span style={{ fontWeight: '500' }}>{organization}</span>
            </p>
            <p style={{
                color: colors[1],
                fontSize: '1.15vmax',
                fontWeight: '300',
                marginTop: '1.5vmax'
            }}>
                Paper Saved: <span style={{ fontWeight: '500' }}>{(
                    numOfRecords === -1 ? "Loading..." : (
                        numOfRecords === -2 ? "Connection Error" : `${numOfRecords} pages`
                    )
                )}</span>
            </p>
        </>;
    } else if (tab == 'Preferences' && mode === "admin") {
        page = <>
            <h2 style={{
                color: colors[1],
                fontSize: '1.5vmax',
                fontWeight: '300',
                marginTop: '3vmax'
            }}>Preferences</h2>
            <p style={{
                color: colors[1],
                fontSize: '1.15vmax',
                fontWeight: '300',
                marginTop: '3vmax',
                display: 'inline-block'
            }}>
                Theme (experimental):
            </p>
            <a className={theme === '1' ? 'panel-button' : 'panel-button-two'} style={{
                display: 'inline-block',
                marginLeft: '1vmax',
                backgroundColor: theme === '1' ? colors[1] : colors[0],
                color: theme === '1' ? colors[0] : colors[1]
            }} onClick={(e) => {
                e.preventDefault();
                axios.patch(`${process.env.REACT_APP_DEV_SERVER}` + 'api/users', {
                    query: {
                        username: username
                    },
                    params: {
                        "settings.theme": "1"
                    } }, {
                    headers: {
                        Authorization: 'Bearing ' + token
                    }
                }).then((response) => {
                    setLoaded(false);
                }).catch((error) => {});
            }}>Dark</a>
            <a className={theme === '1' ? 'panel-button' : 'panel-button-two'} style={{
                display: 'inline-block',
                marginLeft: '1vmax',
                backgroundColor: theme === '2' ? colors[1] : colors[0],
                color: theme === '2' ? colors[0] : colors[1]
            }} onClick={(e) => {
                e.preventDefault();
                axios.patch(`${process.env.REACT_APP_DEV_SERVER}` + 'api/users', {
                    query: {
                        username: username
                    },
                    params: {
                        "settings.theme": "2"
                    } }, {
                    headers: {
                        Authorization: 'Bearing ' + token
                    }
                }).then((response) => {
                    setLoaded(false);
                }).catch((error) => {});
            }}>Light</a>
        </>;
    } else if (tab == 'Members' && mode === "admin") {
        page = <>
            <h2 style={{
                color: colors[1],
                fontSize: '1.5vmax',
                fontWeight: '300',
                marginTop: '2vmax'
            }}>Members</h2>
            <div style={{
                border: 'solid 0.75pt',
                borderColor: colors[1],
                width: '95%',
                height: '80%',
                marginLeft: '2.5%',
                marginTop: '2.5%',
                textAlign: 'left',
                position: 'relative'
            }}>
                <div style={{
                    height: '90%',
                    width: '33%',
                    borderRight: 'solid 0.75pt',
                    borderColor: colors[1],
                    display: 'inline-block',
                    verticalAlign: 'top',
                    overflowY: 'scroll'
                }} onClick={(e) => {
                    setGroup(-1);
                }}>
                    { groupView }
                </div>
                <div className="pointy" style={{
                    display: 'inline-block',
                    width: '33%',
                    borderTop: 'solid 0.75pt',
                    borderRight: 'solid 0.75pt',
                    borderColor: colors[1],
                    overflowY: 'scroll',
                    textAlign: 'center',
                    position: 'absolute',
                    backgroundColor: colors[0],
                    left: '0',
                    bottom: '0',
                    height: '10%'
                }} onClick={(e) => {
                    e.stopPropagation();
                    setNewGroupModal([true, '']);
                }}>
                    <h2 style={{
                        fontWeight: '300',
                        fontSize: '1vmax'
                    }}>Add Group</h2>
                </div>
                <div style={{
                    height: '100%',
                    width: 'calc(67% - 1pt)',
                    display: 'inline-block',
                    verticalAlign: 'top',
                    overflowY: 'scroll'
                }}>
                    { memberView }
                </div>
            </div>
            { newGroupModal[0] && !editGroupModal[0] ? <Modal width="20" height="20">
                <h3 style={{
                    fontWeight: '300',
                    fontSize: '1.15vmax',
                    marginTop: '2vmax'
                }}>Create New Group</h3>
                <span style={{
                    fontWeight: '300',
                    fontSize: '1vmax',
                    marginRight: '0.5vmax'
                }}>Group Name: </span>
                <input className='panel-input' value={newGroupModal[1]} onChange={(e) => {
                    setNewGroupModal([true, e.target.value]);
                }}></input>
                <br/>
                <a href='' className='panel-button' style={{
                    fontSize: '1vmax',
                    marginTop: '1.5vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    setNewGroupModal([false, '']);
                }}>Exit</a>
                <a href='' className='panel-button' style={{
                    fontSize: '1vmax',
                    marginTop: '1.5vmax',
                    marginLeft: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    axios.patch(`${process.env.REACT_APP_DEV_SERVER}` + 'api/users', {
                        query: {
                            username: username
                        },
                        params: {
                            "members": [...members, {
                                name: newGroupModal[1],
                                members: []
                            }]
                        } }, {
                        headers: {
                            Authorization: 'Bearing ' + token
                        }
                    }).then((res) => {
                        setLoaded(false);
                        setNewGroupModal([false, '']);
                    }).catch((e) => console.error(e))
                }}>Save</a>
            </Modal> : <></> }
            { editGroupModal[0] && !newGroupModal[0] ? <Modal width="20" height="20">
                <h3 style={{
                    fontWeight: '300',
                    fontSize: '1.15vmax',
                    marginTop: '2vmax'
                }}>Edit Group</h3>
                <span style={{
                    fontWeight: '300',
                    fontSize: '1vmax',
                    marginRight: '0.5vmax'
                }}>Group Name: </span>
                <input className='panel-input' value={editGroupModal[1]} onChange={(e) => {
                    setEditGroupModal([true, e.target.value, editGroupModal[2]]);
                }}></input>
                <br/>
                <a href='' className='panel-button' style={{
                    fontSize: '1vmax',
                    marginTop: '1.5vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    setEditGroupModal([false, '', -1]);
                }}>Exit</a>
                <a href='' className='panel-button' style={{
                    fontSize: '1vmax',
                    marginTop: '1.5vmax',
                    marginLeft: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    let new_members = [...members];
                    new_members[editGroupModal[2]].name = editGroupModal[1];
                    axios.patch(`${process.env.REACT_APP_DEV_SERVER}` + 'api/users', {
                        query: {
                            username: username
                        },
                        params: {
                            "members": new_members
                        } }, {
                        headers: {
                            Authorization: 'Bearing ' + token
                        }
                    }).then((res) => {
                        setLoaded(false);
                        setEditGroupModal([false, '', -1]);
                    }).catch((e) => console.error(e))
                }}>Save</a>
                <a href='' className='panel-button' style={{
                    fontSize: '1vmax',
                    marginTop: '1.5vmax',
                    marginLeft: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    let new_members = [...members];
                    new_members.splice(editGroupModal[2], 1);
                    axios.patch(`${process.env.REACT_APP_DEV_SERVER}` + 'api/users', {
                        query: {
                            username: username
                        },
                        params: {
                            "members": new_members
                        } }, {
                        headers: {
                            Authorization: 'Bearing ' + token
                        }
                    }).then((res) => {
                        setLoaded(false);
                        setEditGroupModal([false, '', -1]);
                        setGroup(-1);
                    }).catch((e) => console.error(e))
                }}>Delete</a>
            </Modal> : <></> }
            { selectedMember !== -1 ? <Modal width="30" height={members[group].members[selectedMember].passcode !== -1 ? "26.5" : "21.5"}>
                <h3 style={{
                    fontWeight: '300',
                    fontSize: '1.15vmax',
                    marginTop: '2vmax'
                }}>Edit Member</h3>
                <span style={{
                    fontWeight: '300',
                    fontSize: '1vmax',
                    marginRight: '0.5vmax'
                }}>Name: </span>
                <input className='panel-input' value={members[group].members[selectedMember].name} onChange={(e) => {
                    let new_members = [...members];
                    new_members[group].members[selectedMember].name = e.target.value;
                    setMembers(new_members);
                }}></input>
                { members[group].members[selectedMember].passcode !== -1 ? <><br/>
                <span style={{
                    fontWeight: '300',
                    fontSize: '1vmax',
                    marginRight: '0.5vmax',
                    marginTop: '1.25vmax',
                    display: 'inline-block'
                }}>Passcode: </span>
                <input className='panel-input' value={members[group].members[selectedMember].passcode} onChange={(e) => {
                    let new_members = [...members];
                    new_members[group].members[selectedMember].passcode = e.target.value;
                    setMembers(new_members);
                }}></input></> : <></> }
                <br/>
                <a href='' className='panel-button' style={{
                    fontSize: '1vmax',
                    marginTop: '1.5vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    setLoaded(false);
                    setSelectedMember(-1);
                }}>Exit</a>
                <a href='' className='panel-button' style={{
                    fontSize: '1vmax',
                    marginTop: '1.5vmax',
                    marginLeft: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    if (members[group].members[selectedMember].passcode === -1) {
                        let new_members = [...members];
                        new_members[group].members[selectedMember].passcode = '';
                        setMembers(new_members);
                    } else {
                        let new_members = [...members];
                        new_members[group].members[selectedMember].passcode = -1;
                        setMembers(new_members);
                    }
                }}>Toggle Passcode</a>
                <a href='' className='panel-button' style={{
                    fontSize: '1vmax',
                    marginTop: '1.5vmax',
                    marginLeft: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    axios.patch(`${process.env.REACT_APP_DEV_SERVER}` + 'api/users', {
                        query: {
                            username: username
                        },
                        params: {
                            "members": members
                        } }, {
                        headers: {
                            Authorization: 'Bearing ' + token
                        }
                    }).then((res) => {
                        setLoaded(false);
                        setSelectedMember(-1);
                    }).catch((e) => console.error(e));
                }}>Save</a>
                <a href='' className='panel-button' style={{
                    fontSize: '1vmax',
                    marginTop: '1.5vmax',
                    marginLeft: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    let new_members = [...members];
                    new_members[group].members.splice(selectedMember, 1);
                    axios.patch(`${process.env.REACT_APP_DEV_SERVER}` + 'api/users', {
                        query: {
                            username: username
                        },
                        params: {
                            "members": new_members
                        } }, {
                        headers: {
                            Authorization: 'Bearing ' + token
                        }
                    }).then((res) => {
                        setLoaded(false);
                        setSelectedMember(-1);
                    }).catch((e) => console.error(e))
                }}>Delete</a>
            </Modal> : <></> }
        </>;
    }

    return (<div style={{
        backgroundColor: colors[0],
        width: '100%',
        height: '100vh',
        position: 'absolute',
        top: '0',
        left: '0'
    }}>
        <Header />
        <div style={{
            marginTop: '25vh',
            color: colors[1],
            textAlign: 'center'
        }}>
            <div style={{
                color: colors[1],
                position: 'absolute',
                top: 'calc(4vmax + 12vh + 1pt)',
                width: '100%',
                height: 'calc(100vh - 4vmax - 12vh - 1pt)',
                overflow: 'scroll'
            }}>
                <h1 style={{
                    textAlign: 'center',
                    fontSize: '2.5vmax',
                    fontWeight: '300',
                    marginTop: '1vmax',
                    display: 'block',
                    marginBottom: '3vmax'
                }}>Settings</h1>
                <div style={{
                    marginLeft: '2.5vw',
                    width: '95vw',
                    height: '80%',
                    border: 'solid 1pt',
                    borderColor: colors[1]
                }}>
                    <div style={{
                        height: '100%',
                        width: '20%',
                        borderRight: 'solid 1pt',
                        borderColor: colors[1],
                        display: 'inline-block',
                        verticalAlign: 'top'
                    }}>
                        <div className="pointy comp" style={{
                            display: 'block',
                            width: '100%',
                            borderBottom: 'solid 1pt',
                            borderColor: colors[1],
                            fontWeight: tab != 'Profile' ? '300' : '500',
                            fontSize: '1.25vmax',
                            paddingTop: '5%',
                            paddingBottom: '5%',
                            backgroundColor: tab != 'Profile' ? colors[0] : colors[1],
                            color: tab != 'Profile' ? colors[1] : colors[0]
                        }} onClick={(e) => {
                            e.stopPropagation();
                            setTab('Profile');
                        }}>
                            Profile
                        </div>
                        {mode === "admin" ? <div className="pointy comp" style={{
                            display: 'block',
                            width: '100%',
                            borderBottom: 'solid 1pt',
                            borderColor: colors[1],
                            fontWeight: tab != 'Preferences' ? '300' : '500',
                            fontSize: '1.25vmax',
                            paddingTop: '5%',
                            paddingBottom: '5%',
                            backgroundColor: tab != 'Preferences' ? colors[0] : colors[1],
                            color: tab != 'Preferences' ? colors[1] : colors[0]
                        }} onClick={(e) => {
                            e.stopPropagation();
                            setTab('Preferences');
                        }}>
                            Preferences
                        </div> : <></>}
                        {mode === "admin" ? <div className="pointy comp" style={{
                            display: 'block',
                            width: '100%',
                            borderBottom: 'solid 1pt',
                            borderColor: colors[1],
                            fontWeight: tab != 'Members' ? '300' : '500',
                            fontSize: '1.25vmax',
                            paddingTop: '5%',
                            paddingBottom: '5%',
                            backgroundColor: tab != 'Members' ? colors[0] : colors[1],
                            color: tab != 'Members' ? colors[1] : colors[0]
                        }} onClick={(e) => {
                            e.stopPropagation();
                            setTab('Members');
                        }}>
                            Members
                        </div> : <></>}
                        <div className="pointy comp" style={{
                            display: 'inline-block',
                            width: '100%',
                            borderBottom: 'solid 1pt',
                            borderColor: colors[1],
                            fontWeight: '300',
                            fontSize: '1.25vmax',
                            paddingTop: '5%',
                            paddingBottom: '5%',
                            backgroundColor: colors[0],
                            color: colors[1]
                        }} onClick={(e) => {
                            e.stopPropagation();
                            window.location.reload();
                            window.location.href = '/';
                        }}>
                            Log Out
                        </div>
                    </div>
                    <div style={{
                        height: '100%',
                        width: 'calc(80% - 1pt)',
                        display: 'inline-block',
                        verticalAlign: 'top'
                    }}>
                        {page}
                    </div>
                </div>
            </div>
        </div>
    </div>);

}