
import { useState, useEffect, useRef } from 'react';

import NavBarLink from './NavBarLink';

import { useSelector } from 'react-redux';
import { selectMode } from '../../redux/features/modeSlice';

export default function NavBar ({}) {

    const mode = useSelector(selectMode);

    const [ open, setOpen ] = useState(false);
    const [ hovered, setHovered ] = useState(false);

    function useOutsideAlerter(ref) {
        useEffect(() => {
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
              setOpen(false);
            }
          }
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
    }

    const navRef = useRef(null);
    useOutsideAlerter(navRef);

    if (!open) {
        return (
            <i class="fa-solid fa-bars" style={{
                color: '#fff',
                position: 'fixed',
                top: '8vh',
                fontSize: '3vh',
                right: '8vh',
                cursor: hovered ? 'pointer' : 'auto',
                zIndex: '5'
            }} onClick={(e) => {
                setOpen(true);
            }}  onMouseEnter={(e) => {
                setHovered(true);
            }} onMouseLeave={(e) => {
                setHovered(false);
            }}></i>
        );
    } else {
        return (
            <div  ref={navRef}>
                <i class="fa-solid fa-xmark" style={{
                    color: '#fff',
                    position: 'fixed',
                    top: '8vh',
                    fontSize: '3vh',
                    right: '8vh',
                    cursor: hovered ? 'pointer' : 'auto',
                    zIndex: '5'
                }} onClick={(e) => {
                    setOpen(false);
                }}  onMouseEnter={(e) => {
                    setHovered(true);
                }} onMouseLeave={(e) => {
                    setHovered(false);
                }}></i>
                <div style={{
                    position: 'fixed',
                    top: '12vh',
                    right: '8vh',
                    backgroundColor: '#0F1108',
                    width: '15vmax',
                    border: 'solid 1.5pt #FAF3DD',
                    borderBottomWidth: '0pt',
                    textAlign: 'center',
                    zIndex: '5'
                }}>
                    <NavBarLink to='/'>Home</NavBarLink>
                    {mode === "admin" ? <NavBarLink to='/forms'>Forms</NavBarLink> : <></>}
                    {mode === "admin" ? <NavBarLink to='/forms/create'>New Form</NavBarLink> : <></> }
                    <NavBarLink to='/records'>Records</NavBarLink>
                    {/* <NavBarLink to='/records'>New Record</NavBarLink> */}
                    <NavBarLink to='/settings'>Settings</NavBarLink>
                    <NavBarLink to='/help'>Help</NavBarLink>
                </div>
            </div>
        );
    }

}