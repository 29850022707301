
// Dashboard.js
// Component for viewing all form interactions

import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUsername } from '../../redux/features/usernameSlice';
import { selectMode } from '../../redux/features/modeSlice';

import Header from '../utils/Header';

export default function Dashboard () {

    const username = useSelector(selectUsername);
    const mode = useSelector(selectMode);
    const navigate = useNavigate();

    return (
        <div style={{
            backgroundColor: '#0F1108',
            width: '100%',
            height: '100vh',
            position: 'absolute',
            top: '0',
            left: '0'
        }}>
            <Header />
            <div style={{
                marginTop: '25vh',
                color: '#FAF3DD',
                fontFamily: '\"Playfair Display\", serif',
                fontSize: '4vmax',
                textAlign: 'center'
            }}>
                Welcome, <span style={{
                    // color: '#E6AACE',
                    fontFamily: '\"Playfair Display\", serif',
                    background: "-webkit-linear-gradient(#e6aace, #e096cf)",
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                }}>{username}</span>.
            </div>
            <div style={{
                width: '70%',
                marginLeft: '15%',
                height: '50%',
                marginTop: '5%',
                display: 'grid',
                gridTemplateRows: '1fr',
                gridTemplateColumns: mode === "admin" ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)',
                gridTemplateAreas: mode === "admin" ? '\"a b c\"' : '\"a b\"',
                alignItems: 'stretch'
            }}>
               {mode === "admin" ? <div style={{
                    border: '1pt solid #faf3dd',
                    gridArea: 'a',
                    display: 'inline-block',
                    textAlign: 'center'
                }} className='panel-button' onClick={(e) => {
                    e.preventDefault();
                    navigate('/forms', {});
                }}>
                    <i className='fa-solid fa-scroll' style={{
                        fontSize: '1.5vmax',
                        marginTop: '18vh'
                    }}></i>
                    <h2 style={{
                        fontWeight: '300',
                        fontSize: '1.5vmax'
                    }}>Forms</h2>
                </div> : <></>}
                {mode === "admin" ? <div style={{
                    border: '1pt solid #faf3dd',
                    borderLeft: '0pt',
                    gridArea: 'b',
                    display: 'inline-block',
                    textAlign: 'center'
                }} className='panel-button' onClick={(e) => {
                    e.preventDefault();
                    navigate('/records', {});
                }}>
                    <i className='fa-solid fa-clipboard' style={{
                        fontSize: '1.5vmax',
                        marginTop: '18vh'
                    }}></i>
                    <h2 style={{
                        fontWeight: '300',
                        fontSize: '1.5vmax'
                    }}>Records</h2>
                </div> : <div style={{
                    border: '1pt solid #faf3dd',
                    borderLeft: '1pt solid #faf3dd',
                    gridArea: 'a',
                    display: 'inline-block',
                    textAlign: 'center'
                }} className='panel-button' onClick={(e) => {
                    e.preventDefault();
                    navigate('/records', {});
                }}>
                    <i className='fa-solid fa-clipboard' style={{
                        fontSize: '1.5vmax',
                        marginTop: '18vh'
                    }}></i>
                    <h2 style={{
                        fontWeight: '300',
                        fontSize: '1.5vmax'
                    }}>Records</h2>
                </div>}
                {mode === "admin" ? <div style={{
                    border: '1pt solid #faf3dd',
                    borderLeft: '0pt',
                    gridArea: 'c',
                    display: 'inline-block',
                    textAlign: 'center'
                }} className='panel-button' onClick={(e) => {
                    e.preventDefault();
                    navigate('/settings', {});
                }}>
                    <i className='fa-solid fa-gears' style={{
                        fontSize: '1.5vmax',
                        marginTop: '18vh'
                    }}></i>
                    <h2 style={{
                        fontWeight: '300',
                        fontSize: '1.5vmax'
                    }}>Settings</h2>
                </div> : <div style={{
                    border: '1pt solid #faf3dd',
                    borderLeft: '0pt',
                    gridArea: 'b',
                    display: 'inline-block',
                    textAlign: 'center'
                }} className='panel-button' onClick={(e) => {
                    e.preventDefault();
                    navigate('/settings', {});
                }}>
                    <i className='fa-solid fa-gears' style={{
                        fontSize: '1.5vmax',
                        marginTop: '18vh'
                    }}></i>
                    <h2 style={{
                        fontWeight: '300',
                        fontSize: '1.5vmax'
                    }}>Settings</h2>
                </div>}
            </div>
        </div>
    );

}