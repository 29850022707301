
// Home.js
// Hosts the components for logging in and viewing/manipulating forms (dashboard)

// standard
import { useSelector } from 'react-redux';
import { selectToken } from '../../redux/features/tokenSlice';

// stylesheets
import './normalize.css';
import './index.css';

// components
import LogIn from './LogIn';
import Dashboard from './Dashboard';

export default function Home () {

    const token = useSelector(selectToken);

    return (
        <>
            { token === "" ? <LogIn /> : <Dashboard /> }
        </>
    );

}