
import { Rnd } from 'react-rnd';
import { useState, useEffect } from 'react';

import process_vars from '../../forms/process_vars';
import execute_script from './execute_script';

const math = require('mathjs');

// processes label's text, performs relevant arithmetic, and replaces variable values
// (String text, Map vars) -> String parsedText
function renderLabelText(text, vars) {
    let parsedText = text;

    let mathRegex = /\[(.*?)\](\d*)f(\d*)/g;
    let matchResults = [...parsedText.matchAll(mathRegex)];
    matchResults.forEach(result => {
        let mathExp = process_vars(result[1], vars);
        let evaluatedExp = 0;
        try {
            evaluatedExp = math.evaluate(mathExp).toString();
            if (result[3] != null) {
                evaluatedExp = parseFloat(evaluatedExp).toFixed(parseInt(result[3]));
            }
        } catch (e) {
            // do nothing
        }
        parsedText = parsedText.replace(result[0], evaluatedExp);
    });

    parsedText = process_vars(parsedText, vars);
    return parsedText;
}

export default function Label ({ id, comps, setter, vw, vars, setVars, editable }) {

    let boxShadow = "0px 0px 0px #ff4f42";
    if (comps[id].error) {
        boxShadow =  '0px 0px 50px #ff4f42';
    } else if (comps[id].hasOwnProperty('warning')) {
        if (comps[id].warning) {
            boxShadow = `0px 0px 50px #d35400`;
        }
    } else if (comps[id].disable) {
        boxShadow = '0px 0px 50px #4e6df5';
    }

    return (
        <div>
            <Rnd className='comp' style={{
                color: process_vars(comps[id].props.color, vars),
                backgroundColor: process_vars(comps[id].props.backgroundColor, vars),
                textAlign: 'center',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: 'hidden',
                fontSize: process_vars(comps[id].props.fontSize, vars) + 'vmax',
                fontWeight: process_vars(comps[id].props.bold, vars) ? '700' : '400',
                fontStyle: process_vars(comps[id].props.italic, vars) ? 'italic' : '',
                textDecoration: process_vars(comps[id].props.underlined, vars) ? 'underline' : '',
                zIndex: '0',
                border: process_vars(comps[id].props.borderEnabled, vars) ?
                            `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px',
                position: 'absolute',
                boxShadow: boxShadow,
                transition: 'box-shadow 0.5s'
            }} position={{
                x: process_vars(comps[id].position[0], vars) * vw,
                y: process_vars(comps[id].position[1], vars) * vw
            }} size={{
                width: process_vars(comps[id].size[0], vars) * vw,
                height: process_vars(comps[id].size[1], vars) * vw
            }} enableResizing={false} disableDragging={true}><div style={{
                textAlign: process_vars(comps[id].props.alignment, vars),
                width: '98%'
            }}>{renderLabelText(comps[id].props.text, vars)}</div></Rnd>
        </div>
    );

}