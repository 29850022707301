
import { useState } from "react";
import NavBar from "./NavBar";

import { useNavigate } from 'react-router-dom';

export default function Header () {

    let navigate = useNavigate();

    return <><div style={{
        zIndex: '5',
        position: 'fixed'
    }} id="header">
    
        <div style={{
            height: 'calc(4vmax + 12vh)',
            width: '100%',
            position: 'fixed',
            top: '0',
            left: '0',
            backgroundColor: '#0F1108',
            zIndex: '5',
            display: 'block'
        }}>
            <div className='logo' style={{
                // background: 'red'
            }}>
                <div className='pointy' style={{
                    // background: 'red',
                    width: '6vmax',
                    margin: '0 auto'
                }} onClick={() => {
                    navigate('/');
                }}>
                    <img src="/logo_black.svg" type="image/svg+xml"></img>
                </div>
            </div>
            <i className="fa-solid fa-arrow-left pointy" style={{
                color: '#fff',
                position: 'fixed',
                top: '8vh',
                fontSize: '1.5vmax',
                left: '7.5vw',
            }} onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                navigate(-1);
            }}></i>
        </div>
        <NavBar />

    </div></>;

}