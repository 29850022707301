
import { configureStore } from '@reduxjs/toolkit';

import tokenReducer from './features/tokenSlice';
import usernameReducer from './features/usernameSlice';
import idReducer from './features/idSlice';
import modeReducer from './features/modeSlice';

export default configureStore({
    reducer: {
        token: tokenReducer,
        username: usernameReducer,
        id: idReducer,
        mode: modeReducer
    }
});