
import { Rnd } from 'react-rnd';
import { useState, useEffect } from 'react';

import process_vars from '../../forms/process_vars';
import execute_script from './execute_script';

const math = require('mathjs');

// processes label's text, performs relevant arithmetic, and replaces variable values
// (String text, Map vars) -> String parsedText
export function renderTableLabelText(comps, id, text, row, process_table_vars) {
    let parsedText = text;

    let mathRegex = /\[(.*?)\](\d*)f(\d*)/g;
    let matchResults = [...parsedText.matchAll(mathRegex)];

    matchResults.forEach(result => {
        let mathExp = process_table_vars(comps, id, result[1], row);
        let evaluatedExp = 0;
        try {
            evaluatedExp = math.evaluate(mathExp).toString();
            if (result[3] != null) {
                evaluatedExp = parseFloat(evaluatedExp).toFixed(parseInt(result[3]));
            }
        } catch (e) {
            // do nothing
        }
        parsedText = parsedText.replace(result[0], evaluatedExp);
    });

    parsedText = process_table_vars(comps, id, parsedText, row);
    return parsedText;
}

export function process_table_vars (comps, id, str, row) {
    try {
        let searcher = /\{\{(\w*)\}\}/g;
        let match = str.match(searcher);
        if (match.length > 0) {
            for (let k = 0; k < match.length; ++k) {
                let in_vars = false;
                for (let j = 0; j < comps[id].vars[row].length; ++j) {
                    if (match[k].slice(2, match[k].length - 2) === comps[id].vars[row][j].name) {
                        in_vars = comps[id].vars[row][j].value;

                        // if we have a number, we must check if it contains a mathematical expression
                        if (comps[id].vars[row][j].type === 'Number') {
                            // regex for checking for an expression in form: [{{x}} + {{y}}]f
                            let mathRegex = /\[(.*?)\](\d*)f(\d*)/g;
                            let matchResults = [...in_vars.matchAll(mathRegex)];

                            // if we have a match, replace that expression with its evaluated number
                            if (matchResults.length > 0) {
                                matchResults.forEach(result => {
                                    let mathExp = process_table_vars(comps, id, result[1], row);
                                    let evaluatedExp = 0;

                                    // there may be evaluation errors via mathjs, so we use try/catch
                                    try {
                                        // we use math.js here
                                        evaluatedExp = math.evaluate(mathExp).toString();
                                    } catch (e) {
                                        // if error is found, don't do anything
                                    }

                                    // we replace the expression with its evaluated form
                                    in_vars = in_vars.replace(result[0], evaluatedExp);
                                });
                            }
                        }

                        break;
                    }
                } if (in_vars !== false) {
                    str = str.replace(match[k], in_vars);
                }
            }
        }
        return str;
    } catch (e) {
        return str;
    }
}

export default function InputTable({ id, comps, setter, vw, vars, setVars, currentPage, editable }) {

    let innerContent = [];

    let rows = comps[id].data.length;
    let cols = comps[id].data[0].length;

    // const [ columnHeadings, setColumnHeadings ] = useState([]);
    // const [ rowHeadings, setRowHeadings ] = useState([]);

    let columnHeadings = []
    let rowHeadings = [];

    try {

        let rows = comps[id].data.length;
        let cols = comps[id].data[0].length;

        let totalWidth = 0;
        for (let j = 0; j < comps[id].colData.length; ++j) {
            totalWidth = totalWidth + comps[id].colData[j].width;
        }

        for (let j = 0; j < cols; ++j) {

            let totalWidthBeforeThisColumn = 0;
            for (let p = 0; p < j; ++p) {
                totalWidthBeforeThisColumn = totalWidthBeforeThisColumn + comps[id].colData[p].width;
            }

            for (let i = 0; i < rows; ++i) {

                let background_color = `${process_vars(comps[id].props.backgroundColor, vars)}`;
                // alert(comps[id].disable[j]);
                let disabled = false;
                if (comps[id].error[i][j] !== "") {
                    background_color = "#ff5479";
                } 

                try {
                    if (comps[id].warning[i][j] !== '' && background_color !== "#ff5479") {
                        background_color = '#f8d568';
                    }
                } catch (e) {
                    // do nothing
                }
                
                if (comps[id].disable[i][j]) {
                    background_color = background_color === "#ff5479" ? "#ff5479" : "#b8b0ff";
                    disabled = true;
                }

                let width = (process_vars(comps[id].size[0], vars) * vw) * (comps[id].colData[j].width / totalWidth);
                let height = (process_vars(comps[id].size[1], vars) * vw)/rows;
                let topPos = (i * height);
                let leftPos = (totalWidthBeforeThisColumn / totalWidth) * vw * process_vars(comps[id].size[0], vars);

                let contentToPush = <></>;
                if (comps[id].colData[j].type === "Input") {
                    contentToPush = <div style={{
                        position: 'absolute',
                        width: `${width}px`,
                        height: `${height}px`,
                        top: `calc(${topPos}px + 0vmax)`,
                        left: `calc(${leftPos}px + 0.5vmax)`,
                        fontSize: `${process_vars(comps[id].props.fontSize, vars)}vmax`,
                        backgroundColor: background_color,
                        color: `${process_vars(comps[id].props.color, vars)}`,
                        border: process_vars(comps[id].props.borderEnabled, vars) ?
                            `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px',
                        overflowY: 'hidden',
                        boxShadow: 'none',
                        WebkitBoxShadow: 'none'
                        // paddingTop: `${height / }px`,
                    }} onClick={(e) => {
                        e.stopPropagation();
                    }}><textarea style={{
                        border: 'none',
                        padding: '1% 1%',
                        marginTop: '0%',
                        height: '100%',
                        width: '100%',
                        resize: 'none',
                        backgroundColor: background_color,
                        outline: 'none',
                        outlineStyle: 'none',
                        boxShadow: 'none',
                        borderColor: 'transparent',
                    }} value={comps[id].data[i][j]} onChange={(e) => {
                        if (editable && !disabled) {
                            let new_comps = [...comps];
                            new_comps[id].data[i][j] = e.target.value;
                            setter(new_comps);
                        }
                    }}></textarea></div>;
                } else if (comps[id].colData[j].type === "Label") {
                    contentToPush = <div style={{
                        position: 'absolute',
                        width: `${width}px`,
                        height: `${height}px`,
                        top: `calc(${topPos}px + 0vmax)`,
                        left: `calc(${leftPos}px + 0.5vmax)`,
                        fontSize: `${process_vars(comps[id].props.fontSize, vars)}vmax`,
                        backgroundColor: background_color,
                        color: `${process_vars(comps[id].props.color, vars)}`,
                        border: process_vars(comps[id].props.borderEnabled, vars) ?
                            `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px',
                        // paddingTop: `${height / }px`,
                        display: 'flex',
                        alignItems: 'center',
                        overflowY: "scroll"
                    }} onClick={(e) => {
                        e.stopPropagation();
                    }}><div contentEditable={false} style={{
                        border: 'none',
                        paddingLeft: '5%',
                        width: '90%',
                        backgroundColor: background_color,
                        color: `${process_vars(comps[id].props.color, vars)}`,
                        textAlign: comps[id].colData[j].align
                        // resize: 'none',
                        // wordBreak: 'break-all',
                        // whiteSpace: 'normal',
                    }} onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}>{renderTableLabelText(comps, id, comps[id].data[i][j], i, process_table_vars)}</div></div>; 
                } else if (comps[id].colData[j].type === "Check") {
                    contentToPush = <div style={{
                        position: 'absolute',
                        width: `${width}px`,
                        height: `${2 * height / 3}px`,
                        top: `calc(${topPos}px + 0vmax)`,
                        left: `calc(${leftPos}px + 0.5vmax)`,
                        fontSize: `${process_vars(comps[id].props.fontSize, vars)}vmax`,
                        backgroundColor: background_color,
                        color: `${process_vars(comps[id].props.color, vars)}`,
                        border: process_vars(comps[id].props.borderEnabled, vars) ?
                            `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px',
                        paddingTop: `${height / 3}px`,
                    }} onClick={(e) => {
                        e.stopPropagation();
                    }}>
                        <input type="checkbox" style={{
                            fontSize: '1vmax'
                        }} checked={comps[id].data[i][j]} onClick={(e) => {
                            if (editable) {
                                if (!disabled) {
                                    let new_comps = [...comps];
                                    new_comps[id].data[i][j] = !new_comps[id].data[i][j];
                                    setter(new_comps);
                                    execute_script(1, id, comps, setter, vars, setVars);
                                }
                            }
                        }}></input>
                    </div>;
                } else if (comps[id].colData[j].type === "Drop") {

                    let optionsSelectRender = [];
                    for (let k = 0; k < comps[id].colData[j].values.length; ++k) {
                        optionsSelectRender.push(<option value={comps[id].colData[j].values[k]}>{comps[id].colData[j].values[k]}</option>)
                    }

                    contentToPush = <div style={{
                        position: 'absolute',
                        width: `${width}px`,
                        height: `${2 * height / 3}px`,
                        top: `calc(${topPos}px + 0vmax)`,
                        left: `calc(${leftPos}px + 0.5vmax)`,
                        fontSize: `${process_vars(comps[id].props.fontSize, vars)}vmax`,
                        backgroundColor: background_color,
                        color: `${process_vars(comps[id].props.color, vars)}`,
                        border: process_vars(comps[id].props.borderEnabled, vars) ?
                            `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px',
                        paddingTop: `${height / 3}px`,
                    }} onClick={(e) => {
                        e.stopPropagation();
                    }}>
                        <select className='panel-select' style={{
                            fontSize: '1vmax'
                        }} value={comps[id].colData[j].values[comps[id].data[i][j]]} onChange={(e) => {
                            if (editable) {
                                if (!disabled) {
                                    let new_comps = [...comps];
                                    new_comps[id].data[i][j] = comps[id].colData[j].values.indexOf(e.target.value);
                                    setter(new_comps);
                                    execute_script(1, id, comps, setter, vars, setVars);
                                }
                            }
                        }}>
                            { optionsSelectRender }
                        </select>
                    </div>;
                } else if (comps[id].colData[j].type === "Sign") {
                    contentToPush = <div style={{
                        position: 'absolute',
                        width: `${width}px`,
                        height: `${height}px`,
                        top: `calc(${topPos}px + 0vmax)`,
                        left: `calc(${leftPos}px + 0.5vmax)`,
                        fontSize: `${process_vars(comps[id].props.fontSize, vars)}vmax`,
                        backgroundColor: `${process_vars(comps[id].props.backgroundColor, vars)}`,
                        color: `${process_vars(comps[id].props.color, vars)}`,
                        border: process_vars(comps[id].props.borderEnabled, vars) ?
                            `${process_vars(comps[id].props.borderColor, vars)} solid ${process_vars(comps[id].props.borderWidth)}pt` : '#000 solid 0px'
                        // paddingTop: `${height / }px`,
                    }} onClick={(e) => {
                        e.stopPropagation();
                    }}><p style={{
                        fontWeight: '300',
                        padding: '15%'
                    }}>Signature Region</p></div>;
                }

                innerContent.push(contentToPush);
            }
        }

    } catch (err) {

    }

    // const TIME = 10;

    // useEffect(() => {
    //     let interval = setInterval(() => {
    //         let rows = comps[id].data.length;
    //         let cols = comps[id].data[0].length;
    //         let new_rowHeadings = [];
    //         let new_columnHeadings = [];
    //         for (let k = 0; k < cols; ++k) {
    //             let scrollPos = 0;
    //             try {
    //                 scrollPos = document.getElementById("wrapper").scrollTop;
    //             } catch (e) {
    //             }
    //             let topPosition = `calc(-2.5vmax)`;
    //             let leftPosition = `calc(${k / cols * comps[id].size[0] * vw}px + 2px)`;
    //             let navbarHeight = window.innerHeight * 0.075;
    //             if (scrollPos >= comps[id].position[1] * vw + navbarHeight && scrollPos <= comps[id].position[1] * vw + navbarHeight + comps[id].size[1] * vw) {
    //                 let deltaScroll = scrollPos - (comps[id].position[1] * vw + navbarHeight);
    //                 topPosition = `calc(${deltaScroll}px)`;
    //             }
    //             new_columnHeadings.push(<div style={{
    //                 width: `${comps[id].size[0] * vw / cols}px`,
    //                 fontSize: '1vmax',
    //                 position: 'fixed',
    //                 // position: 'absolute',
    //                 padding: '0',
    //                 paddingTop: '0.5vmax',
    //                 paddingBottom: '0.5vmax',
    //                 top: topPosition,
    //                 left: leftPosition,
    //                 zIndex: '5'
    //             }} className='panel-button'>{comps[id].colData[k].name}</div>)
    //         }

    //         for (let k = 0; k < rows; ++k) {
    //             let elementHeight = comps[id].size[1] * vw;
    //             new_rowHeadings.push(<div style={{
    //                 width: '2vmax',
    //                 fontSize: '1vmax',
    //                 position: 'fixed',
    //                 top: `${comps[id].size[1] * vw * k / rows}px`,
    //                 display: 'inline-block',
    //                 left: '-2vmax',
    //                 height: k !== rows - 1 ? `${comps[id].size[1] * vw / rows}px` : `${comps[id].size[1] * vw / (1.5 * rows)}px`,
    //                 padding: '0',
    //                 paddingTop: `calc(${(elementHeight / rows) / 2}px - 0.5vmax)`
    //             }} className='panel-button'>{k + 1}</div>)
    //         }

    //         setRowHeadings(new_rowHeadings);
    //         setColumnHeadings(new_columnHeadings);

    //     }, TIME);

    //     return () => { clearInterval(interval) };
    // }, [])

    for (let k = 0; k < cols; ++k) {
        let totalWidth = 0;
        for (let j = 0; j < comps[id].colData.length; ++j) {
            totalWidth = totalWidth + comps[id].colData[j].width;
        }
        let totalWidthBeforeThisColumn = 0;
        for (let p = 0; p < k; ++p) {
            totalWidthBeforeThisColumn = totalWidthBeforeThisColumn + comps[id].colData[p].width;
        }
        let scrollPos = 0;
        try {
            scrollPos = document.getElementById("wrapper").scrollTop;
        } catch (err) {

        }
        let topPosition = `calc(-2.5vmax)`;
        let leftPosition = `calc(${totalWidthBeforeThisColumn / totalWidth * comps[id].size[0] * vw}px + 7px)`;
        let navbarHeight = window.innerHeight * 0.075;
        if (scrollPos >= comps[id].position[1] * vw + navbarHeight && scrollPos <= comps[id].position[1] * vw + navbarHeight + comps[id].size[1] * vw) {
            let deltaScroll = scrollPos - (comps[id].position[1] * vw + navbarHeight);
            topPosition = `calc(${deltaScroll}px - 4vmax)`;
        }
            //* Include all new column features here.
            let columnNamesEditable = false;
            let columnNameComponent = <>{comps[id].colData[k].name}</>;
            try {
                if ('columnNamesEditable' in comps[id]) {
                    columnNamesEditable = comps[id].columnNamesEditable;
                }

                //* assign correct UI component
                if (columnNamesEditable) {
                    columnNameComponent =
                        <div contentEditable={true} onInput={(e) => {
                            let new_comps = [...comps];
                            new_comps[id].colData[k].name = e.currentTarget.textContent;
                            setter(new_comps);
                        }}>{comps[id].colData[k].name}</div>;
                }
            } catch (e) {
                // do nothing
            }

            columnHeadings.push(
                <div style={{
                    display: 'table'
                }}>
                    <div style={{
                        display: 'table-row',
                        width: `${(comps[id].colData[k].width / totalWidth) * vw * comps[id].size[0]}px`,
                        height: navbarHeight / 2.75,
                        fontSize: '1vmax',
                        position: 'fixed',
                        // position: 'absolute',
                        padding: '0.3vmax 0',
                        top: topPosition,
                        left: leftPosition,
                        zIndex: '5',
                        overflowY: 'scroll',
                        textAlign: 'center',
                        // overflowX: 'hidden'
                        // overflowWrap: 'break-word'
                    }} className='panel-button' onClick={(e) => {
                        e.stopPropagation();
                    }}>
                        <div style={{
                            display: 'table-cell',
                            verticalAlign: 'middle',
                            textAlign: 'center',
                            // background: 'red',
                            width: `${(comps[id].colData[k].width / totalWidth) * vw * comps[id].size[0]}px`,
                            height: navbarHeight / 2.75,
                        }}>{columnNameComponent}</div>
                    </div>
                </div>
            );
    }

    for (let k = 0; k < rows; ++k) {
        let elementHeight = comps[id].size[1] * vw;
        rowHeadings.push(<div style={{
            width: '2vmax',
            fontSize: '1vmax',
            position: 'fixed',
            top: `${(100/rows) * k}%`,
            display: 'inline-block',
            left: '-2vmax',
            height: `calc(${elementHeight / rows}px - ${(elementHeight / rows) / 2}px + 1vmax)`,
            padding: '0',
            paddingTop: `calc(${(elementHeight / rows) / 2}px - 0.5vmax)`
        }} className='panel-button'>{k + 1}</div>)
    }

    return (
        <div key={id}>
            <Rnd className='comp' style={{
                backgroundColor: process_vars(comps[id].props.backgroundColor, vars),
                textAlign: 'center',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: 'visible',
                zIndex: '0',
            }} position={{
                x: process_vars(comps[id].position[0], vars) * vw,
                y: process_vars(comps[id].position[1], vars) * vw
            }} size={{
                width: process_vars(comps[id].size[0], vars) * vw,
                height: process_vars(comps[id].size[1], vars) * vw
            }} minWidth={window.innerWidth * 0.05} minHeight={window.innerWidth * 0.025} bounds='.form-view' 
            enableResizing={false} disableDragging={true}>
                { columnHeadings }
                { rowHeadings }
                { innerContent }
            </Rnd>
        </div>
    );


}