

import {
    useNavigate,
    useParams
} from 'react-router-dom';

import {
    useState,
    useRef,
    useEffect
} from 'react';

import { useSelector } from 'react-redux';

import LogIn from '../../views/root/LogIn';

import NavBar from "../../views/utils/NavBar";
import Modal from '../../views/utils/Modal';

import Box from '../components/Box';
import Label from '../components/Label';
import Button from '../components/Button';
import Input from '../components/Input';
import List from '../components/List';
import Check from '../components/Check';
import Radio from '../components/Radio';
import Drop from '../components/Drop';
import Image from '../components/Image';

import Signature from '../components/Signature';
import InputTable from '../components/InputTable';
import Snapshot from '../components/Snapshot';

import { selectId } from '../../redux/features/idSlice';
import { selectToken } from '../../redux/features/tokenSlice';
import { selectMode } from '../../redux/features/modeSlice';
import axios from 'axios';
import Header from '../../views/utils/Header';

import execute_script from '../components/execute_script';

export default function EditRecordURI({}) {

    const { userId, recordId } = useParams();

    const navigate = useNavigate();

    const id = useSelector(selectId);
    const token = useSelector(selectToken);
    const mode = useSelector(selectMode);

    const [ memberLoginViewLoaded, setMemberLoginViewLoaded ] = useState(false);
    const [ memberLoaded, setMemberLoaded ] = useState(false);
    const [ memberCredentials, setMemberCredentials ] = useState([ "", "" ]); // username, passcode
    const [ members, setMembers ] = useState([]);
    const [ allowedPages, setAllowedPages ] = useState([]); // for visibility
    const [ editablePages, setEditablePages ] = useState([]); // for editability
    const [ loaded, setLoaded ] = useState(false);
    const [ dbId, setDbId ] = useState('');

    if (!memberLoginViewLoaded && loaded) {
        axios.get(`${process.env.REACT_APP_DEV_SERVER}` + 'api/users', {
            headers: {
                Authorization: 'Bearing ' + token
            }
        }).then((response) => {
            setMembers(response.data[0].members);
            setMemberLoginViewLoaded(true);
        }).catch((error) => {
            setMemberLoginViewLoaded(true);
            navigate(-1);
        });
    }

    const [ formName, setFormName ] = useState('Loading...');
    const [ name, setName ] = useState('New Record');
    const [ editingName, setEditingName ] = useState(false);

    const [ useOldTimestamp, setUseOldTimestamp ] = useState(true);
    const [ oldTimestamp, setOldTimestamp ] = useState([
        '00', '00', '00', '00', '00', '0000'
    ]);

    // Array of components to be rendered
    const [components, setComponents] = useState([]);

    // States for pages
    const [ pages, setPages ] = useState([]);
    
    const [ currentPage, setCurrentPage ] = useState(0);
    
    const [ vars, setVars ] = useState([]);
    const [ externals, setExternals ] = useState({
        flags: [
            {
                name: "Loading Flags...",
                values: ['Loading Flags...'],
                colors: ['#fff'],
                value: 0
            }
        ]
    });

    const [ errorsRender, setErrorsRender ] = useState("");
    const [ warningsRender, setWarningsRender ] = useState("");

    const [ isEditingFlags, setIsEditingFlags ] = useState(false);
    const [ currentFlag, setCurrentFlag ] = useState(0);
    const [ viewCurrentFlag, setViewCurrentFlag ] = useState(false);

    let render_flags = [];
    let render_flag = [];
    try {
        for (let k = 0; k < externals.flags.length; ++k) {
            render_flags.push(
                <a style={{
                    width: '90%',
                    fontWeight: '300',
                    padding: '0.5vmax 0',
                    border: '1pt #faf3dd solid',
                    borderTop: k !== 0 ? '0pt' : '1pt #faf3dd solid'
                }} className='panel-button' onClick={(e) => {
                    e.preventDefault();
                    setCurrentFlag(k);
                    setViewCurrentFlag(true);
                }}>
                    {externals.flags[k].name}
                </a>
            );
        }
        for (let k = 0; k < externals.flags[currentFlag].values.length; ++k) {
            render_flag.push(
                <a style={{
                    width: '90%',
                    fontWeight: '300',
                    padding: '0.5vmax 0',
                    border: '1pt #faf3dd solid',
                    borderTop: k !== 0 ? '0pt' : '1pt #faf3dd solid',
                    backgroundColor: parseInt(externals.flags[currentFlag].value) === k ? '#faf3dd' : '#000',
                    color: parseInt(externals.flags[currentFlag].value) === k ? '#000' : '#faf3dd'
                }} className='panel-button' onClick={(e) => {
                    e.preventDefault();
                    let new_externals = {...externals};
                    new_externals.flags[currentFlag].value = k;
                    setExternals(new_externals);
                }}>
                    {externals.flags[currentFlag].values[k]}
                </a>
            );
        }
    } catch (err) {

    }

    const [ isSaving, setIsSaving ] = useState(false);
    const [ auth, setAuth ] = useState({
        password: ''
    });

    const [ timestamp, setTimestamp ] = useState([
        new Date().getSeconds().toString().length === 1 ? '0' + new Date().getSeconds().toString() : new Date().getSeconds().toString(),
        new Date().getMinutes().toString().length === 1 ? '0' + new Date().getMinutes().toString() : new Date().getMinutes().toString(),
        new Date().getHours().toString().length === 1 ? '0' + new Date().getHours().toString() : new Date().getHours().toString(),
        new Date().getDate().toString().length === 1 ? '0' + new Date().getDate().toString() : new Date().getDate().toString(),
        new Date().getMonth().toString().length === 1 ? '0' + (new Date().getMonth() + 1).toString() : (new Date().getMonth() + 1).toString(),
        new Date().getFullYear().toString()
    ]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            let date = new Date();
            setTimestamp([
                date.getSeconds().toString().length === 1 ? '0' + date.getSeconds().toString() : date.getSeconds().toString(),
                date.getMinutes().toString().length === 1 ? '0' + date.getMinutes().toString() : date.getMinutes().toString(),
                date.getHours().toString().length === 1 ? '0' + date.getHours().toString() : date.getHours().toString(),
                date.getDate().toString().length === 1 ? '0' + date.getDate().toString() : date.getDate().toString(),
                date.getMonth().toString().length === 1 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString(),
                date.getFullYear().toString()
            ]);
            if (loaded === false) {
                if (id === userId) {
                    axios.get(`${process.env.REACT_APP_DEV_SERVER}` + 'api/records/byId', {
                        headers: {
                            Authorization: 'Bearing ' + token
                        },
                        params: {
                            _id: recordId
                        }
                    }).then((response) => {
                        console.log("Response", response.data[0]);
                        if (response.data.length === 1) {
                            setName(response.data[0].name);
                            axios.get(`${process.env.REACT_APP_DEV_SERVER}` + 'api/forms/byId', {
                                headers: {
                                    Authorization: 'Bearing ' + token
                                },
                                params: {
                                    _id: response.data[0].form
                                }
                            }).then((res) => {
                                setFormName(res.data[0].name);
                            }).catch((err) => {
                                setLoaded(0);
                            });
                            setComponents(response.data[0].content);
                            setPages(response.data[0].pages);
                            setVars(response.data[0].vars);
                            setExternals(response.data[0].externals);
                            setDbId(response.data[0].form);
                            setAuth(response.data[0].auth);
                            setOldTimestamp(response.data[0].datetime);
                            setLoaded(true);
                        } else {
                            setLoaded(0);
                        }
                    }).catch((e) => {
                        setLoaded(0);
                    });
                }
            }
        }, 100);
        return () => clearInterval(intervalId);
    }, [timestamp]);

    // try {
        
    // } catch (err) {

    // }

    const [ componentsHaveRendered, setComponentsHaveRendered ] = useState(false);
    const [ componentsRender, setComponentsRender ] = useState([]);

    useEffect(() => {
        for (let j = 0; j < components.length; ++j) {
            execute_script(0, j, components, setComponents, vars, setVars);
        }
        let new_errors_render = "";
        let new_warnings_render = "";
        let components_render = [];
        for (let k = 0; k < components.length; ++k) {
            if (components[k].type !== 'intermediate/InputTable') {
                if (components[k].error !== "") {
                    new_errors_render = `${new_errors_render}\n${components[k].name} (${k}): ${components[k].error}`;
                } if (components[k].hasOwnProperty('warning')) {
                    if (components[k].warning) {
                        new_warnings_render = `${new_warnings_render}\n${components[k].name} (${k}): ${components[k].warning}`;
                    }
                }
            } else {
                for (let p = 0; p < components[k].data.length; ++p) {
                    for (let q = 0; q < components[k].data[p].length; ++q) {
                        if (components[k].error[p][q] !== "") {
                            new_errors_render = `${new_errors_render}\n${components[k].name} (Row ${p + 1}, Column ${q + 1}): ${components[k].error[p][q]}`;
                        } if (components[k].warning[p][q] !== "") {
                            new_warnings_render = `${new_warnings_render}\n${components[k].name} (Row ${p + 1}, Column ${q + 1}): ${components[k].warning[p][q]}`;
                        }
                    }
                }
            }
            if (components[k].page === currentPage) {
                if (allowedPages.includes(currentPage) || mode === "admin") {
                    if (components[k].type === 'primitive/Box') {
                        components_render.push(
                            <Box  id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'primitive/Label') {
                        components_render.push(
                            <Label id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'primitive/Button') {
                        components_render.push(
                            <Button id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'primitive/Input') {
                        components_render.push(
                            <Input id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'primitive/List') {
                        components_render.push(
                            <List id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'primitive/Check') {
                        components_render.push(
                            <Check id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'primitive/Radio') {
                        components_render.push(
                            <Radio id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'primitive/Drop') {
                        components_render.push(
                            <Drop id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'primitive/Image') {
                        components_render.push(
                            <Image  id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'intermediate/Signature') {
                        components_render.push(
                            <Signature id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'intermediate/InputTable') {
                        components_render.push(
                            <InputTable id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} currentPage={currentPage} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'intermediate/Snapshot') {
                        components_render.push(
                            <Snapshot id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    }
                } else {
                    components_render.push(
                        <div style={{
                            width: '100%',
                            height: '20vh',
                            fontSize: '1.25vmax',
                            textAlign: 'center',
                            lineHeight: '20vh'
                        }}>Access Prohibited</div>
                    )
                }
            }
        } setComponentsRender(components_render);
        setComponentsHaveRendered(true);
        setErrorsRender(new_errors_render);
        setWarningsRender(new_warnings_render);
        setIsSaving(false);
    }, []);

    if (!componentsHaveRendered) {
        for (let j = 0; j < components.length; ++j) {
            execute_script(0, j, components, setComponents, vars, setVars);
        }
        let new_errors_render = "";
        let new_warnings_render = "";
        let components_render = [];
        for (let k = 0; k < components.length; ++k) {
            if (components[k].type !== 'intermediate/InputTable') {
                if (components[k].error !== "") {
                    new_errors_render = `${new_errors_render}\n${components[k].name} (${k}): ${components[k].error}`;
                } if (components[k].hasOwnProperty('warning')) {
                    if (components[k].warning) {
                        new_warnings_render = `${new_warnings_render}\n${components[k].name} (${k}): ${components[k].warning}`;
                    }
                }
            } else {
                for (let p = 0; p < components[k].data.length; ++p) {
                    for (let q = 0; q < components[k].data[p].length; ++q) {
                        if (components[k].error[p][q] !== "") {
                            new_errors_render = `${new_errors_render}\n${components[k].name} (Row ${p + 1}, Column ${q + 1}): ${components[k].error[p][q]}`;
                        } if (components[k].warning[p][q] !== "") {
                            new_warnings_render = `${new_warnings_render}\n${components[k].name} (Row ${p + 1}, Column ${q + 1}): ${components[k].warning[p][q]}`;
                        }
                    }
                }
            }
            if (components[k].page === currentPage) {
                if (allowedPages.includes(currentPage) || mode === "admin") {
                    if (components[k].type === 'primitive/Box') {
                        components_render.push(
                            <Box  id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'primitive/Label') {
                        components_render.push(
                            <Label id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'primitive/Button') {
                        components_render.push(
                            <Button id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'primitive/Input') {
                        components_render.push(
                            <Input id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'primitive/List') {
                        components_render.push(
                            <List id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'primitive/Check') {
                        components_render.push(
                            <Check id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'primitive/Radio') {
                        components_render.push(
                            <Radio id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'primitive/Drop') {
                        components_render.push(
                            <Drop id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'primitive/Image') {
                        components_render.push(
                            <Image  id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'intermediate/Signature') {
                        components_render.push(
                            <Signature id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'intermediate/InputTable') {
                        components_render.push(
                            <InputTable id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} currentPage={currentPage} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    } else if (components[k].type === 'intermediate/Snapshot') {
                        components_render.push(
                            <Snapshot id={k} comps={components} setter={setComponents}
                                    vw={0.9 * window.innerWidth} vars={vars} setVars={setVars} editable={editablePages.includes(currentPage) || mode === 'admin'} />
                        );
                    }
                } else {
                    components_render.push(
                        <div style={{
                            width: '100%',
                            height: '20vh',
                            fontSize: '1.25vmax',
                            textAlign: 'center',
                            lineHeight: '20vh'
                        }}>Access Prohibited</div>
                    )
                }
            }
        } setComponentsRender(components_render);
        setComponentsHaveRendered(true);
        setErrorsRender(new_errors_render);
        setWarningsRender(new_warnings_render);
        setIsSaving(false);
    }

    return <>{ id !== userId ? <LogIn /> : <>
        { mode === "member" && !memberLoaded ? <div style={{
            background: '#0F1108',
            width: '100%',
            height: '100vh',
            textAlign: 'center',
            fontSize: '1.5vmax',
            color: '#faf3dd',
            fontWeight: '300'
        }}>
            <div style={{
                paddingTop: '37.5vh',
            }}>Member Login</div>
            <div style={{
                paddingTop: '3vh',
                width: '30%',
                height: '30vh',
                marginLeft: '35%',
            }}>
                <i className='fa-solid fa-user' style={{
                    fontSize: '1.25vmax',
                    marginRight: '5%'
                }}></i>
                <input className='panel-input' style={{
                }} value={memberCredentials[0]} onChange={(e) => {
                    setMemberCredentials([ e.target.value, memberCredentials[1] ]);
                }}></input>
                <br />
                <i className='fa-solid fa-lock' style={{
                    fontSize: '1.25vmax',
                    marginRight: '5%',
                    marginTop: '2vh'
                }}></i>
                <input className='panel-input' style={{
                }} value={memberCredentials[1]} onChange={(e) => {
                    setMemberCredentials([ memberCredentials[0], e.target.value ]);
                }}></input>
                <br />
                <a href='#' className='panel-button' style={{
                    fontSize: '1.15vmax',
                    marginTop: '5vh'
                }} onClick={(e) => {
                    e.preventDefault();
                    if (memberLoginViewLoaded) {

                        // computes all visible pages for the current member
                        let available_pages = [];
                        for (let k = 0; k < pages.length; ++k) {
                            if (pages[k].visibleTo === "all") {
                                available_pages.push(k);
                            } else {
                                let includePage = false;
                                for (let j = 0; j < members.length; ++j) {
                                    if (pages[k].visibleTo.includes(members[j].name)) {
                                        let effPasscode = memberCredentials[1] !== "" ? memberCredentials[1] : -1;
                                        let effCredentials = {
                                            name: memberCredentials[0],
                                            passcode: effPasscode
                                        }; // console.log(effCredentials);
                                        // if (members[j].members.includes(effCredentials)) {
                                        //     available_pages.push(k);
                                        //     break;
                                        // }
                                        for (let i = 0; i < members[j].members.length; ++i) {
                                            if (JSON.stringify(members[j].members[i]) === JSON.stringify(effCredentials)) {
                                                includePage = true;
                                                break;
                                            }
                                        }
                                    }
                                } if (includePage) {
                                    available_pages.push(k);
                                }
                            }
                        }

                        // computes all editable pages for the current member
                        let editable_pages = [];
                        for (let k = 0; k < pages.length; ++k) {
                            if (pages[k].editableTo === "all") {
                                editable_pages.push(k);
                            } else {
                                let includePage = false; // should we include page k into editable_pages?
                                for (let j = 0; j < members.length; ++j) {
                                    if (pages[k].editableTo.includes(members[j].name)) {
                                        let effPasscode = memberCredentials[1] !== "" ? memberCredentials[1] : -1;
                                        let effCredentials = {
                                            name: memberCredentials[0],
                                            passcode: effPasscode
                                        }; for (let i = 0; i < members[j].members.length; ++i) {
                                            if (JSON.stringify(members[j].members[i]) === JSON.stringify(effCredentials)) {
                                                includePage = true;
                                                break;
                                            }
                                        }
                                    }
                                } if (includePage) {
                                    editable_pages.push(k);
                                }
                            }
                        }

                        // if more than 0 pages are visible, then the member is allowed to proceed forward
                        if (available_pages.length === 0) {
                            navigate(-1);
                        } else { // otherwise, they go back to the page they came from
                            setAllowedPages(available_pages);
                            setEditablePages(editable_pages);
                            setMemberLoaded(true);
                        }
                    }
                }}>Enter</a>
                <a href='#' className='panel-button' style={{
                    fontSize: '1.15vmax',
                    marginLeft: '5%'
                }} onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                }}>Back</a>
            </div>
        </div> : <div style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100vh',
                backgroundColor: '#0F1108',
                overflow: 'scroll'
            }} onChange={(e) => {
                setComponentsHaveRendered(false);
            }}>

                {/* <div style={{
                    height: 'calc(4vmax + 12vh)',
                    width: '100%',
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    backgroundColor: '#0F1108',
                    zIndex: '9'
                }}>
                    <div className='logo'>
                        <object data="/logo_black.svg" type="image/svg+xml"></object>
                    </div>
                </div>
                <NavBar /> */}
                <Header />

                <div style={{
                    color: '#FAF3DD',
                    position: 'absolute',
                    top: 'calc(4vmax + 12vh + 1pt)',
                    width: '100%',
                    height: 'calc(100vh - 4vmax - 12vh - 1pt)',
                    overflow: 'scroll'
                }} id="wrapper" onScroll={(e) => {
                    setComponentsHaveRendered(false);
                }}>
                    {!editingName ? <h1 style={{
                        textAlign: 'center',
                        fontSize: '2vmax',
                        fontWeight: '300',
                        marginTop: '1vmax',
                        display: 'block',
                        marginBottom: '2vmax',
                        cursor: 'pointer'
                    }} onClick={(e) => {
                        setEditingName(true);
                    }}><b>{name}</b> for <b>{formName}</b></h1> : <div style={{
                        marginTop: '1vmax',
                        textAlign: 'center'
                    }}>
                        <input className='panel-input' onChange={(e) => {
                            setName(e.target.value);
                        }} value={name} style={{
                            width: '15%',
                            display: 'inline-block',
                            margin: 'auto',
                            textAlign: 'center',
                            padding: '1vmax',
                            fontWeight: '300',
                            fontSize: '1.5vmax',
                            marginBottom: '2vmax'
                        }} placeholder='Field Cannot Be Empty' />
                        <i class="fa-solid fa-check panel-icon" style={{
                            color: '#faf3dd',
                            marginLeft: '1vmax',
                            fontSize: '1.5vmax',
                            padding: '0.5vmax'
                        }} onClick={(e) => {
                            let nameValid = false;
                            for (let k = 0; k < name.length; ++k) {
                                if (name[k] !== ' ') {
                                    nameValid = true;
                                    break;
                                }
                            } if (nameValid) {
                                setEditingName(false);
                            }
                        }}></i>
                    </div>}
                    <div style={{
                        textAlign: 'center',
                        fontSize: '1.25vmax',
                        fontWeight: '300'
                    }}>
                        <b>Timestamp:</b> {timestamp[2]}:{timestamp[1]}:{timestamp[0]} <b>on</b> {timestamp[4]}/{timestamp[3]}/{timestamp[5]}
                    </div>
                    {loaded ? <div style={{
                        background: '#ffffff',
                        width: '90vw',
                        height: (pages[currentPage].height * 90).toString() + 'vw',
                        marginLeft: '5vw',
                        marginTop: '2.5vmax'
                    }}>
                        {componentsRender}
                    </div> : <></>}
                    <div style={{           // ERROR CONSOLE
                        width: '90vw',
                        border: '1pt solid #faf3dd',
                        borderTop: '0pt solid #faf3dd',
                        display: 'block',
                        marginLeft: '5vw',
                    }}>
                        <p style={{
                            fontWeight: '300',
                            fontSize: '1.2vmax',
                            margin: '0',
                            padding: '2vmax',
                            paddingBottom: '1vmax',
                            display: 'inline-block'
                        }}>Error Log:</p>
                        {errorsRender === "" ? <div style={{
                            display: 'inline-block',
                            padding: '0',
                            margin: '0',
                            fontSize: '1.1vmax',
                            fontWeight: '300',
                            marginBottom: '2vmax'
                        }}>No Errors Found.</div> : <div style={{
                            width: '85vw',
                            color: '#ff4f42',
                            display: 'block',
                            // padding: '0',
                            paddingLeft: '2vmax',
                            paddingBottom: '2vmax',
                            fontSize: '1.1vmax',
                            fontWeight: '300',
                            overflowX: "auto",
                            overflowY: "scroll",
                            maxHeight: '10vmax',
                            whiteSpace: 'pre-line'
                        }}>{errorsRender}</div>}
                    </div>
                    <div style={{           // ERROR CONSOLE
                        width: '90vw',
                        border: '1pt solid #faf3dd',
                        borderTop: '0pt solid #faf3dd',
                        display: 'block',
                        marginLeft: '5vw',
                        marginBottom: '10vh'
                    }}>
                        <p style={{
                            fontWeight: '300',
                            fontSize: '1.2vmax',
                            margin: '0',
                            padding: '2vmax',
                            paddingBottom: '1vmax',
                            display: 'inline-block'
                        }}>Warning Log:</p>
                        {warningsRender === "" ? <div style={{
                            display: 'inline-block',
                            padding: '0',
                            margin: '0',
                            fontSize: '1.1vmax',
                            fontWeight: '300',
                            marginBottom: '2vmax'
                        }}>No Warnings Found.</div> : <div style={{
                            width: '85vw',
                            color: '#fcff45',
                            display: 'block',
                            // padding: '0',
                            paddingLeft: '2vmax',
                            paddingBottom: '2vmax',
                            fontSize: '1.1vmax',
                            fontWeight: '300',
                            overflowX: "auto",
                            overflowY: "scroll",
                            maxHeight: '10vmax',
                            whiteSpace: 'pre-line'
                        }}>{warningsRender}</div>}
                    </div>
                    <div style={{
                        textAlign: 'center',
                        color: '#faf3dd',
                        fontSize: '1.25vmax',
                        marginTop: '2vmax',
                        fontWeight: '300'
                    }}>
                        <i class="fa-solid fa-chevron-left panel-icon" style={{
                            fontSize: '1vmax',
                            padding: '0.45vmax 0.6vmax',
                            marginRight: '5vmax',
                            borderRadius: '100px'
                        }} onClick={(e) => {
                            if (currentPage !== 0) {
                                setCurrentPage(currentPage - 1);
                            } else {
                                setCurrentPage(pages.length - 1);
                            } setComponentsHaveRendered(false);
                        }}></i>
                        Page {currentPage + 1}/{pages.length}
                        <i class="fa-solid fa-chevron-right panel-icon" style={{
                            fontSize: '1vmax',
                            padding: '0.45vmax 0.6vmax',
                            marginLeft: '5vmax',
                            borderRadius: '100px'
                        }} onClick={(e) => {
                            if (currentPage !== pages.length - 1) {
                                setCurrentPage(currentPage + 1)
                            } else {
                                setCurrentPage(0);
                            } setComponentsHaveRendered(false);
                        }}></i>
                    </div>
                    <div style={{
                        textAlign: 'center',
                        fontWeight: '300'
                    }}>
                        <a href='#' className='panel-button' style={{
                            fontSize: '1.15vmax',
                            marginTop: '2vmax'
                        }} onClick={(e) => {
                            e.preventDefault();
                            setIsEditingFlags(true);
                        }}>Edit Flags</a>
                    </div>
                    <div style={{
                        textAlign: 'center',
                        fontWeight: '300'
                    }}>
                        <a href='#' className='panel-button' style={{
                            fontSize: '1.15vmax',
                            marginRight: '1.5vmax',
                            marginTop: '2vmax'
                        }} onClick={(e) => {
                            e.preventDefault();
                            setIsSaving(true);
                        }}>Save</a>
                        <a href='#' className='panel-button' style={{
                            fontSize: '1.15vmax'
                        }} onClick={(e) => {
                            e.preventDefault();
                            navigate('/records', {});
                        }}>Exit</a>
                    </div>
                    <div style={{
                        width: '100%',
                        height: '10vmax',
                        display: 'block'
                    }}></div>
                </div>
        </div>}
        { isSaving && errorsRender === "" ? <Modal width='30' height='32.5'>
            <h1 style={{
                fontWeight: '300',
                fontSize: '1.25vmax',
                marginTop: '2vmax'
            }}>Save Record</h1>
            <p style={{
                fontWeight: '300',
                fontSize: '1vmax'
            }}>Record Name: <b>{name}</b></p>
            <p style={{
                fontWeight: '300',
                fontSize: '1vmax'
            }}>Timestamp: <b>{useOldTimestamp ? oldTimestamp[2] : timestamp[2]}:{useOldTimestamp ? oldTimestamp[1] : timestamp[1]}:{useOldTimestamp ? oldTimestamp[0] : timestamp[0]}</b> on <b>{useOldTimestamp ? oldTimestamp[4] : timestamp[4]}/{useOldTimestamp ? oldTimestamp[3] : timestamp[3]}/{useOldTimestamp ? oldTimestamp[5] : timestamp[5]}</b></p>
            <p style={{
                fontWeight: '300',
                fontSize: '1vmax',
                display: 'inline-block',
                margin: '0',
                marginBottom: '1.5vmax'
            }}>Password: </p><input className='panel-input' style={{
                margin: '0',
                marginLeft: '0.5vmax'
            }} placeholder='No Password' value={auth.password} onChange={(e) => {
                setAuth({
                    password: e.target.value
                });
            }}></input>
            <br/>
            <a href='#' className='panel-button' style={{
                backgroundColor: useOldTimestamp ? '#faf3dd' : '#000',
                color: useOldTimestamp ? '#000' : '#faf3dd',
                display: 'inline-block',
                marginBottom: '1vmax'
            }} onClick={(e) => {
                e.preventDefault();
                setUseOldTimestamp(!useOldTimestamp);
            }}>Using Old Timestamp</a>
            <br/>
            <a href='#' className='panel-button' style={{
                marginRight: '1vmax'
            }} onClick={(e) => {
                e.preventDefault();
                if (!editingName && loaded) {
                    for (let j = 0; j < components.length; ++j) {
                        execute_script(2, j, components, setComponents, vars, setVars);
                    }
                    axios
                        .patch(`${process.env.REACT_APP_DEV_SERVER}` + 'api/records/byId', {
                            query: {
                                _id: recordId
                            }, params: {
                                name: name,
                                datetime: useOldTimestamp ? oldTimestamp : timestamp,
                                content: components,
                                pages: pages,
                                vars: vars,
                                externals: externals,
                                auth: auth
                            }
                        }, {
                            headers: {
                                Authorization: 'Bearing ' + token
                            }
                        })
                        .then((response) => {
                            navigate('/records');
                        })
                        .catch((error) => {
                            console.error('Failed to Save Record');
                        });
                    }
            }}>Save</a>
            <a href='#' className='panel-button' onClick={(e) => {
                e.preventDefault();
                setIsSaving(false);
            }}>Cancel</a>
        </Modal> : <>
        </> }
        {
            isEditingFlags ? <Modal width='40' height='50'>
                <h1 style={{
                    color: '#FAF3DD',
                    fontWeight: '300',
                    margin: '1vmax 0',
                    padding: '1vmax 0',
                    fontSize: '1.75vmax',
                    display: 'block'
            }}>Edit Flags</h1>
            <div style={{
                height: '65%',
                overflow: 'scroll'
            }}>
                { render_flags }
            </div>
            <div style={{
                position: 'absolute',
                bottom: '0',
                width: '100%',
                backgroundColor: '#000000'
            }}>
                <a href='' className='panel-button' style={{
                    marginBottom: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    setIsEditingFlags(false);
                }}>Okay</a>
            </div>
            </Modal> : <></>
        }
        {
            viewCurrentFlag && loaded ? <Modal width='40' height='50'>
                <h1 style={{
                    color: '#FAF3DD',
                    fontWeight: '300',
                    margin: '1vmax 0',
                    padding: '1vmax 0',
                    fontSize: '1.75vmax',
                    display: 'block'
            }}>Edit {externals.flags[currentFlag].name}</h1>
            <div style={{
                height: '65%',
                overflow: 'scroll'
            }}>
                { render_flag }
            </div>
            <div style={{
                position: 'absolute',
                bottom: '0',
                width: '100%',
                backgroundColor: '#000000'
            }}>
                <a href='' className='panel-button' style={{
                    marginBottom: '1vmax'
                }} onClick={(e) => {
                    e.preventDefault();
                    setViewCurrentFlag(false);
                }}>Okay</a>
            </div>
            </Modal> : <></>
        }
    </>}</>;

}